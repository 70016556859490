import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material';
// components
import MenuPopover from './MenuPopover';
// mocks_
import account from './account';
import UseStore from '../Zustand/zustand';

// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Trang chủ',
    icon: 'eva:home-fill',
    linkTo: '/nhasx/dashboard',
  },
  {
    label: 'Trang cá nhân',
    icon: 'eva:person-fill',
    linkTo: '/nhasx/profile',
  },
];

// ----------------------------------------------------------------------

export default function AccountPopover({ userInfo }) {
  const setInfoF5 = UseStore((state) => state.setNamevsAvatar);
  
  const avatars = UseStore((state) => state.avatar);
  const name = UseStore((state) => state.name);
  if (avatars == undefined || name == undefined) {
    setInfoF5(localStorage.getItem("avatar"), localStorage.getItem("name"));
  }
  const anchorRef = useRef(null);
  const avatar = avatars != "null" ? avatars : `https://avatar.oxro.io/avatar.svg?name=${name}&background=f39c12&length=2`
  const [open, setOpen] = useState(null);
  
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);

  };
  const logout = () => {
    localStorage.clear();
    window.location.href = "/";
}

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={avatar} alt="photoURL" />
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {userInfo.name}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary',textAlign: 'left' }} noWrap>
            {userInfo.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} to={option.linkTo} component={RouterLink} onClick={handleClose}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={logout} sx={{ m: 1 }}>
          Logout
        </MenuItem>
      </MenuPopover>
    </>
  );
}
