import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import callApi from "../../Api/userApi";
import UseStore from "../../Zustand/zustand";
import "./CapLaiMK.css";
CapLaiMK.propTypes = {};

function CapLaiMK({ data, open, handleClose }) {
  const [isSubmit, setIsSubmit] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = UseStore((state) => state.accessToken);
  const removeUser = () => {
    setIsSubmit(true);
    enqueueSnackbar("Đang xử lý, vui lòng chờ", { variant: "info" });
    callApi.changePassForgot(token, data.Value.ID).then((res) => {
      if (!res.mess) enqueueSnackbar(`Lỗi: ${res.error}`, { variant: "error" });
      else if (res.mess) {
        closeSnackbar();
        enqueueSnackbar(`${res.mess}`, { variant: "success" });
        enqueueSnackbar(
          "Vui lòng nhắc nhở người dùng truy cập vào Email để nhận mật khẩu",
          { variant: "info" }
        );
      } else {
        closeSnackbar();
        enqueueSnackbar("Lỗi không xác định", { variant: "error" });
      }
      handleClose();
      setIsSubmit(false);
    });
  };
  return (
    <div>
      {isSubmit ? (
        <Dialog
          open={true}
          style={{ background: "none" }}
          className="DiaLog_Loading"
        >
          <DialogContent>
            <img
              src={require("../../image/iconLoading.gif")}
              className="icon_loading"
            />
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="draggable-dialog-title"
        >
          <DialogTitle>
            Cấp lại mật khẩu cho người dùng {data.Value.name}
          </DialogTitle>
          <DialogContent>
            Bạn xác nhận muốn cấp lại mật khẩu cho người dùng {data.Value.name}{" "}
            chứ?. Mật khẩu mới sẽ được gửi qua Email mà người dùng đã đăng ký
            trước đó
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Không</Button>
            <Button onClick={removeUser}>Có</Button>
          </DialogActions>
        </Dialog>
      )}
    </div>
  );
}

export default CapLaiMK;
