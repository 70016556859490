import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useState } from "react";
import UseStore from "../../Zustand/zustand";
import CapLaiMK from "./CapLaiMK";
import SuaNguoiDung from "./SuaNguoiDung";
import XoaNguoiDung from "./XoaNguoiDung";
function RowTable({ data, size }) {
  const IDHT = UseStore((state) => state.ID);
  const [dataDialog, setDataDialog] = useState(false);
  const handleCloseND = () => {
    setDataDialog(false);
  };
  const [infoUserDel, setInfoUserDel] = useState(0);
  const handleCloseDL = () => {
    setInfoUserDel(false);
  };
  const [infoUserChangePass, setChangePass] = useState(0);
  const closeDialogMH = () => {
    setChangePass(0);
  }
  
  var dem = size;
  return (
    <>
      {data.map((res) => {
        dem++;
        return (
          <TableRow hover role="checkbox" key={res.ID}>
            <TableCell>{dem}</TableCell>
            <TableCell>{res.username}</TableCell>
            <TableCell>{res.name}</TableCell>
            <TableCell>{res.email}</TableCell>
            <TableCell>{res.phone}</TableCell>
            <TableCell>
              <PopupState variant="popover" popupId="showMore">
                {(popupState) => (
                  <React.Fragment>
                    <IconButton {...bindTrigger(popupState)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          setDataDialog(res);
                        }}
                      >
                        <Typography onClick={popupState.close}>
                          Sửa người dùng
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setChangePass(res);
                        }}
                      >
                        <Typography onClick={popupState.close}>
                          Cấp lại mật khẩu
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setInfoUserDel(res);
                        }}
                      >
                        <Typography onClick={popupState.close}>
                          Xoá người dùng
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
              {dataDialog == res && (
                <SuaNguoiDung
                  info={dataDialog}
                  openSua={Boolean(dataDialog)}
                  handleClose={handleCloseND}
                />
              )}
              {infoUserDel == res && (
                <XoaNguoiDung
                  data={infoUserDel}
                  handleClose={handleCloseDL}
                  open={Boolean(infoUserDel)}
                />
              )}
              {infoUserChangePass == res && (
                <CapLaiMK
                  data={infoUserChangePass}
                  open={Boolean(infoUserChangePass)}
                  handleClose={closeDialogMH}
                />
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

export default RowTable;
