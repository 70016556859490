import { Search } from "@mui/icons-material";
import {
  Input,
  Skeleton, Typography
} from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import callApi from "../../Api/userApi";
import UseStore from "../../Zustand/zustand";
import "./HomePageKhoa.css";
import TableKhoa from "./TableKhoa";
import CampaignIcon from "@mui/icons-material/Campaign";
HomePageKhoa.propTypes = {};
const columns = [
  { id: "STT", label: "STT", minWidth: 10 },
  { id: "username", label: "Username", minWidth: 10 },
  { id: "name", label: "Họ và tên", minWidth: 10 },
  {
    id: "trangthai",
    label: "Trạng thái cấp khoá",
    minWidth: 10,
  },
  {
    id: "Thao tác",
    label: "",
    minWidth: 5,
  },
];

const rows = [
  {
    id: 1,
    value: 2,
  },
  {
    id: 2,
    value: 2,
  },
  {
    id: 3,
    value: 2,
  },
  {
    id: 4,
    value: 2,
  },
  {
    id: 5,
    value: 2,
  }
];

function HomePageKhoa({ infoUser }) {
  
  const [page, setPage] = React.useState(0);
  const hotReload = UseStore((state) => state.hotReload);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const bookmarkHistory = [
    "g1AAAABQeJzLYWBgYMpgSmHgKy5JLCrJTq2MT8lPzkzJBYoLMHzp378_My-zJDMxJ7MqNQWkjgOmDquKLAAOqxwD",
  ];
  const [rowPageGet, setRowPageGet] = React.useState({
    size: rowsPerPage,
    bookmark:
      "g1AAAABQeJzLYWBgYMpgSmHgKy5JLCrJTq2MT8lPzkzJBYoLMHzp378_My-zJDMxJ7MqNQWkjgOmDquKLAAOqxwD",
  });
  const [count, setCound] = React.useState();
  const handleChangeRowsPerPage = (event) => {
    setRowPageGet(event.target.value);
    setPage(0);
    setRowsPerPage(event.target.value);
    setRowPageGet({
      size: event.target.value,
      bookmark:
        "g1AAAABQeJzLYWBgYMpgSmHgKy5JLCrJTq2MT8lPzkzJBYoLMHzp378_My-zJDMxJ7MqNQWkjgOmDquKLAAOqxwD",
    });
  };
  const [data, setData] = React.useState(null);
  const handleChangePage = (event, newPage) => {
    bookmarkHistory.push(data.bookmark);
    if (newPage < page)
      data.bookmark = bookmarkHistory[bookmarkHistory.length - 2];
    setPage(newPage);
    setRowPageGet({
      ...rowPageGet,
      bookmark: data.bookmark,
    });
  };
  const setHotReload = UseStore((state) => state.setHotReload);
  const userChange = (e) => {
    if (!e.target.value) setHotReload();
    let res = {};
    res.Value = [];
    for (let i = 0; i < data.results.length; i++) {
      if (data.results[i].Value.username.indexOf(e.target.value) != -1)
        res.Value.push(data.results[i]);
    }

    setData({
      ...data,
      results: res.Value,
    });
  };
  React.useEffect(() => {
    callApi.totalSize(infoUser.accessToken).then((res) => setCound(res));
    callApi
      .getListUser(infoUser.accessToken, rowPageGet, "")
      .then((res) => setData(res));
  }, [rowPageGet, hotReload]);
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div className="search_box">
          <Search className="icon_search"></Search>
          <Input
            placeholder="Tìm kiếm người dùng"
            onChange={userChange}
          ></Input>
        </div>
        <div style={{display: 'flex', marginLeft: 2, color: 'red'}}>
          <CampaignIcon/>
          <Typography variant="body" style={{ color: "red" }}>
            Chỉ những người dùng là Cơ quan kiểm định mới có khoá công khai
          </Typography>
        </div>

        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data == null ? (
                rows.map((ske) => {
                  return (
                    <TableRow key={ske.id}>
                      {rows.map((skel) => {
                        return (
                          <TableCell key={skel.id}>
                            <Skeleton></Skeleton>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <TableKhoa data={data} size={page * rowsPerPage + 1} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {count != undefined && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={count.total - 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </Paper>
      <div>
        <div className="HomePageKhoa_cop">
          <img
            src={require("../../image/logo.png")}
            className="logo_troot"
          ></img>
        </div>

        <Typography>Copyright © 2023 T Services</Typography>
      </div>
    </>
  );
}

export default HomePageKhoa;
