import React from "react";
import PropTypes from "prop-types";
import { logDOM } from "@testing-library/react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import UseStore from "../../Zustand/zustand";
import SanPhamAPI from "../../Api/SanPhamAPI";
import { useSnackbar } from "notistack";

XoaSanPham.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
};

function XoaSanPham({ data, onClose }) {
  const [open, setOpen] = React.useState(true);
  const loading = UseStore((state) => state.setLoading);
  const token = UseStore((state) => state.accessToken);
  const hotReload = UseStore((state) => state.setHotReload);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const XoaSP = () => {
    loading(true);
    onClose();
    SanPhamAPI.removeUser(token, data.ID)
      .then((res) => {
        hotReload(new Date());
        loading(false);
        enqueueSnackbar(
          "Thay đổi trạng thái sản xuất của sản phẩm thành công",
          { variant: "success" }
        );
      })
      .catch(() => {
        enqueueSnackbar("Có lỗi khi cập nhật thông tin sản phẩm", {
          variant: "error",
        });
      });
  };
  return (
    <>
      <Dialog
        open={Boolean(data)}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          {data.trangthai == true ? (
            <span>Bạn có chắc chắn muốn ngừng sản xuất sản phẩm này?</span>
          ) : (
            <span>Bạn muốn sản xuất trở lại sản phẩm này chứ ?</span>
          )}
        </DialogTitle>
        <DialogContent>
          {data.trangthai == true ? (
            <span>
              Nếu bạn ngừng sản xuất sản phẩm này, người dùng sẽ không thể truy
              xuất thông tin sản phẩm của bạn. Tính năng này nên sử dụng khi sản
              phẩm bị thu hồi hoặc có rủi ro sau quy trình sản xuất
            </span>
          ) : (
            <span>
              Nếu bạn sản xuất trở lại sản phẩm này, mọi người có thể tra cứu
              được sản phẩm này. Bạn chắc chắn muốn xác nhận sản xuất lại sản
              phẩm này chứ ?
            </span>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={XoaSP}>Có</Button>
          <Button onClick={onClose}>Không</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default XoaSanPham;
