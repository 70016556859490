// component
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Trang chủ',
    path: '/admin/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Người dùng',
    path: '/admin/controluser',
    icon: getIcon('eva:people-fill'),
  },
  {
    title: 'Tổ chức',
    path: '/admin/organization',
    icon: getIcon('icons8:organization'),
  },
  {
    title: 'Trang cá nhân',
    path: '/admin/profile',
    icon: getIcon('icon-park-twotone:permissions'),
  },
  {
    title: 'Quản lý khoá',
    path: '/admin/key',
    icon: getIcon('akar-icons:key')
  }
];

export default navConfig;
