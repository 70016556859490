import { Search } from "@mui/icons-material";
import { AppBar, Button, Dialog, IconButton, Input, Skeleton, TextField, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import callApi from "../../Api/userApi";
import UseStore from "../../Zustand/zustand";
import RowTable from "./RowTable";
import "./UserPage.css";
import ThemNguoiDung from "./ThemNguoiDung";
UserPage.propTypes = {};
const columns = [
  { id: "STT", label: "STT", minWidth: 10 },
  { id: "username", label: "Username", minWidth: 10 },
  { id: "name", label: "Họ và tên", minWidth: 10 },
  {
    id: "email",
    label: "Email",
    minWidth: 10,
  },
  {
    id: "phone",
    label: "Số điện thoại",
    minWidth: 10,
  },
  {
    id: "Thao tác",
    label: "",
    minWidth: 5,
  },
];

const rows = [
  {
    id: 1,
    value: 2,
  },
  {
    id: 2,
    value: 2,
  },
  {
    id: 3,
    value: 2,
  },
  {
    id: 4,
    value: 2,
  },
  {
    id: 5,
    value: 2,
  },
  {
    id: 6,
    value: 2,
  }
];

function UserPage({ infoUser }) {

  const [page, setPage] = React.useState(0);
  const [dialog, setDialog] = React.useState(false);
  const openDialog = (() => {
    setDialog(true);
  })
  const closeDialog = (() => {
    setDialog(false);
  })
  const hotReload = UseStore((state) => state.hotReload);

  const [count, setCound] = React.useState();

  const [data, setData] = React.useState(null);

  const setHotReload = UseStore((state) => state.setHotReload);
  const userChange = (e) => {
    if (!e.target.value) setHotReload();
    let res = {}
    res.Value = [];
    for (let i = 0; i < data.results.length; i++) {
      if (data.results[i].Value.username.indexOf(e.target.value) != -1) res.Value.push(data.results[i]);
    }

    setData({
      ...data,
      results: res.Value
    })
  }
  React.useEffect(() => {
    console.log(infoUser);
    callApi.getUserToChuc(infoUser.accessToken, infoUser.phongban).then((res) => setData(res))
  }, [hotReload]);
  return (
    <>
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div className="search_box">
          <Search className="icon_search"></Search>
          <Input placeholder="Tìm kiếm người dùng" onChange={userChange}></Input>
          <Button
            variant="outlined"
            sx={{ marginLeft: 2, flexWrap: "nowrap", fontSize: 12 }}
            className="btn_addUser"
            onClick={openDialog}
          >
            Thêm mới người dùng
          </Button>
        </div>

        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data == null ? (
                rows.map((ske) => {
                  return (
                    <TableRow key={ske.id}>
                      {rows.map((skel) => {
                        return (
                          <TableCell key={skel.id}>
                            <Skeleton></Skeleton>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <RowTable data={data} size={0} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      <ThemNguoiDung dialog={dialog} closeDialog={closeDialog} />
      <div>
        <div className="userPage_cop">
          <img src={require("../../image/logo.png")} className="logo_troot"></img>
        </div>

        <Typography>Copyright © 2023 T Services</Typography>
      </div>
    </>
  );
}

export default UserPage;
