import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel, LinearProgress, MenuItem,
  Select, TextField,
  Typography
} from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import callApi from "../../Api/userApi";
import UseStore from "../../Zustand/zustand";
ThemNguoiDung.propTypes = {
  dialog: PropTypes.bool,
  closeDialog: PropTypes.func,
};

function ThemNguoiDung({ dialog, closeDialog }) {
  const [vaitro, setVaitro] = useState("Admin")
  const [vtThat,setVtThat] = useState("Admin")
  const vaitrochange = (e) => {
    if(e.target.value == "NhaSX" || e.target.value == "ANhaSX") setVaitro("NhaSX");
    else if (e.target.value == "CQKD" || e.target.value == "NDVKD") setVaitro("CQKD");
    else setVaitro(e.target.value)
    setVtThat(e.target.value)
  }
  const [submit, setSubmit] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const actoken = UseStore((state) => state.accessToken);
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Không thể bỏ trống Tên người dùng"),
    fullname: Yup.string().required("Không thể bỏ trống Họ và tên"),
    phoneNumber: Yup.string()
      .phone("VN", false, "SĐT không đúng định dạng")
      .required("Số điện thoại không được để trống"),
    email: Yup.string()
      .email("Phải là một Email hợp lệ")
      .required("Không thể bỏ trống"),
    tochuc: Yup.string().required("Không được bỏ trống tổ chức"),
  });
  const hotReload = UseStore((state) => state.setHotReload);
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    handleSubmit,
    formState,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm(formOptions);
  const Submit = async (e) => {
    setSubmit(true);
    enqueueSnackbar("Vui lòng chờ", { variant: 'info' });
    const obj = {
      name: e.fullname,
      username: e.username,
      phongban: e.tochuc,
      vaitro: vtThat,
      email: e.email,
      phone: e.phoneNumber
    }

    await callApi.addUser(obj).then((res) => {
      if (res.mess === "Thêm mới người dùng thành công") {
        closeSnackbar();
        enqueueSnackbar("Đã thêm mới người dùng thành công", { variant: 'success' });
        enqueueSnackbar("Để đảm bảo bảo mật cho người dùng, mật khẩu đăng nhập đã được gửi về email của người dùng", { variant: 'info' })
        reset();
        hotReload(obj.username);
      }
      closeDialog();
    }).catch((e) => {
      closeSnackbar();
      enqueueSnackbar(e.response.data.mess, { variant: 'error' })
      reset();
      closeDialog();
    })
    setSubmit(false);
  };
  const [toChuc, setToChuc] = useState(0);
  useEffect(() => {
    callApi.getAllToChuc(actoken).then((res) => setToChuc(res));
  }, []);
  return (
    <div>
      {submit ? (
        <Dialog open={true} style={{ background: 'none' }} className="DiaLog_Loading">
          <DialogContent>
            <img src={require("../../image/iconLoading.gif")} className='icon_loading' />
          </DialogContent>
        </Dialog>
      ) :
        (<Dialog open={dialog} onClose={closeDialog}>
          {isSubmitting == true && <LinearProgress />}
          <AppBar position="static" style={{ display: "flex" }}>
            <div style={{ display: "flex" }}>
              <IconButton onClick={closeDialog} style={{ justifyContent: "right" }}>
                <CloseIcon style={{ color: "white" }} />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                style={{ alignSelf: "center" }}
              >
                Thêm mới người dùng
              </Typography>
            </div>
          </AppBar>
          <form onSubmit={handleSubmit(Submit)} style={{ overflow: "scroll" }}>
            <DialogContent>
              <TextField
                label="Username"
                fullWidth
                {...register("username")}
                error={Boolean(errors.username)}
              />
              <Typography variant="body" style={{ color: "red" }}>
                {errors.username?.message}
              </Typography>
              <TextField
                style={{ marginTop: 10 }}
                label="Họ và tên"
                fullWidth
                {...register("fullname")}
                error={Boolean(errors.fullname)}
              />
              <Typography variant="body" style={{ color: "red" }}>
                {errors.fullname?.message}
              </Typography>
              <TextField
                style={{ marginTop: 10 }}
                label="Email"
                fullWidth
                error={Boolean(errors.email)}
                {...register("email")}
              />
              <Typography variant="body" style={{ color: "red" }}>
                {errors.email?.message}
              </Typography>
              <TextField
                style={{ marginTop: 10 }}
                label="Số điện thoại"
                fullWidth
                error={Boolean(errors.phoneNumber)}
                defaultValue={"+84"}
                {...register("phoneNumber")}
              />
              <Typography variant="body" style={{ color: "red" }}>
                {errors.phoneNumber?.message}
              </Typography>

              <FormControl fullWidth style={{ marginTop: 10 }}>
                <InputLabel>Vai trò</InputLabel>
                <Select
                  label="Vai trò"
                  defaultValue={"Admin"}
                  onChange={vaitrochange}
                  
                  require
                >
                  <MenuItem value={"Admin"}>Công ty phần mềm</MenuItem>
                  <MenuItem value={"ANhaSX"}>Admin Nhà sản xuất</MenuItem>
                  <MenuItem value= {"NhaSX"}>Nhân viên Nhà sản xuất</MenuItem>
                  <MenuItem value={"CQKD"}> Admin Cơ quan kiểm định</MenuItem>
                  <MenuItem value={"NCQKD"}> Nhân viên Cơ quan kiểm định</MenuItem>
                </Select>
              </FormControl>
              {vaitro != "Admin" ? (
                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel>Tổ chức</InputLabel>
                  <Select
                    label="Tổ chức"
                    {...register("tochuc")}
                    error={Boolean(errors.tochuc)}
                  >
                    {toChuc != 0 &&
                      toChuc.map((value) => {
                        if (value.record.trangthai === "true" && value.record.loai === (vaitro == "NhaSX" ? "CoQuanSX" : "CoQuanKD")) {
                          return (
                            <MenuItem value={value.record.ID} key={value.record.ID}>
                              {value.record.TenCQ}
                            </MenuItem>
                          )
                        }
                      })}
                  </Select>
                  <Typography style={{ color: "red" }} variant="body">
                    {errors.tochuc?.message}
                  </Typography>
                </FormControl>) : (
                <FormControl fullWidth style={{ marginTop: 10 }}>
                  <InputLabel>Tổ chức</InputLabel>
                  <Select
                    label="Tổ chức"
                    {...register("tochuc")}
                    defaultValue={"21575299c3c50035775028e22df6a2cabf85eddcd8e76a28996eb4e7ef8d69c9"}
                    error={Boolean(errors.tochuc)}
                  >
                    <MenuItem value="21575299c3c50035775028e22df6a2cabf85eddcd8e76a28996eb4e7ef8d69c9" key="21575299c3c50035775028e22df6a2cabf85eddcd8e76a28996eb4e7ef8d69c9">Công ty phần mềm</MenuItem>
                  </Select>
                  <Typography style={{ color: "red" }} variant="body">
                    {errors.tochuc?.message}
                  </Typography>
                </FormControl>
              )}
            </DialogContent>
            <DialogActions>
              <Button type="submit">Tạo người dùng</Button>
            </DialogActions>
          </form>
        </Dialog>)}
    </div>
  );
}

export default ThemNguoiDung;
