import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import { AppBar, Avatar, Button, Dialog, DialogActions, DialogContent, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import callApi from "../../Api/userApi";
import UseStore from "../../Zustand/zustand";
SuaToChuc.propTypes = {
  data: PropTypes.object,
  onClose: PropTypes.func,
};

function SuaToChuc({ data, onClose }) {
  console.log(data);
    const validationSchema = Yup.object().shape({
      TenTC: Yup.string().required("Không thể bỏ trống Tên Tổ chức"),
      DCToChuc: Yup.string().required("Không thể bỏ trống Địa chỉ tổ chức"),
      phoneNumber: Yup.string()
        .phone("VN", false, "SĐT không đúng định dạng")
        .required("Số điện thoại không được để trống"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
      handleSubmit,
      formState,
      register,
      reset,
      formState: { isSubmitting, errors },
    } = useForm(formOptions);
    const [state,SetState] = useState(0);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const hotReload = UseStore((state) => state.setHotReload);
    const token = UseStore((state) => state.accessToken);
    let formData = new FormData();
    const [submit,setSubmit] = useState(false);
    const onSubmit = async(e) => {
        setSubmit(true);
        enqueueSnackbar('Vui lòng đợi, đang xử lý',{variant: 'info'})
        await callApi.suaToChuc(token,data.ID,e.TenTC,e.DCToChuc,e.phoneNumber,e.TieuChuanKD).then((e) => {
            closeSnackbar();
            enqueueSnackbar(`${e.mess}`, {variant: 'success'});
            hotReload(e);
        }).catch((e) => {
            enqueueSnackbar('Có lỗi',{variant: 'error'})
        })
        onClose();
        setSubmit(false);
    }
    const changeAvatar = (e) => {
        enqueueSnackbar("Đang đổi Logo tổ chức, vui lòng chờ",{variant: 'info'})
        e.preventDefault();
        formData.append("image", e.target.files[0]);
        fetch("https://image.banlait.com:3000/uploadImage", {
          method: "POST",
          body: formData,
        }).then((response) => response.json()).then((res) => {
            
            callApi.updateLogoTC(
              token,
              data.ID,
              `https://image.banlait.com:3000/${res.path}`
            ).then((resss) => {
                closeSnackbar();
                enqueueSnackbar(`${resss.mess}`,{variant: 'success'})
                data.logo = `https://image.banlait.com:3000/${res.path}`;
                SetState(1);
            }).catch((e) => enqueueSnackbar(e,{variant: 'error'}))
        }).catch((e) => enqueueSnackbar(e,{variant: 'error'}))
    }
  return (
    <div>
      {submit ? (
        <Dialog
          open={true}
          style={{ background: "none" }}
          className="DiaLog_Loading"
        >
          <DialogContent>
            <img
              src={require("../../image/iconLoading.gif")}
              className="icon_loading"
            />
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog
          open={Boolean(data)}
          aria-labelledby="draggable-dialog-title"
          onClose={onClose}
        >
          <AppBar position="static">
            <div style={{ display: "flex" }}>
              <IconButton
                aria-label="upload picture"
                component="label"
                onClick={onClose}
              >
                <CloseIcon style={{ color: "white" }}></CloseIcon>
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                style={{ alignSelf: "center" }}
              >
                Sửa tổ chức {data.TenCQ}
              </Typography>
            </div>
          </AppBar>
          <form onSubmit={handleSubmit(onSubmit)}>
            <DialogContent>
              <div style={{ display: "flex" }}>
                <div style={{ marginLeft: -10 }}>
                  <IconButton aria-label="upload picture" component="label">
                    <input
                      hidden
                      accept="image/jpg, image/png"
                      type="file"
                      onChange={changeAvatar}
                    />
                    <Avatar src={data.logo}></Avatar>
                  </IconButton>
                </div>
                <div style={{ marginLeft: 5, width: "100%" }}>
                  <TextField
                    label="Tên tổ chức"
                    defaultValue={data.TenCQ}
                    fullWidth
                    name="TenTC"
                    {...register("TenTC")}
                    error={Boolean(errors.TenTC)}
                  ></TextField>
                  <Typography style={{ color: "red" }} variant="body1">
                    {errors.TenTC?.message}
                  </Typography>
                </div>
              </div>
              <div style={{ marginTop: 10 }}>
                <TextField
                  label="Địa chỉ tổ chức"
                  fullWidth
                  defaultValue={data.DiaChi}
                  name="DCToChuc"
                  {...register("DCToChuc")}
                  error={Boolean(errors.DCToChuc)}
                  style={{ marginTop: 5 }}
                ></TextField>
                <Typography style={{ color: "red" }} variant="body1">
                  {errors.DCToChuc?.message}
                </Typography>
                <TextField
                  label="Số điện thoại"
                  fullWidth
                  defaultValue={data.SDT}
                  style={{ marginTop: 10 }}
                  name="phone"
                  {...register("phoneNumber")}
                  error={Boolean(errors.phoneNumber)}
                ></TextField>
                <Typography style={{ color: "red" }} variant="body1">
                  {errors.phoneNumber?.message}
                </Typography>
                {data.loai === "CoQuanKD" && (
                <FormControl fullWidth style={{marginTop: 10}}>
                <InputLabel>Tiêu chuẩn CQKD</InputLabel>
                <Select label="Tiêu chuẩn CQKD" defaultValue={data.tieuchuan}
                {...register("TieuChuanKD")}>
                  <MenuItem value={"TatCa"}>Cơ quan kiểm định tiêu chuẩn</MenuItem>
                  <MenuItem value={"VietGaps"}>CQKD chuẩn VietGaps</MenuItem>
                </Select>
              </FormControl>
              )}
              </div>
            </DialogContent>
            <DialogActions>
              <Button type="submit">Lưu thay đổi</Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </div>
  );
}

export default SuaToChuc;
