import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import SanPhamAPI from '../../../Api/SanPhamAPI';
import { useSnackbar } from 'notistack';
import * as Yup from "yup";
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { Button, FormControl, IconButton, ImageList, ImageListItem, Input, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import './GiaoViec.css';
import renderHTML from 'react-render-html';
import callApi from '../../../Api/userApi';
import { log } from 'util';
GiaoViec.propTypes = {
    infoUser: PropTypes.object,
};

function GiaoViec({ infoUser }) {
    const id = useParams().id;
    const [image1, setImage1] = useState(require("../../../image/imageIcon.png"));
    const [data, setData] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {
        handleSubmit,
        formState,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm();
    const [ttSanPham, setTTSanPham] = useState(null);
    const [user, setUser] = useState(null);
    const [qtcuoi, setqtcuoi] = useState(true);
    const [targetFile1, setTargetFile1] = useState(null);
    let formdata = new FormData();
    const img1 = (e) => {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setImage1(reader.result); //base64encoded string
        };
        setTargetFile1(e.target.files[0]);
    };
    useEffect(() => {
        SanPhamAPI.getQuyTrinh(id).then((res) => {
            setData(res);
            for (var i = 0; i < res.length; i++) {
                if (res[i].TTKy != true) {
                    setqtcuoi(false);
                    break;
                }
            }
        })
        SanPhamAPI.truyvanqt(id).then((res) => {
            if(res.length > 0) {
                setqtcuoi(false)
            }
        })
        SanPhamAPI.getInfoProduct(id).then((res) => {
            setTTSanPham(res);

        })
        callApi.getUserToChuc(infoUser.accessToken, infoUser.phongban).then((res) => {
            setUser(res);
        })

    }, [])
    const navigate = useNavigate();
    const submitForm = async (e) => {
        var datas = [];
        for (var i = 0; i < data.length; i++) {
            datas[i] = {
                ID: data[i].ID,
                NguoiKy: e.select[i + 1]
            }
        }
        SanPhamAPI.SubMItGiaoViec(datas).then(() => {
            enqueueSnackbar("Phân công quy trình thành công", { variant: "success" });
            navigate('/advkd/quytrinh')
        }).catch(() => {
            enqueueSnackbar("Lỗi không xác định", { variant: "error" });
        })

    }
    var dem = 0;
    const taihinh = async() => {
        if (targetFile1 == null) {
            enqueueSnackbar("Bạn chưa chọn hình ảnh quyết định", { variant: "error" });
            return;
        }
        let fd = new FormData();
        fd.append('image',targetFile1);
        await fetch("https://image.banlait.com:3000/uploadImage", {
            method: "POST",
            body: fd,
        })
            .then((response) => response.json())
            .then((result) => {
                console.log(result);
                SanPhamAPI.taomoiqt(id, infoUser.ID, result.path).then(() => {
                    enqueueSnackbar("Thành công", { variant: "success" });
                    setqtcuoi(false)
                })
        })
    }
    return (
        <div>
            <Typography variant='h6'>Phân công quy trình cho sản phẩm</Typography>
            <Typography variant='h6'>Tại đây bạn có thể phân công quy trình cho sản phẩm của mình</Typography>
            <br></br>
            <Typography variant='h6' style={{ color: 'red' }}>Thông tin về sản phẩm</Typography>
            {ttSanPham != null && (
                <>
                    <Typography variant='subtitle1'>Tên sản phẩm: {ttSanPham.TenSP}</Typography>
                    <Typography variant='subtitle1'>Loại sản phẩm: {ttSanPham.LoaiSP}</Typography>
                    <Typography variant='subtitle1'>Lô hàng: {ttSanPham.lo}</Typography>
                    <Typography variant='subtitle1'>Ngày sản xuất: {ttSanPham.NgaySX}</Typography>
                    <Typography variant='subtitle1'>Thời hạn sử dụng: {ttSanPham.ThoiHanSD}</Typography>
                    <Typography variant='subtitle1'>Mô tả về sản phẩm:</Typography>
                    <div className='ttSP_div'>
                        {renderHTML(ttSanPham.MoTa)}
                    </div>
                    <Typography variant='subtitle1'>Hình ảnh sản phẩm(nếu có)</Typography>
                    <ImageList sx={{ width: 800, height: 250 }} cols={3} rowHeight={164}>
                        {ttSanPham.HinhAnhSP.hinh1 != "null" &&
                            <ImageListItem key={ttSanPham.HinhAnhSP.hinh1}>
                                <img
                                    src={`${ttSanPham.HinhAnhSP.hinh1}`}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        }

                        {ttSanPham.HinhAnhSP.hinh2 != "null" &&
                            <ImageListItem key={ttSanPham.HinhAnhSP.hinh2}>
                                <img
                                    src={`${ttSanPham.HinhAnhSP.hinh2}`}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        }
                        {ttSanPham.HinhAnhSP.hinh3 != "null" &&
                            <ImageListItem key={ttSanPham.HinhAnhSP.hinh3}>
                                <img
                                    src={`${ttSanPham.HinhAnhSP.hinh3}`}
                                    loading="lazy"
                                />
                            </ImageListItem>}
                    </ImageList>

                    <form onSubmit={handleSubmit(submitForm)}>
                        {data != null && data.map((info) => {
                            dem++;
                            return (
                                <>
                                    <Typography variant='h6'>Quy trình thứ: {dem}</Typography>
                                    <Typography variant='caption1'>Tên quy trình: {info.TenQuyTrinh}</Typography>
                                    <br></br>
                                    <Typography variant='caption1'>Mô tả về quy trình: {info.GhiChu}</Typography>
                                    <br></br>
                                    <br></br>
                                    <Typography variant='caption1'>Chọn người xác thực quy trình</Typography>
                                    <br></br>
                                    <br></br>
                                    <FormControl fullWidth>
                                        <InputLabel id="userGiao">Người thực hiện quy trình</InputLabel>
                                        <Select
                                            {...register(`select[${dem}]`)}
                                            key={info.ID}
                                            label="Người thực hiện quy trình"
                                            defaultValue={info.UserGiao}
                                        >
                                            {user != null && user.map((res) => {

                                                return (
                                                    <MenuItem value={res.ID}>{res.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </FormControl>
                                </>
                            )
                        })}
                        <div className='btn_submit'>
                            <Button type='submit' variant="contained">Hoàn tất</Button>
                        </div>

                    </form>
                    <Typography style={{color: 'green', fontSize: 25}} variant='caption1'>Xác thực toàn bộ quy trình cho sản phẩm</Typography>
                    <br></br>
                    {qtcuoi == true ? (
                        <>
                            <Typography variant='caption1'>Up quyết định công nhận toàn bộ quy trình:</Typography>
                            <br></br>
                            <IconButton aria-label="upload picture" component="label">
                                <img src={image1} className="img_1" />
                                <input
                                    hidden
                                    accept="image/jpg, image/jpeg"
                                    type="file"
                                    onChange={img1}
                                ></input>
                            </IconButton>
                            <Button onClick={taihinh}>Upload</Button>
                        </>
                    ) : (
                        <>
                                <Typography style={{ color: 'red', fontWeight: 'bold', fontSize: 20 }} variant='caption1'>Không thể xác thực quy trình cuối vì chưa ký xong toàn bộ quy trình hoặc sản phẩm đã ký quy trình cuối</Typography>
                        </>
                    )}
                </>
            )
            }
            {/* <form onSubmit={handleSubmit(submitForm)}>
                {data.map((res) => {
                    return (
                        <h1>Test</h1>
                    )
                })}
            </form> */}
        </div >
    );
}

export default GiaoViec;