import React from 'react';
import PropTypes from 'prop-types';
import { pt } from 'date-fns/locale';
import { ReactTitle } from 'react-meta-tags';
import { Paper, Step, StepButton, Stepper, Typography } from '@mui/material';
import SanPhamAPI from '../../Api/SanPhamAPI';
import { useState } from 'react';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import EditProductStep0 from './EditProductStep0';
import EditProductStep1 from './EditProductStep1';
import EditProductStep2 from './EditProductStep2';
import SanPhamStore from '../../Zustand/SanPham';
EditProduct.propTypes = {

};

function EditProduct() {
    let token = localStorage.getItem("accessToken");

    const [infoProduct, setInfoProduct] = useState(null);
    const { ID } = useParams();
    useEffect(() => {
        SanPhamAPI.getInfoProduct(ID).then((info) => setInfoProduct(info))
    }, []);
    const steps = ["Chỉnh sửa thông tin sản phẩm", "Chỉnh sửa các quy trình", "Hoàn tất"];
    const [activeStep, setActiveStep] = React.useState(0);
    const [completed, setCompleted] = React.useState({});
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const totalSteps = () => {
        return steps.length;
    };
    const [step1, setStep1] = useState();
    const [step2, setStep2] = useState();

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step) => () => {
        setActiveStep(step);
    };

    const handleComplete = () => {
        const newCompleted = completed;
        newCompleted[activeStep] = true;
        setCompleted(newCompleted);
        handleNext();
    };
    const navigate = useNavigate();
    const handleReset = () => {
        setActiveStep(0);
        setCompleted({});
    };
    const motaqt = SanPhamStore((state) => state.motaQT);
    const tenqt = SanPhamStore((state) => state.tenqt);
    const chuan = SanPhamStore((state) => state.loai);
    const submitData = () => {
        console.log(activeStep);
        step1.motaqt = motaqt;
        step1.tenqt = tenqt;
        step1.chuan = chuan;
        const data = {
            step0: step1,
            step1: step2,
        }
        SanPhamAPI.updateSP(data).then((res) => {
            enqueueSnackbar(res.mess, { variant: 'success' });
            navigate("/nhasx/product", { replace: true });
        }).catch((res) => {
            enqueueSnackbar("Có lỗi xảy ra, vui lòng thử lại", { variant: 'error' });
            navigate("/nhasx/product", { replace: true });
        })
    }
    return (
        <div>
            {infoProduct != null &&
                <div>
                    <Paper>
                        <Stepper nonLinear activeStep={activeStep}>
                            {steps.map((label, index) => (
                                <Step key={label} completed={completed[index]}>
                                    <StepButton color="inherit">
                                        {label}
                                    </StepButton>
                                </Step>
                            ))}
                        </Stepper>
                        
                        {activeStep == 0 && <EditProductStep0 infoProduct={infoProduct} token={token} setStep={setStep1} next={handleNext} />}
                        {activeStep == 1 && <EditProductStep1 infoProduct={infoProduct} token={token} setStep={setStep2} next={handleNext} />}
                        {activeStep == 2 && <EditProductStep2 submitData={submitData} />}
                    </Paper>
                </div>}
        </div>
    );
}

export default EditProduct;