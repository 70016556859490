import { Search } from "@mui/icons-material";
import { Button, Input, Skeleton, Typography } from "@mui/material";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import * as React from "react";
import { ReactTitle } from "react-meta-tags";
import callApi from "../../Api/userApi";
import UseStore from "../../Zustand/zustand";
import RowTable from "../RowTable";
import BangInfoToChuc from "./BangInfoToChuc";
import "./OrganizaTionPage.css";
import ThemMoiToChuc from "./ThemMoiToChuc";

OrganizaTionPage.propTypes = {};
const columns = [
  { id: "STT", label: "STT", minWidth: 20 },
  { id: "Tên tổ chức", label: "Tên tổ chức", minWidth: 10 },
  { id: "Địa chỉ", label: "Địa chỉ", minWidth: 10 },
  {
    id: "Số điện thoại",
    label: "Số điện thoại",
    minWidth: 10,
  },
  {
    id: "Loại cơ quan",
    label: "Loại cơ quan",
    minWidth: 5,
  },
  {
    id: "Trạng thái",
    label: "Trạng thái",
    minWidth: 5,
  },
  {
    id: "Thao tác",
    label: "",
    minWidth: 5,
  },
];

const rows = [
  {
    id: 1,
    value: 2,
  },
  {
    id: 2,
    value: 2,
  },
  {
    id: 3,
    value: 2,
  },
  {
    id: 4,
    value: 2,
  },
  {
    id: 5,
    value: 2,
  },
  {
    id: 6,
    value: 2,
  },
  {
    id: 7,
    value: 2,
  },
];

function OrganizaTionPage({ infoUser }) {
  const [page, setPage] = React.useState(0);
  const hotReload = UseStore((state) => state.hotReload);
  const setHotReload = UseStore((state) => state.setHotReload);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const bookmarkHistory = [
    "g1AAAABQeJzLYWBgYMpgSmHgKy5JLCrJTq2MT8lPzkzJBYoLMHzp378_My-zJDMxJ7MqNQWkjgOmDquKLAAOqxwD",
  ];
  const [rowPageGet, setRowPageGet] = React.useState({
    size: rowsPerPage,
    bookmark:
      "g1AAAABQeJzLYWBgYMpgSmHgKy5JLCrJTq2MT8lPzkzJBYoLMHzp378_My-zJDMxJ7MqNQWkjgOmDquKLAAOqxwD",
  });
  const [count, setCound] = React.useState();
  const handleChangeRowsPerPage = (event) => {
    setRowPageGet(event.target.value);
    setPage(0);
    setRowsPerPage(event.target.value);
    setRowPageGet({
      size: event.target.value,
      bookmark:
        "g1AAAABQeJzLYWBgYMpgSmHgKy5JLCrJTq2MT8lPzkzJBYoLMHzp378_My-zJDMxJ7MqNQWkjgOmDquKLAAOqxwD",
    });
  };
  const [data, setData] = React.useState(null);
  const handleChangePage = (event, newPage) => {
    bookmarkHistory.push(data.bookmark);
    if (newPage < page)
      data.bookmark = bookmarkHistory[bookmarkHistory.length - 2];
    setPage(newPage);
    setRowPageGet({
      ...rowPageGet,
      bookmark: data.bookmark,
    });
  };
  React.useEffect(() => {
    callApi.totalSizeToChuc(infoUser.accessToken).then((res) => setCound(res));
    callApi
      .getListToChuc(infoUser.accessToken, rowPageGet, "")
      .then((res) => setData(res));
  }, [rowPageGet, hotReload]);
  const timkiemtochuc = (e) => {
    if (!e.target.value) setHotReload(e);
    let res = [];
    for (let i = 0; i < data.results.length; i++) {
      if (data.results[i].TenCQ.indexOf(e.target.value) != -1)
        res.push(data.results[i]);
    }

    setData({
      ...data,
      results: res,
    });
  };
  const [openDialog, setOpenDialog] = React.useState(false);
  const oDialog = () => {
    setOpenDialog(true);
  };
  const cDialog = () => {
    setOpenDialog(false);
  };
  return (
    <>
      <ReactTitle title="Quản lý tổ chức - TRoot" />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <div className="search_box">
          <Search className="icon_search"></Search>
          <Input
            placeholder="Tìm kiếm tổ chức"
            onChange={timkiemtochuc}
          ></Input>
          <Button
            variant="outlined"
            sx={{ marginLeft: 2, flexWrap: "nowrap", fontSize: 12 }}
            className="btn_addUser"
            onClick={oDialog}
          >
            Thêm mới tổ chức
          </Button>
        </div>

        <TableContainer>
          <Table stickyHeader aria-label="sticky table">
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {data == null ? (
                rows.map((ske) => {
                  return (
                    <TableRow key={ske.id}>
                      {rows.map((skel) => {
                        return (
                          <TableCell key={skel.id}>
                            <Skeleton></Skeleton>
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })
              ) : (
                <BangInfoToChuc data={data} size={page * rowsPerPage + 1} />
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {count != undefined && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={count.total - 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
        <ThemMoiToChuc open={openDialog} onClose={cDialog} />
      </Paper>
      <div>
        <div className="OrganizaTionPage_cop">
          <img
            src={require("../../image/logo.png")}
            className="logo_troot"
          ></img>
        </div>

        <Typography>Copyright © 2023 T Services</Typography>
      </div>
    </>
  );
}

export default OrganizaTionPage;
