import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import SanPhamAPI from '../../Api/SanPhamAPI';

KyQT.propTypes = {

};

function KyQT({infoUser}) {
    const [datas, setData] = useState(null);
    const id = useParams().id;
    useEffect(() => {
        SanPhamAPI.getQTByID(id).then((data) => {
            setData(data);
        })
    }, [])
    const [oclose, setoClose] = useState(false);
    const openClose = () => {
        setoClose(!oclose);
    }
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const khongxacnhan = () => {
        SanPhamAPI.khongkyqt(id,infoUser.ID).then((res) => {
            enqueueSnackbar("Xác thực không ký cho quy trình thành công", { variant: 'success' });
            navigate(-1);
        })
    }
    const [openxt,setopenxt] = useState(false);
    const closext = () => {
        setopenxt(!openxt)
    }
    const xacnhan = () => {
        setLoading(true);
        if(contentFile == null) {
            enqueueSnackbar("Bạn chưa chọn khoá bí mật", { variant: 'error' });
            return ;
        }
        SanPhamAPI.ky(infoUser.ID,id,contentFile).then((e) => {
            enqueueSnackbar(e.mess, { variant: 'success' });
            closext();
            navigate(-1);
            setLoading(false);
        }).catch((e) => {
            enqueueSnackbar("Lỗi khi ký quy trình, có thể do khoá không chính xác", { variant: 'error' });
            closext();
            navigate(-1);
            setLoading(false);
        })
        setLoading(false);
    }
    const [contentFile,setContentFile] = useState(null);
    const handleFilesChange = (e) => {
        const reader = new FileReader();
        reader.readAsText(e.target.files[0]);
        reader.onload = () => {
            setContentFile(reader.result);
            console.log(reader.result);
        };
       
    }
    const [loading,setLoading] = useState(false);
    return (
        <>
        {loading ? (<Dialog
                open={true}
                style={{ background: "none" }
                }
                className="DiaLog_Loading"
            >
                <DialogContent>
                    <img
                        src={require("../../image/iconLoading.gif")}
                        className="icon_loading"
                    />
                </DialogContent>
            </Dialog >) : (
                <>
            {datas != null && (
                <div>
                    <Typography variant='h5'>Bạn đang xác thực cho quy trình: </Typography>
                    <Typography variant='caption1'>Tên quy trình: {datas.TenQuyTrinh}</Typography>
                    <br></br>
                    <Typography variant='caption1'>Mô tả quy trình: {datas.GhiChu}</Typography>
                    <Typography variant='h6' style={{ color: 'red' }}>Hãy kiểm tra sản phẩm này trong thực tế</Typography>
                    <Typography variant='h6' style={{ color: 'red' }}>Nếu sản phẩm này đạt chuẩn chất lượng, hãy bấm ký để xác thực cho quy trình</Typography>

                    <div style={{ display: 'flex', justifyContent: 'right' }}>
                        <Button variant='contained' style={{ marginRight: '10px' }} onClick={closext}>Ký</Button>
                        <Button variant='outlined' onClick={openClose}>Không ký</Button>
                    </div>
                    <Dialog
                        open={oclose}
                        onClose={openClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Bạn xác nhận không ký quy trình này"}
                        </DialogTitle>
                        <DialogContent>
                            Bạn có đồng ý không ký xác thực quy trình này ?. Yêu cầu này không thể hoàn tác
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={openClose}>Thoát</Button>
                            <Button onClick={khongxacnhan} autoFocus>
                                Xác nhận
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        open={openxt}
                        onClose={closext}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Bạn xác nhận ký quy trình này"}
                        </DialogTitle>
                        <DialogContent>
                            Để xác thực ký quy trình này, vui lòng chọn file khoá bí mật của bạn
                            <input type="file" onChange={handleFilesChange} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={closext}>Thoát</Button>
                            <Button onClick={xacnhan} autoFocus>
                                Xác nhận
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>)}
                </>)}
        </>
    );
}

export default KyQT;