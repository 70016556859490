import { Typography } from '@mui/material';
import './style.scss';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { Box } from '@mui/system';
import { Link } from 'react-router-dom'
Welcome.propTypes = {

};

function Welcome(props) {
    function goToSX() {

    }
    function nguoitieudung() {
        window.location.href = 'https://truyxuat.sytuan.net'
    }
    return (
        <div className='root'>
            <img src={require('../image/logo.png')} className='logo_st' />
            <h3 className='welcomeText'>Chào mừng bạn đến với TRoot - Giải pháp truy xuất nguồn gốc</h3>
            <p>Để tiếp tục sử dụng, vui lòng chọn phân quyền của bạn</p>
            <Box sx={{
                marginTop: 8,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                border: 'ActiveBorder'
            }}>
                <Link to='/admin/login' style={{ textDecoration: 'none' }}>
                    <Button variant="contained" >Bạn là doanh nghiệp</Button></Link>
                <Button variant="contained" style={{ marginTop: 80 }} onClick={nguoitieudung} >Bạn là người tiêu dùng</Button>
            </Box>
        </div>
    );
}

export default Welcome;