import React from "react";
import PropTypes from "prop-types";
import { Button, Paper, Typography } from "@mui/material";
import './TableProduct.css'
import { Navigate, useNavigate } from "react-router-dom";
import TableAndSkeletion from "../TableSanPham/TableAndSkeletion";
TableProduct.propTypes = {
  infoUser: PropTypes.object,
};

function TableProduct({ infoUser }) {
  const navigate = useNavigate();
  const addProduct = () => {
    return navigate("/anhasx/addProduct", { replace: true });
  }
  return (
    <div>
      <Paper>
        <div className="div_btn_header">
          <Button
            variant="outlined"
            sx={{ marginLeft: 2, flexWrap: "nowrap", fontSize: 12 }}
            className="btn_addUser"
            onClick={addProduct}
          >
            Thêm mới sản phẩm
          </Button>
        </div>
        <TableAndSkeletion />
      </Paper>
    </div>
  );
}

export default TableProduct;
