import React from 'react';
import PropTypes from 'prop-types';
import { Button, IconButton, MenuItem, Select, TextField, Typography } from '@mui/material';
import './productStep0.css'
import * as Yup from "yup";
import { useSnackbar } from 'notistack';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { useState } from 'react';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useEffect } from 'react';
import moment from 'moment/moment';
import { ContentState, EditorState } from 'draft-js';
import SanPhamStore from '../../Zustand/SanPham';
import { convertFromHTML, convertToHTML } from 'draft-convert';
import htmlToDraft from 'html-to-draftjs';
import { LoadingButton } from '@mui/lab';
import UseStore from '../../Zustand/zustand';

EditProductStep0.propTypes = {
    token: PropTypes.string,
    infoProduct: PropTypes.object,
};

function EditProductStep0({ infoProduct, token, setStep, next }) {
    const [otherOpen, setOtherOpen] = useState(true);
    const [targetFile1, setTargetFile1] = useState(null);
    const [targetFile2, setTargetFile2] = useState(null);
    const [targetFile3, setTargetFile3] = useState(null);
    const [loaisp, setLoaiSP] = useState(infoProduct.loaisp);
    const [btloading, setBtLoading] = useState(false);
    const [image1, setImage1] = useState(require("../../image/imageIcon.png"));
    const [image2, setImage2] = useState(require("../../image/imageIcon.png"));
    const [image3, setImage3] = useState(require("../../image/imageIcon.png"));

    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const setMoTaHtml = SanPhamStore((state) => state.setMoTa);
    const mota = SanPhamStore((state) => state.mota);
    const setEditor = (edtState) => {
        setEditorState(edtState);
        setMoTaHtml(convertToHTML(editorState.getCurrentContent()));
    };
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const validationSchema = Yup.object().shape({
        tenSP: Yup.string().required("Không thể bỏ trống Tên sản phẩm"),
        HanSD: Yup.string().required("Sản phẩm phải có hạn sử dụng"),
        lohang: Yup.string().required("Không thể bỏ trống lô hàng"),
        NgaySX: Yup.string().required("Sản phẩm phải có ngày sản xuất"),
    });
    const img1 = (e) => {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            setImage1(reader.result); //base64encoded string

        };
        setTargetFile1(e.target.files[0]);
    };

    const img2 = (e) => {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);

        reader.onload = () => {
            setImage2(reader.result); //base64encoded string
        };
        setTargetFile2(e.target.files[0]);
    };
    const img3 = (e) => {
        var reader = new FileReader();
        reader.readAsDataURL(e.target.files[0]);
        reader.onload = () => {
            setImage3(reader.result); //base64encoded string
        };
        setTargetFile3(e.target.files[0]);
    };
    const showImgThat = () => {
        if (infoProduct.HinhAnhSP.hinh1 != "null") {
            setImage1(infoProduct.HinhAnhSP.hinh1)

        }
        else setTargetFile1("nothing");
        if (infoProduct.HinhAnhSP.hinh2 != "null") {
            setImage1(infoProduct.HinhAnhSP.hinh2)

        }
        else setTargetFile2("nothing");
        if (infoProduct.HinhAnhSP.hinh3 != "null") {
            setImage1(infoProduct.HinhAnhSP.hinh3)

        }
        else setTargetFile3("nothing");
    }

    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        handleSubmit,
        formState,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm(formOptions);
    const contentBlock = htmlToDraft(infoProduct.MoTa);
    useEffect(() => {
        if (infoProduct.LoaiSP === "Trái Cây" || infoProduct.LoaiSP === "Thịt, cá, trứng" || infoProduct.LoaiSP === "Rau, củ"
            || infoProduct.LoaiSP === "Đông lạnh" || infoProduct.LoaiSP === "Thực phẩm khô" || infoProduct.LoaiSP === "Bia, nước ngọt" || infoProduct.LoaiSP === "Sữa") setOtherOpen(false);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState)
        showImgThat();
    }, []);
    const changeValue = (e) => {
        if (e.target.value === "Khác") {
            setOtherOpen(true);
            infoProduct.LoaiSP = "";
        }
        setLoaiSP(e.target.value);
    }
    var value = new Date();
    value = moment(value).format("YYYY-MM-DD");
    let formData = new FormData();
    let infoUser;
    const res = UseStore((state) => {
        infoUser = {
            accessToken: state.accessToken,
            ID: state.ID,
            phongban: state.phongban,
            name: state.name,
            username: state.username,
            email: state.email,
            avatar: state.avatar,
            phone: state.phone
        }
    });
    if (infoUser == null) {
        window.location.href = "/";
        localStorage.clear();
    }
    if (!infoUser.accessToken) {
        window.location.href = "/";
        localStorage.clear();
    }
    const submitForm = async (e) => {
        setBtLoading(true)
        if (targetFile1 != null || targetFile1 != "nothing")
            formData.append("images", targetFile1);
        if (targetFile2 != null || targetFile2 != "nothing")
            formData.append("images", targetFile2);
        if (targetFile3 != null || targetFile3 != "nothing")
            formData.append("images", targetFile3);
        let img = null;
        await fetch("https://image.banlait.com:3000/uploadBulkImage", {
            method: "POST",
            body: formData,
        })
            .then((response) => response.json())
            .then((result) => {
                //img = result;
                img = [null, null, null];
                let dem = 0;
                console.log(targetFile1);
                if (targetFile1 != "nothing" && targetFile1 != null) {
                    img[0] = result[dem];
                    dem++;
                }
                else img[0] = null;
                if (targetFile2 != "nothing" && targetFile2 != null) {
                    img[1] = result[dem];
                    dem++;
                }
                else img[1] = null;
                if (targetFile3 != "nothing" && targetFile3 != null) {
                    img[2] = result[dem];
                    //dem++;
                }
                else img[2] = null;

            })

        var IDSPP = localStorage.getItem('phongban')
        const product = {
            tensp: e.tenSP,
            id: infoProduct.ID,
            loaisp: e.loaisp,
            lo: e.lohang,
            ngaysx: e.NgaySX,
            HanSD: e.HanSD,
            iduser: infoUser.ID,
            IDDN: IDSPP,
            mota: mota,
            hinh1: !img[0] ? infoProduct.HinhAnhSP.hinh1 != "null" ? infoProduct.HinhAnhSP.hinh1 : "null" : `https://image.banlait.com:3000/${img[0].path}`,
            hinh2: !img[1] ? infoProduct.HinhAnhSP.hinh2 != "null" ? infoProduct.HinhAnhSP.hinh2 : "null" : `https://image.banlait.com:3000/${img[1].path}`,
            hinh3: !img[2] ? infoProduct.HinhAnhSP.hinh3 != "null" ? infoProduct.HinhAnhSP.hinh3 : "null" : `https://image.banlait.com:3000/${img[2].path}`,
        };

        setStep(product);
        console.log(product);
        next();

    }
    return (
        <>
            <Typography variant='body1' className='header_mota' style={{ fontWeight: 'bold', fontSize: 25, color: "#3b3e66" }}>Mô tả sản phẩm</Typography>
            <Typography variant='body2' className='header_gioithieu' style={{ fontSize: 15 }}>Thông tin cơ bản giới thiệu sản phẩm</Typography>
            <form onSubmit={handleSubmit(submitForm)}>
                <div className='div_trencung'>
                    <div style={{ display: 'flex', marginTop: 5 }} className='div_top1'>
                        <Typography variant="caption1">
                            Tên sản phẩm <span style={{ color: "red" }}>(*)</span>
                        </Typography>
                        <TextField
                            fullWidth
                            style={{ marginTop: 5 }}
                            name="TenSP"
                            error={Boolean(errors.tenSP)}
                            defaultValue={infoProduct.TenSP}
                            {...register("tenSP")}
                        ></TextField>
                        <Typography variant="caption1" style={{ color: "red" }}>
                            {errors.tenSP?.message}
                        </Typography>
                    </div>
                    <div style={{ display: 'flex', marginTop: 5 }} className='div_top2'>
                        <Typography variant='caption1'>Loại SP <span style={{ color: "red" }}>(*)</span></Typography>
                        {otherOpen == false ?
                            <Select
                                className="option_loaisp"
                                style={{ marginTop: 5 }}
                                name="loaisp"
                                error={Boolean(errors.loaisp)}
                                onChange={changeValue}
                                defaultValue={infoProduct.LoaiSP}
                            >
                                <MenuItem value={"Trái Cây"}>Trái Cây</MenuItem>
                                <MenuItem value={"Thịt, cá, trứng"}>Thịt, cá, trứng</MenuItem>
                                <MenuItem value={"Rau củ"}>Rau củ</MenuItem>
                                <MenuItem value={"Đông lạnh"}>Đông lạnh</MenuItem>
                                <MenuItem value={"Gia vị"}>Gia vị</MenuItem>
                                <MenuItem value={"Thực phẩm khô"}>Thực phẩm khô</MenuItem>
                                <MenuItem value={"Bia, nước ngọt"}>Bia, nước ngọt</MenuItem>
                                <MenuItem value={"Sữa"}>Sữa</MenuItem>
                                <MenuItem value={"Khác"}>Khác</MenuItem>
                            </Select>
                            : <TextField fullWidth
                                style={{ marginTop: 5 }}
                                name="loaisp"
                                error={Boolean(errors.tenSP)}
                                onChange={changeValue}
                                {...register("loaisp")}
                                defaultValue={infoProduct.LoaiSP}
                                required
                            />}
                    </div>
                </div>
                <div className="div_tren3s">
                    <div className="div_thsds">
                        <Typography variant="caption1">
                            Ngày sản xuất <span style={{ color: "red" }}>(*)</span>
                        </Typography>
                        <TextField
                            id="date"
                            type="date"
                            error={Boolean(errors.NgaySX)}
                            {...register("NgaySX")}
                            defaultValue={infoProduct.NgaySX}
                            InputProps={{
                                inputProps: { max: value },
                            }}
                            required
                        />
                        <Typography style={{ color: "red" }} variant="caption1">
                            {errors.NgaySX?.message}
                        </Typography>
                    </div>
                    <div className="div_los">
                        <Typography variant="caption1">
                            Hạn sủ dụng <span style={{ color: "red" }}>(*)</span>
                        </Typography>
                        <TextField
                            id="date"
                            type="date"
                            error={Boolean(errors.HanSD)}
                            defaultValue={infoProduct.ThoiHanSD}
                            {...register("HanSD")}
                            InputProps={{
                                inputProps: { min: value },
                            }}
                        />
                        <Typography style={{ color: "red" }} variant="caption1">
                            {errors.HanSD?.message}
                        </Typography>
                    </div>
                </div>
                <div className='div_lohang'>
                    <Typography variant="caption1">
                        Lô hàng <span style={{ color: "red" }}>(*)</span>
                    </Typography>
                    <TextField
                        type="text"
                        {...register("lohang")}
                        error={Boolean(errors.lohang)}
                        defaultValue={infoProduct.lo}
                    ></TextField>
                    <Typography style={{ color: "red" }} variant="caption1">
                        {errors.lohang?.message}
                    </Typography>
                </div>
                <div className="div_motahtml">
                    <Typography variant="caption1">Mô tả về sản phẩm</Typography>
                    <div className="editor">
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={setEditor}
                        />
                    </div>
                </div>
                <div className='div_hinhanh'>
                    <Typography variant="caption1">Hình ảnh sản phẩm</Typography>
                    <div>
                        <IconButton aria-label="upload picture" component="label">
                            <img src={image1} className="img_1" />
                            <input
                                hidden
                                accept="image/jpg, image/jpeg"
                                type="file"
                                {...register("img1")}
                                onChange={img1}
                            ></input>
                        </IconButton>
                        <IconButton aria-label="upload picture" component="label">
                            <img src={image2} className="img_1" />
                            <input
                                hidden
                                accept="image/jpg, image/jpeg"
                                type="file"
                                {...register("img2")}
                                onChange={img2}
                            ></input>
                        </IconButton>
                        <IconButton aria-label="upload picture" component="label">
                            <img src={image3} className="img_1" />
                            <input
                                hidden
                                accept="image/jpg, image/jpeg"
                                type="file"
                                {...register("img3")}
                                onChange={img3}
                            ></input>
                        </IconButton>
                    </div>
                </div>
                <div className='button_submit'>
                    <LoadingButton type='submit' variant="contained" loading={Boolean(btloading)}>Bước kế tiếp</LoadingButton>
                </div>
            </form>
        </>
    );
}

export default EditProductStep0;