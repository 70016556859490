// component
import Iconify from "./Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "Trang chủ",
    path: "/advkd/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "Quản lý người dùng",
    path: "/advkd/users",
    icon: getIcon("mdi:users"),
  },
  {
    title: "Phân công quy trình",
    path: "/advkd/quytrinh",
    icon: getIcon("fluent-mdl2:processing"),
  },
  {
    title: "Xác thực quy trình",
    path: "/advkd/xnquytrinh",
    icon: getIcon("mdi-checkbox-marked"),
  },
  {
    title: "Quản lý khoá",
    path: "/advkd/key",
    icon: getIcon("material-symbols:key"),
  },
  {
    title: "Trang cá nhân",
    path: "/advkd/profile",
    icon: getIcon("icon-park-twotone:permissions"),
  },
];

export default navConfig;
