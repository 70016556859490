/* eslint-disable jsx-a11y/alt-text */
import { Button, ImageList, ImageListItem, Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import renderHTML from 'react-render-html';
import { Route, useNavigate, useParams } from 'react-router-dom';
import SanPhamAPI from '../../Api/SanPhamAPI';
import callApi from '../../Api/userApi';
import './GiaoViec.css';
XacThuc.propTypes = {
    infoUser: PropTypes.object,
};

function XacThuc({ infoUser }) {
    const id = useParams().id;
    const [data, setData] = useState(null);
    const { enqueueSnackbar, closeSnackbar } = useSnackbar();
    const {
        handleSubmit,
        formState,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm();
    const [ttSanPham, setTTSanPham] = useState(null);
    const [user, setUser] = useState(null);

    useEffect(() => {
        SanPhamAPI.pccq(infoUser.ID, infoUser.accessToken, id).then((res) => {
            let datass = [];
            for(let i = res.length - 1; i >= 0;i--) {
                datass.push(res[i]);
            }
            setData(datass)

        })
        SanPhamAPI.getInfoProduct(id).then((res) => {
            setTTSanPham(res);

        })
        callApi.getUserToChuc(infoUser.accessToken, infoUser.phongban).then((res) => {
            setUser(res);
        })

    }, [])
    const navigate = useNavigate();
    const submitForm = async (e) => {
        var datas = [];
        for (var i = 0; i < data.length; i++) {
            datas[i] = {
                ID: data[i].ID,
                NguoiKy: e.select[i + 1]
            }
        }
        SanPhamAPI.SubMItGiaoViec(datas).then(() => {
            enqueueSnackbar("Phân công quy trình thành công", { variant: "success" });
            navigate('/advkd/quytrinh')
        }).catch(() => {
            enqueueSnackbar("Lỗi không xác định", { variant: "error" });
        })

    }
    var dem = 0;

    return (
        <div>
            <Typography variant='h6'>Xác thực quy trình cho sản phẩm</Typography>
            <Typography variant='h6'>Dưới đây là toàn bộ thông tin của sản phẩm và các quy trình</Typography>
            <Typography variant='h6'>Hãy kiểm định sản phẩm trong thực tế và xác thực vào hệ thống</Typography>
            <br></br>
            <Typography variant='h6' style={{ color: 'red' }}>Thông tin về sản phẩm</Typography>
            {ttSanPham != null && (
                <>
                    <Typography variant='subtitle1'>Tên sản phẩm: {ttSanPham.TenSP}</Typography>
                    <Typography variant='subtitle1'>Loại sản phẩm: {ttSanPham.LoaiSP}</Typography>
                    <Typography variant='subtitle1'>Lô hàng: {ttSanPham.lo}</Typography>
                    <Typography variant='subtitle1'>Ngày sản xuất: {ttSanPham.NgaySX}</Typography>
                    <Typography variant='subtitle1'>Thời hạn sử dụng: {ttSanPham.ThoiHanSD}</Typography>
                    <Typography variant='subtitle1'>Mô tả về sản phẩm:</Typography>
                    <div className='ttSP_div'>
                        {renderHTML(ttSanPham.MoTa)}
                    </div>
                    <Typography variant='subtitle1'>Hình ảnh sản phẩm(nếu có)</Typography>
                    <ImageList sx={{ width: 800, height: 300 }} cols={3} rowHeight={164}>
                        {ttSanPham.HinhAnhSP.hinh1 != "null" &&
                            <ImageListItem key={ttSanPham.HinhAnhSP.hinh1}>
                                <img
                                    src={`${ttSanPham.HinhAnhSP.hinh1}`}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        }

                        {ttSanPham.HinhAnhSP.hinh2 != "null" &&
                            <ImageListItem key={ttSanPham.HinhAnhSP.hinh2}>
                                <img
                                    src={`${ttSanPham.HinhAnhSP.hinh2}`}
                                    loading="lazy"
                                />
                            </ImageListItem>
                        }
                        {ttSanPham.HinhAnhSP.hinh3 != "null" &&
                            <ImageListItem key={ttSanPham.HinhAnhSP.hinh3}>
                                <img
                                    src={`${ttSanPham.HinhAnhSP.hinh3}`}
                                    loading="lazy"
                                />
                            </ImageListItem>}
                    </ImageList>

                    <div>
                        {data != null && data.map((rs) => {
                            dem++;

                            return (
                                <div>
                                    <Typography variant='h5' style={{ fontWeight: 'bold' }}>Quy trình thứ {dem}:</Typography>
                                    <Typography variant='caption1' style={{ fontSize: 15 }}>Tên quy trình: {rs.TenQuyTrinh}</Typography>
                                    <br></br>
                                    <Typography variant='caption1' style={{ fontSize: 15 }}>Mô tả về quy trình {rs.GhiChu}</Typography>
                                    {rs.TTKy == false ? (
                                        
                                    <div className='button_ky'>
                                        <Button variant='contained' onClick={() => {
                                            navigate('/advkd/ky/' + rs.ID)
                                        }}>Xác thực quy trình</Button>
                                    </div>) : (
                                    
                                    <div className='button_ky'>
                                        <Typography>Bạn đã xác thực quy trình này</Typography>
                                    </div>
                                    )}
                                    <br></br>
                                </div>
                            )
                        })}

                    </div>
                </>
            )
            }
            {/* <form onSubmit={handleSubmit(submitForm)}>
                {data.map((res) => {
                    return (
                        <h1>Test</h1>
                    )
                })}
            </form> */}
        </div >
    );
}

export default XacThuc;