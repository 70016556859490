import { yupResolver } from "@hookform/resolvers/yup";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import {
  AppBar,
  Avatar,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  Input,
  InputLabel,
  LinearProgress,
  Menu,
  MenuItem,
  Select,
  Typography
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import callApi from "../../Api/userApi";
import UseStore from "../../Zustand/zustand";
import "./SuaNguoiDung.css";
function SuaNguoiDung({ info, handleClose, openSua }) {
  let formData = new FormData();
  const [vaitro, setVT] = useState();
  const actoken = UseStore((state) => state.accessToken);
  const setAvatar = UseStore((state) => state.setAvatar);
  const userID = UseStore((state) => state.ID);
  const setVaitro = (e) => {
    setVT(e.target);
  };
  const imageAvatar = (e) => {
    e.preventDefault();
    formData.append("image", e.target.files[0]);
    fetch("https://image.banlait.com:3000/uploadImage", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        if (userID == info.Value.ID) {
          setAvatar(`https://image.banlait.com:3000/${result.path}`);
          localStorage.setItem(
            "avatar",
            `https://image.banlait.com:3000/${result.path}`
          );
        }

        const callApii = callApi.updateAvatar(
          info.Value.ID,
          `https://image.banlait.com:3000/${result.path}`,
          actoken
        );
        info.avatar = `https://image.banlait.com:3000/${result.path}`;
        callApii.catch((e) => {
          enqueueSnackbar("Đổi Avatar thất bại", { variant: "error" });
          return;
        });
        enqueueSnackbar("Đổi Avatar thành công", { variant: "success" });
        info.avatar = ` https://image.banlait.com/${result.path}`;
        setVT(1);
      })
      .catch(() => {
        console.log(e);
      });
  };
  const avatar =
    info.Value.avatar != null
      ? info.Value.avatar
      : `https://avatar.oxro.io/avatar.svg?name=${info.Value.name}&background=f39c12&length=2`;
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Không thể bỏ trống Tên người dùng"),
    email: Yup.string()
      .email("Phải là một email hợp lệ")
      .required("Không thể bỏ trống Email"),
    phoneNumber: Yup.string()
      .phone("VN", false, "SĐT không đúng định dạng")
      .required("Số điện thoại không được để trống"),
  });
  const hotReload = UseStore((state) => state.setHotReload);
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    handleSubmit,
    formState,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm(formOptions);
  const [submit, setSubmit] = useState(false);
  const formSubmit = async (e) => {
    setSubmit(true);
    enqueueSnackbar("Vui lòng chờ, hệ thống đang xử lý", { variant: "info" });
    const res = {
      id: info.Value.ID,
      name: e.name,
      email: e.email,
      phone: e.phoneNumber,
      vaitro: e.qvaitro,
      phongban: e.coquan,
    };
    await callApi
      .updateUser(actoken, res)
      .then((ress) => {

        closeSnackbar();
        enqueueSnackbar("Thành công!", { variant: "success" });
        handleClose();
        hotReload(res.id);
      })
      .catch((e) => {
        closeSnackbar();
        enqueueSnackbar(`Có lỗi: ${e}`, { variant: "error" });
      });
    setSubmit(false);
  };
  const [toChuc, setToChuc] = useState(0);
  useEffect(() => {
    callApi.getAllToChuc(actoken).then((res) => setToChuc(res));
  }, []);
  return (
    <div>
      {submit ? (
        <Dialog
          open={true}
          style={{ background: "none" }}
          className="DiaLog_Loading"
        >
          <DialogContent>
            <img
              src={require("../../image/iconLoading.gif")}
              className="icon_loading"
            />
          </DialogContent>
        </Dialog>
      ) : (
        <div>
          <Dialog open={openSua} onClose={handleClose}>
            {isSubmitting == true && (
              <LinearProgress style={{ color: "white" }} />
            )}
            <AppBar position="static">
              <div style={{ display: "flex" }}>
                <IconButton style={{ color: "white" }} onClick={handleClose}>
                  <CloseIcon />
                </IconButton>
                <Typography
                  variant="h6"
                  color="inherit"
                  style={{ alignSelf: "center" }}
                >
                  Sửa người dùng {info.Value.username}
                </Typography>
              </div>
            </AppBar>
            <form
              style={{ marginTop: "4vh" }}
              onSubmit={handleSubmit(formSubmit)}
            >
              <div style={{ display: "flex" }}>
                <IconButton
                  aria-label="upload picture"
                  component="label"
                  style={{
                    width: "5%",
                    height: "10%",
                    display: "flex",
                    marginLeft: "2vw",
                  }}
                >
                  <input
                    hidden
                    accept="image/jpg, image/png"
                    type="file"
                    onChange={imageAvatar}
                  />
                  <Avatar src={avatar} alt="photoURL" className="avatar" />
                </IconButton>
                <Box className="box_nd">
                  <Box style={{ display: "flex" }}>
                    <div className="div_tennd">
                      <InputLabel className="input_tnd">
                        Tên người dùng
                      </InputLabel>
                      <Input
                        placeholder="Tên người dùng"
                        defaultValue={info.Value.name}
                        fullWidth
                        id="name"
                        label="Tên người dùng"
                        name="name"
                        error={Boolean(errors.name)}
                        {...register("name")}
                      />
                      <Typography style={{ color: "red" }}>
                        {errors.name?.message}
                      </Typography>
                    </div>
                    <div className="div_email">
                      <InputLabel className="input_tnd">Email</InputLabel>
                      <Input
                        placeholder="Email"
                        defaultValue={info.Value.email}
                        fullWidth
                        id="email"
                        label="Địa chỉ Email"
                        name="email"
                        error={Boolean(errors.email)}
                        {...register("email")}
                      />
                      <Typography style={{ color: "red" }}>
                        {errors.email?.message}
                      </Typography>
                    </div>
                  </Box>
                  <Box className="box_dongduoi">
                    <div className="div_sdt">
                      <InputLabel className="input_tnd">
                        Số điện thoại
                      </InputLabel>
                      <Input
                        placeholder="Email"
                        defaultValue={info.Value.phone}
                        fullWidth
                        error={Boolean(errors.phoneNumber)}
                        {...register("phoneNumber")}
                      />
                      <Typography style={{ color: "red" }}>
                        {errors.phoneNumber?.message}
                      </Typography>
                    </div>
                    <div className="div_vaitro">
                      <FormControl
                        variant="outlined"
                        style={{ width: "100%", marginBottom: 32 }}
                      >
                        <InputLabel
                          id="test-select-label"
                          className="select_vt"
                        >
                          Vai trò
                        </InputLabel>
                        <Select
                          style={{ width: "100%", marginTop: "1vh" }}
                          variant="outlined"
                          label={"Vai trò"}
                          className="select_vt"
                          fullWidth
                          defaultValue={info.Value.vaitro}
                          name="Vaitro"
                          {...register("qvaitro")}
                        >
                          <MenuItem key={"Admin"} value="Admin">
                            Admin
                          </MenuItem>
                          <MenuItem key={"NhaSX"} value="NhaSX">
                            Nhân viên Nhà Sản xuất
                          </MenuItem>
                          <MenuItem key={"ANhaSX"} value="ANhaSX">
                            Admin Nhà sản xuất
                          </MenuItem>
                          <MenuItem key={"CQKD"} value="CQKD">
                            Admin Cơ quan kiểm định
                          </MenuItem>
                          <MenuItem key={"NCQKD"} value="NCQKD">
                            Nhân viên cơ quan kiểm định
                          </MenuItem>

                        </Select>
                      </FormControl>
                    </div>
                  </Box>
                  <Box>
                    <FormControl
                      variant="outlined"
                      style={{ marginTop: "-1vh" }}
                      fullWidth
                    >
                      <InputLabel id="test-select-label">
                        Đơn vị cơ quan
                      </InputLabel>
                      <Select
                        style={{ width: "90%" }}
                        variant="outlined"
                        name="idcq"
                        label={"Đơn vị cơ quan"}
                        onChange={setVaitro}
                        defaultValue={info.Value.phongban}
                        {...register("coquan")}
                      >
                        {toChuc != 0 &&
                          toChuc.map((res) => {
                            if (res.record.trangthai == "true") {
                              return (
                                <MenuItem
                                  key={res.record.ID}
                                  value={res.record.ID}
                                >
                                  {res.record.TenCQ}
                                </MenuItem>
                              );
                            }
                          })}
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
              </div>
              <div style={{ display: "flex", justifyContent: "right" }}>
                <Button type="submit">
                  <DoneIcon />
                  Cập nhật
                </Button>
              </div>
            </form>
          </Dialog>
        </div>
      )}
    </div>
  );
}

export default SuaNguoiDung;
