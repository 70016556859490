import { useState } from "react";
import { Outlet } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
//
import UseStore from "../Zustand/zustand";
import DashboardNavbar from "./DashboardNavbar";
import DashboardSidebar from "./DashboardSidebar";
import UserPage from "./UserPage";
import { ReactTitle } from "react-meta-tags";

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled("div")({
  display: "flex",
  minHeight: "100%",
  overflow: "hidden",
});

const MainStyle = styled("div")(({ theme }) => ({
  flexGrow: 1,
  overflow: "auto",
  minHeight: "100%",
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up("lg")]: {
    paddingTop: APP_BAR_DESKTOP + 24,
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));

// ----------------------------------------------------------------------

export default function ControlNguoiDung() {
  const [open, setOpen] = useState(false);
  let infoUser;
  const store = UseStore((state) => state.updateData);
  if (store.accessToken == undefined) {
    store(
      localStorage.getItem("accessToken"),
      localStorage.getItem("ID"),
      localStorage.getItem("phongban"),
      localStorage.getItem("name"),
      localStorage.getItem("username"),
      localStorage.getItem("email"),
      localStorage.getItem("avatar"),
      localStorage.getItem("phone")
    );
  }
  const res = UseStore((state) => {
    infoUser = {
      accessToken: state.accessToken,
      ID: state.ID,
      phongban: state.phongban,
      name: state.name,
      username: state.username,
      email: state.email,
      avatar: state.avatar,
      phone: state.phone,
    };
  });
  if (infoUser == null) {
    window.location.href = "/";
    localStorage.clear();
  }
  return (
    <RootStyle>
      <ReactTitle title="Quản lý người dùng - TRoot" />
      <DashboardNavbar
        onOpenSidebar={() => setOpen(true)}
        userInfo={infoUser}
      />
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
        userInfo={infoUser}
      />
      <MainStyle>
        <Outlet />
        <UserPage infoUser={infoUser} />
      </MainStyle>
    </RootStyle>
  );
}
