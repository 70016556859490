import create from 'zustand'
const UseStore = create((set) => ({
    accessToken: null,
    ID: null,
    phongban: null,
    name: null,
    username: null,
    email: null,
    avatar: null,
    phone: null,
    hotReload: 1,
    loading: false,
    updateData: (accessToken, ID, phongban, name, username2,email,avatar,phone) => set(() => ({ accessToken: accessToken, ID: ID, phongban: phongban, name: name, username: username2,email:email,avatar:avatar,phone:phone})),
    setAvatar: (avatar) => set(() => ({avatar:avatar})),
    setNamevsAvatar: (name, avatar) => set(() => ({ avatar: avatar,name:name })),
    setHotReload: (data) => set(() => ({ hotReload: data})),
    setBasicInfo: (name,email,phone) => set(() => ({name: name, email: email, phone: phone})),
    setLoading: (loading) => set(() => ({loading: loading}))
}))

export default UseStore;