// component
import Iconify from "./Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "Trang chủ",
    path: "/anhasx/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "Quản lý người dùng",
    path: "/anhasx/users",
    icon: getIcon("mdi:users"),
  },
  {
    title: "Quản lý sản phẩm",
    path: "/anhasx/product",
    icon: getIcon("bi:bag"),
  },
  {
    title: "Quản lý khoá",
    path: "/anhasx/key",
    icon: getIcon("material-symbols:key"),
  },
  {
    title: "Trang cá nhân",
    path: "/anhasx/profile",
    icon: getIcon("icon-park-twotone:permissions"),
  },
];

export default navConfig;
