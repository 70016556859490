import React from 'react';
import PropTypes from 'prop-types';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from '@mui/material';
import { Box } from '@mui/system';

DialogXemKhoa.propTypes = {
    data: PropTypes.object,
    onClose: PropTypes.func,
};

function DialogXemKhoa({data,onClose}) {
    return (
      <>
        <Dialog open={Boolean(data)} onClose={onClose}>
          <DialogTitle>
            Xem khoá công khai của người dùng {data.Value.name}
          </DialogTitle>
          <DialogContent>
            {data.Value.publickey ? (
              <>
                <Typography variant="body">
                  Khoá công khai của người dùng là:
                </Typography>
                <br></br>
                <Typography variant="body" style={{wordWrap: 'break-word'}}>
                  {data.Value.publickey}
                </Typography>
              </>
            ) : (
              <Typography variant="body">
                Người dùng chưa có khoá công khai, vui lòng nhắc nhở người dùng
                truy cập TRoot để tạo khoá công khai nhé !
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={onClose}>Đóng</Button>
          </DialogActions>
        </Dialog>
      </>
    );
}

export default DialogXemKhoa;