import { yupResolver } from "@hookform/resolvers/yup";
import SendIcon from "@mui/icons-material/Send";
import { Button, LinearProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import OTPInput, { ResendOTP } from "otp-input-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import callApi from "../Api/userApi";
import "./sendCode.scss";
import './passreset.css';
SendCode.propTypes = {};
var username;
// get functions to build form with useForm() hook

function SendCode(props) {
  let navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const validationSchema = Yup.object().shape({
    username: Yup.string().required("Không thể bỏ trống Username"),
  });
  const [getCode, setCode] = useState();
  const [getData, setData] = useState();
  const [getOK, setOK] = useState(false);
  const [valueOTP, setvalueOTP] = useState();
  const handleChange = (change) => {
    setvalueOTP(change);
  };
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    handleSubmit,
    formState,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm(formOptions);
  const Submit = async (data) => {
    username = await data.username;
      await callApi
        .sendMail(username)
        .then(async (ress) => await setData(ress))
        .catch((e) => console.log(e));
      if (getData === undefined) {
        enqueueSnackbar("Lỗi không xác định, vui lòng thử lại", {
          variant: "error",
        });
        return;
      }
      if (getData !== undefined && !getData.ID) {
        enqueueSnackbar("Username không tồn tại trong hệ thống", {
          variant: "error",
        });
        reset();
      } else if (getData.ID !== undefined && getData.ID) {
        enqueueSnackbar(
          "Đã gửi mã xác nhận về địa chỉ email của bạn, vui lòng điền mã xác thực để tiếp tục",
          { variant: "success" }
        );
        setOK(true);
        reset();
      }
  };
  const checkCode = async () => {
    if(!getData.ID) {
      enqueueSnackbar("Yêu cầu không hợp lệ",{variant: 'error'});
      return ;
    }
    else {
      await callApi.checkCode(getData.ID,valueOTP).then(res => setCode(res));
      if(getCode == undefined) {
        enqueueSnackbar("Vui lòng thử lại. Lỗi Server", {variant: 'error'});
        return ;
      }
      else if(!getCode.success) {
        enqueueSnackbar("Mã OTP không hợp lệ", {variant: 'error'});
        return ;
      }
      else if(getCode != undefined && getCode.success) {
        enqueueSnackbar("Mã Code hợp lệ, đang chuyển hướng", {variant: 'success'});
        return navigate("/admin/resetPass", {
          replace: true,
          state: { id: getData.ID, code: valueOTP },
        });
      }
      
    }
  };
  async function resend() {
    await callApi
      .sendMail(username)
      .then(async (ress) => await setData(ress))
      .catch((e) => console.log(e));
    
    if (getData === undefined) {
      enqueueSnackbar("Lỗi không xác định, vui lòng thử lại", {
        variant: "error",
      });
      return;
    }
    if (getData !== undefined && !getData.ID) {
      enqueueSnackbar("Username không tồn tại trong hệ thống", {
        variant: "error",
      });
      reset();
    } else if (getData.ID !== undefined && getData.ID) {
      enqueueSnackbar(
        "Đã gửi lại mã xác nhận về địa chỉ email của bạn, vui lòng điền mã xác thực để tiếp tục",
        { variant: "success" }
      );
      setOK(true);
      reset();
    }
    
  }
  const renderTime = (remainingTime) => {
    return (
      <Typography>
        Nếu bạn chưa nhận được mã vui lòng thử lại sau {remainingTime} giây
      </Typography>
    );
  };
  const renderButton = (buttonProps) => {
    return (
      <Button {...buttonProps} variant="contained" style={{ marginLeft: 10 }}>
        Gửi lại
      </Button>
    );
  };
  const [mat, setMat] = useState(false);
  return (
    <div>
      {isSubmitting == true && <LinearProgress/>}
      <form onSubmit={handleSubmit(Submit)}>
        <img
          src={require("../image/imgChiaKhoa.gif")}
          className="imgChiaKhoa"
        ></img>
        {getOK == false ? (
          <>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: 25,
                color: "#3d4068",
                marginTop: 30,
              }}
            >
              Quên mật khẩu?
            </Typography>
            <Typography textAlign="center" marginTop={3}>
              Vui lòng nhập Username của bạn để nhận hướng dẫn khôi phục lại mật
              khẩu
            </Typography>
            <TextField
              id="username"
              label="Username"
              name="username"
              {...register("username", { required: true })}
              sx={{
                width: "20em",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginTop: 3,
              }}
            />
            <Typography color="red" style={{ lineHeight: 2 }}>
              {errors.username?.message}
            </Typography>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              type="submit"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginTop: 3,
              }}
            >
              Gửi
            </Button>
          </>
        ) : (
          <>
            <Typography style={{ lineHeight: 3 }}>
              Chúng tôi đã gửi mã xác nhận 6 số vào Email của bạn
            </Typography>
            <Typography>Nhập mã xác thực để tiếp tục</Typography>
            <Box
              sx={{
                width: "20em",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginTop: 3,
              }}
            >
              <OTPInput
                value={valueOTP}
                onChange={handleChange}
                OTPLength={6}
                otpType="number"
                disabled={false}
                secure
                renderTime
              />
            </Box>
            <Box
              sx={{
                width: "32em",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginTop: 3,
              }}
            >
              <ResendOTP
                onResendClick={resend}
                renderButton={renderButton}
                renderTime={renderTime}
              />
            </Box>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              type="button"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginTop: 3,
              }}
              onClick={checkCode}
            >
              Kiểm tra code
            </Button>
          </>
        )}
      </form>
      <Typography style={{lineHeight: 9}}>Copyright © 2023 T Services</Typography>
    </div>
  );
}

export default SendCode;
