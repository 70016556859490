import React from 'react';
import PropTypes from 'prop-types';
import { Autocomplete, FormControl, FormControlLabel, FormLabel, Radio, RadioGroup, TextField } from '@mui/material';
import { useState } from 'react';
import ProductStep1 from './ProductStep1';
import { ReactTitle } from 'react-meta-tags';
import VietGapsStep from './ProductStepVG';
import SanPhamStore from '../../Zustand/SanPham';

ProductStep11.propTypes = {

};

function ProductStep11({ infoUser, next, setStep }) {
    <ReactTitle title="Bước 2 - Thêm sản phẩm - TRoot"></ReactTitle>
    const quytrinhVG = [{
        label: "Trái cây",
        thongtin: [
            { dieukien: "Chuẩn bị môi trường và giống" },
            {
                dieukien: "Gieo trồng"
            },
            {
                dieukien: "Chăm sóc"
            },
            {
                dieukien: "Thu hoạch"
            },
            { dieukien: "Đóng gói" }
        ]
    }, {
        label: "Rau",
        thongtin: [{
            dieukien: "Chuẩn bị môi trường"
        },
        {
            dieukien: "Nước tưới"
        },
        {
            dieukien: "Chọn giống và gieo trồng"
        },
        {
            dieukien: "Phân bón"
        },
        {
            dieukien: "Phòng trừ sâu bệnh"
        },
        {
            dieukien: "Thu hoạch"
        },
        {
            dieukien: "Sơ chế và kiểm tra"
        },
        { dieukien: "Bảo quản" }]
    },
    {
        label: "Thủy sản",
        thongtin: [{
            dieukien: "Địa điểm nuôi, cơ sở hạ tầng, trang thiết bị"
        },
        {
            dieukien: "Điều kiện nuôi trồng thủy sản"
        },
        {
            dieukien: "Sử dụng nước"
        },
        {
            dieukien: "Đánh bắt và chế biến"
        },
        {
            dieukien: "Đóng gói và bảo quản"
        }]
    }
    ]
    const [quytrinh, setQuyTrinh] = useState()
    const [qtvg, setQtvg] = useState(null)
    const setLoaiAdd = SanPhamStore((state) => state.setLoai);

    const changeQuyTrinh = (e) => {
        setQuyTrinh(e.target.value)
        if (e.target.value == 'All') {
            setQtvg(null)
        }
        if (e.target.value == 'All') setLoaiAdd("All");
        else setLoaiAdd("VietGaps")
    }

    return (
        <div>
            <div style={{ display: 'flex', justifyContent: 'center' }}>
                <FormControl>
                    <FormLabel id="Select_QT" style={{ justifyContent: 'center', display: 'flex' }}>Chọn loại quy trình</FormLabel>
                    <RadioGroup
                        row
                        aria-labelledby="RadioQT"
                        name="RadioQT"
                        onChange={changeQuyTrinh}
                    >
                        <FormControlLabel value="TatCa" control={<Radio />} label="Quy trình người dùng tự định nghĩa" />
                        <FormControlLabel value="VietGaps" control={<Radio />} label="Quy trình VietGaps" />
                    </RadioGroup>
                </FormControl>
            </div>
            {quytrinh == 'TatCa' && <ProductStep1 infoUser={infoUser} next={next} setStep={setStep} />}
            {quytrinh == 'VietGaps' && (
                <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                    <Autocomplete
                        disablePortal
                        id="CBB"
                        style={{ width: '70%' }}
                        options={quytrinhVG}
                        onChange={(event, value) => setQtvg(value)}
                        renderInput={(params) => <TextField {...params} label="Loại sản phẩm" />}
                    />
                </div>

            )}
            {qtvg != null && <VietGapsStep infoUser={infoUser} next={next} setStep={setStep} dataQT={qtvg}></VietGapsStep>}
        </div>
    );
}

export default ProductStep11;