import { yupResolver } from "@hookform/resolvers/yup";
import {
    Button,
    FormControl, Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import htmlToDraft from 'html-to-draftjs';
import { ContentState, EditorState } from "draft-js";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";
import { ReactTitle } from "react-meta-tags";
import * as Yup from "yup";
import callApi from "../../Api/userApi";
import SanPhamStore from "../../Zustand/SanPham";
import SanPhamAPI from "../../Api/SanPhamAPI";
EditProductStep1.propTypes = {

};

function EditProductStep1({ infoProduct, token, setStep, next }) {
    const [infoQuyTrinh, setInfoQuyTrinh] = useState();

    const validationSchema = Yup.object().shape({
        tenqtLon: Yup.string().required("Tên quy trình không thể bỏ trống")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const [bandau, setBandau] = useState(null);
    const [slbandau, setSLbandau] = useState(0);
    const quyTrinhChange = (e) => {

        if (e.target.value <= slbandau) {
            let obj = [];

            for (let i = 1; i <= e.target.value; i++) {
                obj.push(bandau[i - 1]);
            }
            // console.log(bandau);
            // console.log(obj);
            setCacbuoc(obj)
        }
        else {
            let obj = [];

            for (let i = 1; i <= slbandau; i++) {
                obj.push(bandau[i - 1]);
            }
            for (let i = slbandau + 1; i <= e.target.value; i++) {
                obj.push({ key: i });
            }

            setCacbuoc(obj)
        }

    };
    const {
        handleSubmit,
        formState,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm(formOptions);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const setMoTaQT = SanPhamStore((state) => state.setMoTaQT);
    const setEditor = (edtState) => {
        setEditorState(edtState);
        setMoTaQT(convertToHTML(editorState.getCurrentContent()));
    };

    const setTenQT = SanPhamStore((state) => state.setTenQT);
    const Submit = (e) => {
        let res = [];
        setTenQT(e.tenqtLon);
        for (let i = 1; i <= cacbuoc.length; i++) {
            res[i - 1] = {
                tenqt: e.tenqt[i],
                mota: e.mota[i],
                id: (i <= slbandau) ? bandau[i - 1].ID : "123",
                donvikd: e.donvikd[i],
            };
        }
        setStep(res);
        next();
    };
    const [donvikiemdinh, setDonViKiemDinh] = useState(null);
    const [cacbuoc, setCacbuoc] = useState(null);
    const [lengthdf, setlengdf] = useState(null);
    const contentBlock = htmlToDraft(infoProduct.motaqt);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => {
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        setEditorState(editorState);
        callApi.getAllToChuc(token).then((res) => {
            setDonViKiemDinh(res);
        });
        //let bd;
        SanPhamAPI.getQuyTrinh(infoProduct.ID).then((res) => {
            for (let i = 0; i < res.length; i++) {
                res[i]["key"] = i + 1;
            }
            setCacbuoc(res);
            setlengdf(res.length)
            setSLbandau(res.length);
            setBandau(res)
        })
    }, []);
    return (
        <div>
            <ReactTitle title="Bước 2 - Sửa sản phẩm - TRoot"></ReactTitle>
            <form onSubmit={handleSubmit(Submit)} className="root">
                <div style={{ marginTop: 3 }}>
                    <Typography
                        variant="h6"
                        sx={{ fontWeight: "bold", color: "#3b3e66" }}
                    >
                        Sửa quy trình sản xuất trong hệ thống
                    </Typography>

                    <Typography variant="caption1">
                        Lưu ý: Mỗi sản phẩm có tối đa 30 bước
                    </Typography>
                </div>
                <div className="div_slqt">
                    <Typography variant="caption1">
                        Xem và sửa thông tin về quy trình của bạn:
                    </Typography>
                </div>
                <div className="div_tenqt">
                    <Typography variant="caption1">
                        Tên quy trình <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                        {...register("tenqtLon")}
                        error={Boolean(errors.tenqtLon)}
                        style={{ marginTop: 5 }}
                        placeholder='Ví dụ: Quy trình VietGaps'
                        defaultValue={infoProduct.tenqt}
                    ></TextField>
                    <Typography variant="caption1" style={{ color: 'red' }}>{errors.tenqtLon?.message}</Typography>
                </div>
                <div className="div_mota">
                    <Typography variant="caption1">Mô tả về quy trình</Typography>
                    <div className="editor">
                        <Editor
                            editorState={editorState}
                            fullWidth
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={setEditor}
                        />
                    </div>
                </div>
                <div className="div_slqt">
                    <Typography variant="caption1">
                        Nhập số lượng các bước trong quy trình:
                    </Typography>
                    {lengthdf != null &&
                        <Input
                            type="number"
                            onChange={quyTrinhChange}
                            defaultValue={lengthdf}
                            inputProps={{ min: 0, max: 30 }}
                        ></Input>}
                </div>

                <div>
                    {cacbuoc != null && cacbuoc.map((res) => {
                        return (
                            <div
                                style={{ display: "flex", flexDirection: "column" }}
                                key={res.key}
                            >
                                <Typography variant="caption1">
                                    Quy trình thứ {res.key}:
                                </Typography>
                                <TextField
                                    label="Tên bước"
                                    style={{ marginTop: 6 }}
                                    required
                                    defaultValue={res.TenQuyTrinh}
                                    {...register(`tenqt[${res.key}]`)}
                                ></TextField>
                                <TextField
                                    label="Mô tả về bước"
                                    style={{ marginTop: 6 }}
                                    required
                                    defaultValue={res.GhiChu}
                                    {...register(`mota[${res.key}]`)}
                                ></TextField>
                                <FormControl fullWidth style={{ marginTop: 6 }}>
                                    <InputLabel>Lựa chọn đơn vị kiểm định</InputLabel>
                                    <Select
                                        label="Lựa chọn đơn vị kiểm định"
                                        required
                                        defaultValue={res.DonVi}
                                        {...register(`donvikd[${res.key}]`)}
                                    >
                                        {donvikiemdinh != undefined &&
                                            donvikiemdinh.map((res) => {
                                                if (res.record.loai == "CoQuanKD" && res.record.tieuchuan == infoProduct.chuan) {
                                                    return (
                                                        <MenuItem value={res.record.ID}>
                                                            {res.record.TenCQ}
                                                        </MenuItem>
                                                    );
                                                }
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                        );
                    })}
                </div>
                <div className="div_addSP">
                    <Button type="submit" >Sửa sản phẩm</Button>
                </div>
            </form >
        </div >
    );
}

export default EditProductStep1;