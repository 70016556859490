import { yupResolver } from "@hookform/resolvers/yup";
import SendIcon from "@mui/icons-material/Send";
import { Button, LinearProgress, TextField, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import OTPInput from "otp-input-react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { ReactTitle } from "react-meta-tags";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "yup-phone";
import callApi from "../Api/userApi";
import { firebase } from "../Firebase/firebase";
import UseStore from "../Zustand/zustand";
import "./sendCode.scss";
import "./style.css";
LoginByPhoneNumber.propTypes = {};
var username;
// get functions to build form with useForm() hook

function LoginByPhoneNumber(props) {
  let navigate = useNavigate();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const updateData = UseStore((state) => state.updateData);
  const [resultx, setResult] = useState();
  const validationSchema = Yup.object().shape({
    phone: Yup.string()
      .phone("VN", false, "SĐT không đúng định dạng")
      .required("Số điện thoại không được để trống"),
  });
  const [getCode, setCode] = useState();
  const [getData, setData] = useState();
  const [getOK, setOK] = useState(false);
  const [valueOTP, setvalueOTP] = useState();
  const [getSt, setSt] = useState(false);
  const handleChange = (change) => {
    setvalueOTP(change);
  };
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    handleSubmit,
    formState,
    register,
    control,
    reset,
    formState: { isSubmitting, errors },
  } = useForm(formOptions);
  const [verify, setVerify] = useState();
  const [phoneNumber, setPhoneNumber] = useState();
  const Submit = async (data) => {
    setPhoneNumber(data.phone);
    if (!window.recaptchaVerifier) {
      window.recaptchaVerifier = await new firebase.auth.RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "normal",
          callback: async function (recapchaToken) {
            // reCAPTCHA solved, send recapchaToken and phone number to backend.
            // a REST call to your backend
            await callApi
              .sendCode(data.phone, recapchaToken)
              .then((e) => {

                enqueueSnackbar(e.success, { variant: "success" });
                window.recaptchaVerifier.reset();
                setOK(true);
              })
              .catch((e) => {

                enqueueSnackbar(e.mess, { variant: "error" });
                window.recaptchaVerifier.reset();
              });
          },
        }
      );
    }

    // render the rapchaVerifier.
    window.recaptchaVerifier.render().then(function (widgetId) {
      window.recaptchaWidgetId = widgetId;
    });
  };
  const checkCode = async () => {
    await callApi.verify(phoneNumber, valueOTP).then(async (res) => {

      if (!res.ID) {
        enqueueSnackbar(res.mess, { variant: "error" });
        return;
      }

      localStorage.setItem("accessToken", res.accessToken);
      localStorage.setItem("ID", res.ID);
      localStorage.setItem("phongban", res.phongban);
      localStorage.setItem("name", res.name);
      localStorage.setItem("username", res.username);
      localStorage.setItem("email", res.email);
      localStorage.setItem("avatar", res.avatar);
      localStorage.setItem("phone", res.phone);
      updateData(
        res.accessToken,
        res.ID,
        res.phongban,
        res.name,
        res.username,
        res.email,
        res.avatar,
        res.phone
      );
      if (res.vaitro == "Admin") {
        enqueueSnackbar(`Chào mừng bạn ${res.name} đã quay trở lại`, {
          variant: "success",
        });
        return navigate("/admin/dashboard", { replace: true });
      }
      else if (res.vaitro == 'NhaSX') {
        enqueueSnackbar(`Chào mừng bạn ${res.name} đã quay trở lại`, {
          variant: "success",
        });
        return navigate("/nhasx/dashboard", { replace: true });
      }
      else if (res.vaitro == 'CQKD' && res.publickey == null) {
        enqueueSnackbar(`Bạn chưa có khoá, vui lòng tạo khoá`, {
          variant: "error",
        });
        enqueueSnackbar(`Chào mừng bạn ${res.name} đã quay trở lại`, {
          variant: "success",
        });
        return navigate("/advkd/key", { replace: true });
      }
      else if (res.vaitro == "ANhaSX") {
        enqueueSnackbar(`Chào mừng bạn ${res.name} đã quay trở lại`, {
          variant: "success",
        });
        return navigate("/ANhaSX/dashboard", { replace: true });
      }
      else if (res.vaitro == "NCQKD" && res.publickey == null) {
        enqueueSnackbar(`Chào mừng bạn ${res.name} đã quay trở lại`, {
          variant: "success",
        });
        enqueueSnackbar(`Bạn chưa có khoá, vui lòng tạo khoá`, {
          variant: "info",
        });
        return navigate("/ndvkd/key", { replace: true });
      }
      else if (res.vaitro == "NCQKD" && res.publickey != null) {
        enqueueSnackbar(`Chào mừng bạn ${res.name} đã quay trở lại`, {
          variant: "success",
        });

        return navigate("/ndvkd/dashboard", { replace: true });
      }
      else if (res.vaitro == "CQKD" && res.publickey != null) {
        enqueueSnackbar(`Chào mừng bạn ${res.name} đã quay trở lại`, {
          variant: "success",
        });
        return navigate("/advkd/dashboard", { replace: true });
      }
      //enqueueSnackbar("Thành công, xin vui lòng đợi", { variant: "success" });
      if (res.phongban == null)
        window.location.href = "/admin/accountNotAvalible";
    });
  };
  async function resend() {
    enqueueSnackbar(
      "Đang bảo trì tính năng gửi lại. Vui lòng bấm F5 để thao tác lại",
      { variant: "error" }
    );
  }
  const renderTime = (remainingTime) => {
    return (
      <Typography>
        Nếu bạn chưa nhận được mã vui lòng thử lại sau {remainingTime} giây
      </Typography>
    );
  };
  const renderButton = (buttonProps) => {
    return (
      <Button {...buttonProps} variant="contained" style={{ marginLeft: 10 }}>
        Gửi lại
      </Button>
    );
  };
  const [mat, setMat] = useState(false);
  return (
    <div>
      <ReactTitle title="Đăng nhập bằng số điện thoại- TRoot" />
      {isSubmitting == true && <LinearProgress />}
      <form onSubmit={handleSubmit(Submit)}>
        <img
          src={require("../image/verifyPhone.gif")}
          className="imgChiaKhoa"
        ></img>
        {getOK == false ? (
          <>
            <Typography
              style={{
                fontWeight: "bold",
                fontSize: 25,
                color: "#3d4068",
                marginTop: 30,
              }}
            >
              Đăng nhập bằng số điện thoại
            </Typography>
            <Typography textAlign="center" marginTop={3}>
              Vui lòng nhập số điện thoại của bạn để bắt đầu
            </Typography>
            <TextField
              id="phone"
              label="Số điện thoại"
              name="phone"
              className="input_phone"
              defaultValue="+84"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                justifyContent: "center",
                marginTop: 3,
              }}
              error={Boolean(errors.phone)}
              {...register("phone", { required: true })}
            />
            <Typography color="red" style={{ lineHeight: 2 }}>
              {errors.phone?.message}
            </Typography>
            <div id="recaptcha-container" class="recaptcha-container1"></div>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              type="submit"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginTop: 3,
              }}
            >
              Gửi
            </Button>
          </>
        ) : (
          <>
            <Typography style={{ lineHeight: 3 }}>
              Chúng tôi đã gửi mã xác nhận 6 số vào số điện thoại của bạn
            </Typography>
            <Typography>Nhập mã OTP để tiếp tục</Typography>
            <Box
              sx={{
                width: "20em",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginTop: 3,
              }}
            >
              <OTPInput
                value={valueOTP}
                onChange={handleChange}
                OTPLength={6}
                otpType="number"
                className="inputOTP"
                disabled={false}
                secure
                renderTime
              />
            </Box>
            <Box
              sx={{
                width: "32em",
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginTop: 3,
              }}
            ></Box>
            <Button
              variant="contained"
              endIcon={<SendIcon />}
              type="button"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginTop: 3,
              }}
              onClick={checkCode}
            >
              Đăng nhập
            </Button>
          </>
        )}
      </form>
      <Typography style={{ lineHeight: 9 }}>
        Copyright © 2023 T Services
      </Typography>
    </div>
  );
}

export default LoginByPhoneNumber;
