// component
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "Trang chủ",
    path: "/nhasx/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "Quản lý sản phẩm",
    path: "/nhasx/product",
    icon: getIcon("bi:bag"),
  },
  {
    title: "Quản lý khoá",
    path: "/nhasx/key",
    icon: getIcon("material-symbols:key"),
  },
  {
    title: "Trang cá nhân",
    path: "/nhasx/profile",
    icon: getIcon("icon-park-twotone:permissions"),
  },
];

export default navConfig;
