import { yupResolver } from "@hookform/resolvers/yup";
import {
    Email, Phone, VisibilityOff
} from "@mui/icons-material";
import CheckIcon from "@mui/icons-material/Check";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
    Button,
    CssBaseline,
    Grid,
    IconButton,
    InputAdornment,
    LinearProgress,
    TextField
} from "@mui/material";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { JSEncrypt } from "jsencrypt";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import callApi from "../Api/userApi";
import "./style.scss";
import './resetPass.css';
var encryptx = new JSEncrypt();

var publicKey = `
    -----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuKLJc1in6qDU7VlYhCIc
exir2OkORN+/sEAgPWhopmoQt/k8sB5di3hQOeL/98F2DQHGPoSnT4DQMQXrseFL
npUFJnzPN2/0yxffgF2phTA9yKg5jGgIkEoWupuUbMEttqBITb9X23PpQQW7R5BJ
Kw6Ex2U4g825nQIEBEJxy76vBN3B4ZLrasIhRt1XR9tFVcqqckxzZ55g9REC//NU
HT2ofXL6oHuA2x2xCFh5Yljg9lAd+Zo0hQFW4BeChe/gU9ErD4dvvoryo4Xwkaq0
hYHWh7kN34vVJlfreuKaeiN/zRs2dySqtsRxHJNTExlWRcWfWpljYF//DDQiYP9w
9wIDAQAB
-----END PUBLIC KEY-----`;
encryptx.setPublicKey(publicKey);
function encrypt(text) {
  return encryptx.encrypt(text);
}
const bull = (
  <Box
    component="span"
    sx={{ display: "inline-block", mx: "2px", transform: "scale(0.8)" }}
  >
    •
  </Box>
);

export default function BasicCard() {
    let navigate = useNavigate();
  let location = useLocation();
  const { enqueueSnackbar } = useSnackbar();
  const [getType, setType] = useState(false);
  const [retype, setRetype] = useState(false);
  const submit = async (data) => {
    if(!location.state.id || !location.state.code) {
        enqueueSnackbar("Lỗi không hợp lệ", {variant: 'error'});
        return navigate("/", {
          replace: true,
        });
    }
    await callApi
      .resetPass(location.state.id, location.state.code, encrypt(data.password))
      .then((res) => {
        if (!res) {
            enqueueSnackbar("Lỗi Server không xác định, vui lòng thử lại", {variant: 'error'})
        }
        else if(!res.error) {
            enqueueSnackbar("Thay đổi mật khẩu thành công, vui lòng quay về trang đăng nhập", {variant: 'success'});
            return navigate("/admin/login", {replace: true});
        }
        else {
            enqueueSnackbar("Mã CODE không hợp lệ",{variant: 'error'});
        }
      });
  };
  const showPass = () => {
    setType(!getType);
  };
  const showReType = () => {
    setRetype(!retype);
  };
  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .min(6, "Password cần phải đủ tối thiểu 6 ký tự")
      .required("Password không thể bỏ trống"),
    reenterpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Nhập lại mật khẩu không chính xác")
      .required("Confim Password không thể bỏ trống"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    handleSubmit,
    formState,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm(formOptions);
  return (
    <div className="backgroud_div">
      <Card
        sx={{
          maxWidth: "1200vw",
          maxHeight: "80vh",
          marginLeft: "auto",
          marginRight: "auto",
          display: "flex",
          marginTop: "10vh",
          padding: 0,
          className: 'infoLH'
        }}
      >
        <React.Fragment style={{ padding: 0, position: "relative" }} >
          <Grid
            container
            component="main"
            sx={{ height: "80vh", width: "60vh", backgroundColor: "#fafafa" }}
            className="g1"
          >
            <CssBaseline />
            <Grid />
            <Box>
              <Typography
                style={{
                  fontSize: 20,
                  fontWeight: "bold",
                  color: "#070919",
                }}
              >
                Bạn cần trợ giúp ?
              </Typography>
              <br></br>
              <br></br>
              <span
                style={{
                  marginLeft: "4vw",
                  fontWeight: "bold",
                  color: "#3b3e66",
                }}
              >
                Số điện thoại
              </span>
              <Box
                style={{
                  textAlign: "left",
                  display: "flex",
                  marginLeft: "2vw",
                }}
              >
                <Phone style={{ marginTop: "-1vw" }} />
                <span>+84 374 816 654</span>
              </Box>
              <Box style={{ marginTop: "5vh" }}>
                <span
                  style={{
                    marginLeft: "4vw",

                    fontWeight: "bold",
                    color: "#3b3e66",
                  }}
                >
                  Email
                </span>
                <Box
                  style={{
                    textAlign: "left",
                    display: "flex",
                    marginLeft: "2vw",
                  }}
                >
                  <Email style={{ marginTop: "-1vw" }} />
                  <span style={{ marginLeft: 3 }}>
                    {" "}
                    truyxuatnguongoc@sytuan.net
                  </span>
                </Box>
                <img
                  src={require("../image/image_udvubt.png")}
                  style={{ width: "100%", bottom: 0, marginTop: "20vh" }}
                />
              </Box>
            </Box>
          </Grid>
        </React.Fragment>

        <form onSubmit={handleSubmit(submit)}>
          {isSubmitting == true && <LinearProgress />}

          <Box style={{ width: "70vh" }} >
            <img
              src={require("../image/logo.png")}
              className="loginImage"
            ></img>
            <Typography style={{ fontWeight: "bold" }}>
              Đặt lại mật khẩu
            </Typography>
            <Typography>Vui lòng nhập mật khẩu mới của bạn</Typography>
            {errors.password?.message == undefined ? (
              <TextField
                margin="normal"
                name="password"
                label="Mật khẩu"
                type={getType === false ? "password" : "text"}
                id="password"
                autoComplete="current-password"
                style={{ width: "80%", marginLeft: "9vh" }}
                {...register("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={showPass}>
                        {getType ? <VisibilityOff /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <TextField
                margin="normal"
                name="password"
                error
                label="Mật khẩu"
                type={getType === false ? "password" : "text"}
                id="password"
                autoComplete="current-password"
                style={{ width: "80%", marginLeft: "9vh" }}
                {...register("password")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={showPass}>
                        {getType ? <VisibilityOff /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Typography color={"red"}>{errors.password?.message}</Typography>
            {errors.reenterpassword?.message == undefined ? (
              <TextField
                margin="normal"
                name="reenterpassword"
                label="Nhập lại mật khẩu"
                type={retype === false ? "password" : "text"}
                id="password"
                autoComplete="current-password"
                style={{ width: "80%", marginLeft: "9vh" }}
                {...register("reenterpassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={showReType}>
                        {retype ? <VisibilityOff /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            ) : (
              <TextField
                margin="normal"
                name="reenterpassword"
                label="Nhập lại mật khẩu"
                error
                type={retype === false ? "password" : "text"}
                id="password"
                autoComplete="current-password"
                style={{ width: "80%", marginLeft: "9vh" }}
                {...register("reenterpassword")}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={showReType}>
                        {retype ? <VisibilityOff /> : <VisibilityIcon />}
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            )}
            <Typography color={"red"}>
              {errors.reenterpassword?.message}
            </Typography>
            <Button
              variant="contained"
              endIcon={<CheckIcon />}
              type="submit"
              sx={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "flex",
                marginTop: 3,
              }}
            >
              Xác nhận
            </Button>
          </Box>
        </form>
      </Card>
    </div>
  );
}
