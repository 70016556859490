import React from "react";
import { Route, Routes } from "react-router-dom";
import DashboardDVKD from "./AdminDonViKiemDinh";
import GiaoViecKD from "./AdminDonViKiemDinh/NguoiDung/GiaoViec";
import NguoiDungKD from "./AdminDonViKiemDinh/NguoiDung/NguoiDung";
import QuanLyKey from "./AdminDonViKiemDinh/QuanLyKhoa";
import KhoaTaoXong from "./AdminDonViKiemDinh/QuanLyKhoa/KhoaOK";
import QLQuyTrinh from "./AdminDonViKiemDinh/QuyTrinh";
import DVKDProfiles from "./AdminDonViKiemDinh/TrangCaNhan/TrangCaNhan_NhaSX";

import DashboardLayout from "./DashboardAdmin";
import ControlNguoiDung from "./DashboardAdmin/NguoiDung";
import KhoaCongKhai from "./DashboardAdmin/QuanLyKhoaCongKhai";
import ToChuc from "./DashboardAdmin/ToChuc/index";
import AdminProFile from "./DashboardAdmin/TrangCaNhan_Admin";
import DashboardAdminNhaSX from "./DashboardAdminNhaSX";
import AdminAddProduct from "./DashboardAdminNhaSX/AddProduct";
import AdminNguoiDungSX from "./DashboardAdminNhaSX/NguoiDung/NguoiDung";
import AdminProductControl from "./DashboardAdminNhaSX/Product";
import AdminQuanLyKey from "./DashboardAdminNhaSX/QuanLyKhoa";
import AdminSXKhoaTaoXong from "./DashboardAdminNhaSX/QuanLyKhoa/KhoaOK";
import AdminTrangCaNhanNSX from "./DashboardAdminNhaSX/TrangCaNhan_NhaSX";
import DashboardNhaSX from "./DashboardNhaSX";
import AddProduct from "./DashboardNhaSX/AddProduct";
import EditSanPham from "./DashboardNhaSX/EditSanPham";
import ProductControl from "./DashboardNhaSX/Product";
import QuanLyKeyNhaSX from "./DashboardNhaSX/QuanLyKhoa";
import KhoaTaoXongNSX from "./DashboardNhaSX/QuanLyKhoa/KhoaOK";
import TrangCaNhanNSX from "./DashboardNhaSX/TrangCaNhan_NhaSX";
import ResetPass from "./ForgotPass/resetPass";
import SendCode from "./ForgotPass/sendCode";
import LoginAdmin from "./Login";
import LoginByPhoneNumber from "./LoginByPhoneNumber";
import DashboardNDVKD from "./NhanVienDonViKiemDinh";
import QuanLyKeyNDVKD from "./NhanVienDonViKiemDinh/QuanLyKhoa";
import KhoaTaoXongN from "./NhanVienDonViKiemDinh/QuanLyKhoa/KhoaOK";
import NVQLQT from "./NhanVienDonViKiemDinh/QuyTrinh";
import NVKDPr from "./NhanVienDonViKiemDinh/TrangCaNhan/TrangCaNhan_NhaSX";
import XacThuCQT from "./NhanVienDonViKiemDinh/XacThuc";
import KyQT from "./NhanVienDonViKiemDinh/KyQT/KyQT";
import UserNotPermission from "./NotFound";
import Welcome from "./Welcome";
import KyQTTheme from "./NhanVienDonViKiemDinh/KyQT";
import NVQLQNV from "./AdminDonViKiemDinh/QT_NV";
import XacThuCQTNV from "./AdminDonViKiemDinh/XacThuc";
import KyQTNV from "./AdminDonViKiemDinh/KyQT";

RouteDir.propTypes = {};

function RouteDir(props) {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Welcome />} />
        <Route path="/admin/login" element={<LoginAdmin />}></Route>
        <Route
          path="/admin/accountNotAvalible"
          element={<UserNotPermission />}
        />

        <Route path="/admin/forgotPass" element={<SendCode />} />
        <Route path="/admin/resetPass" element={<ResetPass />} />
        <Route path="/admin/phonelogin" element={<LoginByPhoneNumber />} />
        <Route path="/admin/dashboard" element={<DashboardLayout />} />
        <Route path="/admin/controluser" element={<ControlNguoiDung />} />
        <Route path="/admin/organization" element={<ToChuc />} />
        <Route path="/admin/key" element={<KhoaCongKhai />} />
        <Route path="/admin/profile" element={<AdminProFile />} />
        <Route path="/nhasx/dashboard" element={<DashboardNhaSX />} />
        <Route path='/nhasx/product' element={<ProductControl />} />
        <Route path='/nhasx/addProduct' element={<AddProduct />} />
        <Route path="/nhasx/profile" element={<TrangCaNhanNSX />} />
        <Route path='/nhasx/suasanpham/:ID' element={<EditSanPham />} />
        <Route path='/advkd/dashboard' element={<DashboardDVKD />} />
        <Route path="/advkd/profile" element={<DVKDProfiles />} />
        <Route path="/advkd/key" element={<QuanLyKey />} />
        <Route path="/nhasx/key" element={<QuanLyKeyNhaSX />} />
        <Route path='/advkd/createPK' element={<KhoaTaoXong />} />
        <Route path='/nhasx/createPK' element={<KhoaTaoXongNSX />} />
        <Route path='/advkd/users' element={<NguoiDungKD />} />
        <Route path='/advkd/quytrinh' element={<QLQuyTrinh />} />
        <Route path='/anhasx/dashboard' element={<DashboardAdminNhaSX />} />
        <Route path='/anhasx/product' element={<AdminProductControl />} />
        <Route path='/anhasx/addProduct' element={<AdminAddProduct />} />
        <Route path="/anhasx/key" element={<AdminQuanLyKey />} />
        <Route path='/anhasx/createPK' element={<AdminSXKhoaTaoXong/>}/>
        <Route path="/anhasx/profile" element={<AdminTrangCaNhanNSX />} />
        <Route path='/anhasx/users' element={<AdminNguoiDungSX/>}/>
        <Route path='/advkd/giaoviec/:id' element={<GiaoViecKD/>}/>
        <Route path='/ndvkd/dashboard' element={<DashboardNDVKD/>}/>
        <Route path='/ndvkd/key' element={<QuanLyKeyNDVKD />} />
        <Route path='/ndvkd/createPK' element={<KhoaTaoXongN />} />
        <Route path="/ndvkd/profile" element={<NVKDPr />} />
        <Route path='/ndvkd/quytrinh' element={<NVQLQT />} />
        <Route path='/ndvkd/xacthuc/:id' element={<XacThuCQT/>}/>
        <Route path='/ndvkd/ky/:id' element={<KyQTTheme />} />
        <Route path='/advkd/xnquytrinh' element={<NVQLQNV />} />
        <Route path='/advkd/xacthuc/:id' element={<XacThuCQTNV />} />
        <Route path='/advkd/ky/:id' element={<KyQTNV />} />
        
      </Routes>
    </div>
  );
}

export default RouteDir;
