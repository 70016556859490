import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import UseStore from "../../Zustand/zustand";
import callApi from "../../Api/userApi";
import { useSnackbar } from "notistack";

XoaNguoiDung.propTypes = {};

function XoaNguoiDung({ data, handleClose, open }) {
  const hotReload = UseStore((state) => state.setHotReload);
  const token = UseStore((state) => state.accessToken);
  const ID = UseStore((state) => state.ID);
  const { enqueueSnackbar,closeSnackbar } = useSnackbar();
  const [submit,setSubmit] = useState(false);
  const deleteUser = async() => {
    setSubmit(true);
    if(ID == data.ID) {
      enqueueSnackbar("Bạn không thể xoá chính mình", { variant: "warning" });
      handleClose();
      setSubmit(false);
      return ;
    }
    enqueueSnackbar("Yêu cầu đang được thực hiện, vui lòng chờ", {
      variant: "info",
    });
    await callApi.removeUser(token,data.ID).then(() => {
      closeSnackbar();
        enqueueSnackbar("Xoá người dùng thành công",{variant: 'success'})
        hotReload(data.ID);
        handleClose();
    }).catch((e) => enqueueSnackbar(`Lỗi: ${e}`,{variant: 'error'}))
    setSubmit(true);
  };
  return (
    <div>
      {submit ? (
        <Dialog open={true} style={{background: 'none'}} className="DiaLog_Loading">
          <DialogContent>
            <img src={require("../../image/iconLoading.gif")} className='icon_loading' />
          </DialogContent>
        </Dialog>
      ) : (
    <div>
      <Dialog open={open} aria-labelledby="draggable-dialog-title" onClose={handleClose}>
        <DialogTitle style={{ cursor: "move" }}>Xoá người dùng</DialogTitle>
        <DialogContent>
          Bạn có chắc chắn muốn xoá người dùng {data.name} không. Xin lưu ý thao tác
          này không thể hoàn tác
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Không</Button>
          <Button onClick={deleteUser}>Có</Button>
        </DialogActions>
      </Dialog>
    </div>)}
    </div>
  );
}

export default XoaNguoiDung;
