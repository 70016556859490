import { SatelliteAlt } from "@mui/icons-material";
import { Paper, Step, StepButton, Stepper } from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import SanPhamAPI from "../../Api/SanPhamAPI";
import SanPhamStore from "../../Zustand/SanPham";
import ProductStep0 from "./ProductStep0";
import ProductStep1 from "./ProductStep1";
import ProductStep11 from "./ProductStep11";
import ProductStep2 from "./ProductStep2";
ProductAdd.propTypes = {
  infoUser: PropTypes.object,
};

function ProductAdd({ infoUser }) {
  const steps = ["Tạo sản phẩm", "Thêm các quy trình và bước", "Hoàn tất"];
  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const totalSteps = () => {
    return steps.length;
  };
  const [step1, setStep1] = useState();
  const [step2, setStep2] = useState();

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
        // find the first step that has been completed
        steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };
  const navigate = useNavigate();
  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };
  const motaqt = SanPhamStore((state) => state.motaQT);
  const tenqt = SanPhamStore((state) => state.tenqt);
  const chuan = SanPhamStore((state) => state.loai);
  const submitData = () => {

    step1.motaqt = motaqt;
    step1.tenqt = tenqt;
    step1.chuan = chuan;
    const data = {
      step0: step1,
      step1: step2,
    }
    console.log(data);
    SanPhamAPI.sanpham(data).then((res) => {
      enqueueSnackbar(res.mess, { variant: 'success' });
      navigate("/anhasx/product", { replace: true });
    }).catch((res) => {
      enqueueSnackbar("Có lỗi xảy ra, vui lòng thử lại", { variant: 'error' });
      navigate("/anhasx/product", { replace: true });
    })
  }
  return (
    <div>
      <Paper>
        <Stepper nonLinear activeStep={activeStep}>
          {steps.map((label, index) => (
            <Step key={label} completed={completed[index]}>
              <StepButton color="inherit">
                {label}
              </StepButton>
            </Step>
          ))}
        </Stepper>
        {activeStep == 0 && <ProductStep0 infoUser={infoUser} next={handleNext} setStep={setStep1} />}
        {activeStep == 1 && <ProductStep11 infoUser={infoUser} next={handleNext} setStep={setStep2} />}
        {activeStep == 2 && <ProductStep2 infoUser={infoUser} submit={submitData}  />}
      </Paper>
    </div>
  );
}

export default ProductAdd;
