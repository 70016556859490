import React from 'react';
import PropTypes from 'prop-types';
import { CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import './cssStep2.css';
EditProductStep2.propTypes = {

};

function EditProductStep2({ submitData }) {
    const navigate = useNavigate();
    submitData();
    return (
        <div>
            <div className="div_step2">
                <CircularProgress />
            </div>
            <Typography>
                Hệ thống đang xử lý dữ liệu, vui lòng chờ đợi trong giây lát
            </Typography>
        </div>
    );
}

export default EditProductStep2;