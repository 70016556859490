import {
  Button,
  Dialog, DialogContent,
  DialogTitle,
  Typography
} from "@mui/material";
import React from "react";
import QRCode from "qrcode.react";
function ShowQR({ data, onClose }) {
  console.log(data);
  let idsp = `https://truyxuat.sytuan.net/getSanpham?id=${data.ID}`
  const QRDownload = () => {
    const canvas = document.getElementById("qr-gen");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${data.TenSP}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }
  return (
    <>
      <Dialog
        open={Boolean(data)}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>
          <Typography style={{ display: 'flex', justifyContent: 'center', color: 'red' }}>Mã QR của sản phẩm {data.TenSP} là:</Typography>
        </DialogTitle>
        <DialogContent>
          <div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'column' }}>
            <QRCode
              id="qr-gen"
              value={idsp}
              size={290}
              level={"H"}
              includeMargin={true}
            />
            <Button variant="contained" onClick={QRDownload}>Tải mã QR</Button>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ShowQR;
