import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React from "react";
import DialogXemKhoa from "./DialogXemKhoa";
function TableKhoa({ data, size }) {
  let dem = size;
  const [dialog, openDialog] = React.useState(false);
  const handleClose = () => {
    openDialog(false);
  };
  return (
    <>
      {data.results.map((res) => {
        if(res.Value.vaitro == "CQKD" || res.Value.vaitro == "NhaSX" || res.Value.vaitro == "ANhaSX") {
        
        dem++;
        
        return (
          <TableRow hover role="checkbox" key={res.Value.ID}>
            <TableCell>{dem-1}</TableCell>
            <TableCell>{res.Value.username}</TableCell>
            <TableCell>{res.Value.name}</TableCell>
            {res.Value.publickey ? (
              <TableCell>Đã cấp khoá</TableCell>
            ) : (
              <TableCell>Chưa cấp khoá</TableCell>
            )}
            <TableCell>
              <PopupState variant="popover" popupId="showMore">
                {(popupState) => (
                  <React.Fragment>
                    <IconButton {...bindTrigger(popupState)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          openDialog(res);
                        }}
                      >
                        <Typography onClick={popupState.close}>
                          Xem khoá công khai
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
            </TableCell>
            {dialog == res && (
              <DialogXemKhoa data={dialog} onClose={handleClose} />
            )}
          </TableRow>
        );
      }})}
    </>
  );
}

export default TableKhoa;
