// component
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Trang chủ',
    path: '/nhasx/dashboard',
    pathR: '/nhasx/dashboard',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Quản lý sản phẩm',
    path: '/nhasx/product',
    pathR: '/nhasx/product',
    icon: getIcon('bi:bag'),
  },
  {
    title: 'Quản lý tem',
    path: '/nhasx/stamp',
    pathR: '/nhasx/stamp',
    icon: getIcon('akar-icons:image'),
  },
  {
    title: 'Trang cá nhân',
    path: '/nhasx/profile',
    pathR: '/nhasx/profile',
    icon: getIcon('icon-park-twotone:permissions'),
  }
];

export default navConfig;
