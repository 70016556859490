import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import SanPhamAPI from '../../Api/SanPhamAPI';
import { Button, IconButton, Input, Menu, MenuItem, Paper, Skeleton, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { Search } from '@mui/icons-material';
import UseStore from '../../Zustand/zustand';
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useNavigate } from 'react-router-dom';
QLQuyTrinhDashboard.propTypes = {
    infoUser: PropTypes.object,
};

function QLQuyTrinhDashboard({ infoUser }) {
    const [data, setData] = useState(null);
    var dem = 0;
    const columns = [
        { id: "STT", label: "STT", minWidth: 10 },
        { id: "tensp", label: "Tên sản phẩm", minWidth: 10 },
        { id: "ttky", label: "Trạng thái ký", minWidth: 10 },

        { id: "TTPC", label: "Trạng thái phân công", minWidth: 10 },
        { id: "Thaotac", label: "Thao tác", minWidth: 10 },
    ];
    const rows = [
        {
            id: 1,
            value: 2,
        },
        {
            id: 2,
            value: 2,
        },
        {
            id: 3,
            value: 2,
        },
        {
            id: 4,
            value: 2,
        },
        {
            id: 5,
            value: 3
        }
    ];
    const setHotReload = UseStore((state) => state.setHotReload);
    const hotReload = UseStore((state) => state.hotReload);
    const userChange = (e) => {
        if (!e.target.value) setHotReload();
        let res = []

        for (let i = 0; i < data.length; i++) {
            if (data[i].TTSP.TenSP.indexOf(e.target.value) != -1) res.push(data[i]);
        }
        setData(res);
    }
    useEffect(() => {
        async function getAPI() {
            await SanPhamAPI.getSPQuyTrinh(infoUser.phongban).then((res) => {
                console.log(res);
                setData(res);
                
            });
        }
        getAPI();
    }, [hotReload])
    const navigate = useNavigate();
    return (
        <div>
            <Paper sx={{ width: "100%", overflow: "hidden" }}>
                <div className="search_box">
                    <Search className="icon_search"></Search>
                    <Input placeholder="Tìm kiếm người dùng" onChange={userChange}></Input>
                </div>
                <TableContainer>
                    <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                            <TableRow>
                                {columns.map((column) => (
                                    <TableCell
                                        key={column.id}
                                        align={column.align}
                                        style={{ minWidth: column.minWidth }}
                                    >
                                        {column.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data == null ? (
                                rows.map((ske) => {
                                    return (
                                        <TableRow key={ske.id}>
                                            {rows.map((skel) => {
                                                return (
                                                    <TableCell key={skel.id}>
                                                        <Skeleton></Skeleton>
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>
                                    );
                                })
                            ) : (
                                data.map((res) => {
                                    dem++;
                                    return (

                                        <TableRow key={res.ID}>
                                            <TableCell>{dem}</TableCell>
                                            <TableCell>{res.TTSP.TenSP}</TableCell>
                                            <TableCell>{res.TTKy == false ? "Chưa ký" : "Đã ký"}</TableCell>
                                            <TableCell>{res.UserGiao == null ? "Chưa phân công" : "Đã phân công"}</TableCell>
                                            <TableCell>
                                                <PopupState variant="popover" popupId="showMore">
                                                    {(popupState) => (
                                                        <React.Fragment>
                                                            <IconButton {...bindTrigger(popupState)}>
                                                                <MoreVertIcon />
                                                            </IconButton>
                                                            <Menu {...bindMenu(popupState)}>
                                                                <MenuItem
                                                                    onClick={() => {
                                                                        navigate('/advkd/giaoviec/'+res.TTSP.ID)
                                                                    }}
                                                                >
                                                                    <Typography onClick={popupState.close}>
                                                                        Phân công công việc
                                                                    </Typography>
                                                                </MenuItem>
                                                            </Menu>
                                                        </React.Fragment>
                                                    )}
                                                </PopupState>
                                            </TableCell>
                                        </TableRow>)
                                })
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

        </div>
    );
}

export default QLQuyTrinhDashboard;