import { yupResolver } from "@hookform/resolvers/yup";
import { Call } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import callApi from "../../Api/userApi";
import UseStore from "../../Zustand/zustand";
ThemMoiToChuc.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

function ThemMoiToChuc({ open, onClose }) {
  const actoken = UseStore((state) => state.accessToken);
  const [loaicq,setLoaiCQ] = useState("CoQuanSX")
  const changeLoaiCQ = (e) => {
    setLoaiCQ(e.target.value)
    console.log(e);
  }
  const validationSchema = Yup.object().shape({
    TenCQ: Yup.string().required("Không thể bỏ trống Tên cơ quan"),
    DiaChi: Yup.string().required("Không thể bỏ trống Địa chỉ"),
    phoneNumber: Yup.string()
      .phone("VN", false, "SĐT không đúng định dạng")
      .required("Số điện thoại không được để trống"),
  });
  const hotReload = UseStore((state) => state.setHotReload);
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    handleSubmit,
    formState,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm(formOptions);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [submit, setSubmit] = useState(false);
  const submitForm = async (e) => {
    
    setSubmit(true);
    enqueueSnackbar("Đang xử lý, xin vui lòng chờ", { variant: "info" });
    await callApi
      .ThemToChuc(actoken, e.TenCQ, e.DiaChi, e.phoneNumber,loaicq,e.TieuChuanKD)
      .then((res) => {
        closeSnackbar();
        enqueueSnackbar(`${res.mess}`, { variant: "success" });
        onClose();
        hotReload(e);
        
      })
      .catch((e) => {
        closeSnackbar();
        enqueueSnackbar("Có lỗi", { variant: "error" });
        onClose();
      });
    reset();
    setLoaiCQ("CoQuanSX")
    setSubmit(false);
  };
  return (
    <div>
      {submit ? (
        <Dialog
          open={true}
          style={{ background: "none" }}
          className="DiaLog_Loading"
        >
          <DialogContent>
            <img
              src={require("../../image/iconLoading.gif")}
              className="icon_loading"
            />
          </DialogContent>
        </Dialog>
      ) : (
        <Dialog open={open} onClose={onClose}>
          <AppBar position="static">
            <div style={{ display: "flex" }}>
              <IconButton style={{ color: "white" }} onClick={onClose}>
                <CloseIcon />
              </IconButton>
              <Typography
                variant="h6"
                color="inherit"
                style={{ alignSelf: "center" }}
              >
                Thêm mới tổ chức
              </Typography>
            </div>
          </AppBar>
          <form onSubmit={handleSubmit(submitForm)}>
            <DialogContent>
              <TextField
                label="Tên cơ quan"
                fullWidth
                name="TenCQ"
                {...register("TenCQ")}
                error={Boolean(errors.TenCQ)}
              ></TextField>
              <Typography style={{ color: "red" }} variant="body">
                {errors.TenCQ?.message}
              </Typography>
              <TextField
                label="Địa chỉ"
                fullWidth
                name="DiaChi"
                {...register("DiaChi")}
                style={{ marginTop: 20 }}
                error={Boolean(errors.DiaChi)}
              ></TextField>
              <Typography style={{ color: "red" }} variant="body">
                {errors.DiaChi?.message}
              </Typography>
              <TextField
                label="Số điện thoại"
                fullWidth
                name="SoDienThoai"
                {...register("phoneNumber")}
                style={{ marginTop: 20 }}
                defaultValue={"+84"}
                error={Boolean(errors.phoneNumber)}
              ></TextField>
              <Typography style={{ color: "red" }} variant="body">
                {errors.phoneNumber?.message}
              </Typography>

              <FormControl fullWidth style={{marginTop: 20}}>
                <InputLabel>Loại tổ chức</InputLabel>
                <Select label="Loại tổ chức" defaultValue={"CoQuanSX"}
                onChange={changeLoaiCQ}>
                  <MenuItem value={"CoQuanSX"}>Cơ quan sản xuất</MenuItem>
                  <MenuItem value={"CoQuanKD"}>Cơ quan kiểm định</MenuItem>
                </Select>
              </FormControl>
              {loaicq === "CoQuanKD" && (
                <FormControl fullWidth style={{marginTop: 20}}>
                <InputLabel>Tiêu chuẩn CQKD</InputLabel>
                <Select label="Tiêu chuẩn CQKD" defaultValue={"TatCa"}
                {...register("TieuChuanKD")}>
                  <MenuItem value={"TatCa"}>Cơ quan kiểm định tiêu chuẩn</MenuItem>
                  <MenuItem value={"VietGaps"}>CQKD chuẩn VietGaps</MenuItem>
                </Select>
              </FormControl>
              )}
            </DialogContent>

            <DialogActions>
              <Button type="submit">Thêm cơ quan</Button>
            </DialogActions>
          </form>
        </Dialog>
      )}
    </div>
  );
}

export default ThemMoiToChuc;
