import { yupResolver } from "@hookform/resolvers/yup";
import { AccountCircle, Key } from "@mui/icons-material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
  Avatar,
  Button,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Tab,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import callApi from "../../../Api/userApi";
import UseStore from "../../../Zustand/zustand";
import ChangePasswordProfile from "./changePassword";
import "./HomePage.css";
import { JSEncrypt } from "jsencrypt";
NhaSXProfile.propTypes = {
  data: PropTypes.object,
};

function NhaSXProfile({ data }) {
  var encryptx = new JSEncrypt();

  var publicKey = `
    -----BEGIN PUBLIC KEY-----
  MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuKLJc1in6qDU7VlYhCIc
  exir2OkORN+/sEAgPWhopmoQt/k8sB5di3hQOeL/98F2DQHGPoSnT4DQMQXrseFL
  npUFJnzPN2/0yxffgF2phTA9yKg5jGgIkEoWupuUbMEttqBITb9X23PpQQW7R5BJ
  Kw6Ex2U4g825nQIEBEJxy76vBN3B4ZLrasIhRt1XR9tFVcqqckxzZ55g9REC//NU
  HT2ofXL6oHuA2x2xCFh5Yljg9lAd+Zo0hQFW4BeChe/gU9ErD4dvvoryo4Xwkaq0
  hYHWh7kN34vVJlfreuKaeiN/zRs2dySqtsRxHJNTExlWRcWfWpljYF//DDQiYP9w
  9wIDAQAB
  -----END PUBLIC KEY-----`;
  encryptx.setPublicKey(publicKey);
  function encrypt(text) {
    return encryptx.encrypt(text);
  }
  const acToken = UseStore((state) => state.accessToken);
  const setStateInfo = UseStore((state) => state.setBasicInfo);
  const changeStorage = (name, Email, phoneNumber) => {
    localStorage.setItem("name", name);
    localStorage.setItem("email", Email);
    localStorage.setItem("phone", phoneNumber);
    data.name = name;
    data.email = Email;
    data.phone = phoneNumber;
    setStateInfo(name, Email, phoneNumber);
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Không thể bỏ trống Tên của bạn"),
    Email: Yup.string()
      .email("Không phải là Email hợp lệ")
      .required("Không thể bỏ trống Email"),
    phoneNumber: Yup.string()
      .phone("VN", false, "SĐT không đúng định dạng")
      .required("Số điện thoại không được để trống"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    handleSubmit,
    formState,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm(formOptions);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [Tabs, setTabs] = useState("1");
  const handleChange = (e, newValue) => {
    setTabs(newValue);
  };
  const avatar = UseStore((state) => state.setAvatar);
  const imgavatar = UseStore((state) => state.avatar);
  const formData = new FormData();
  let aavatar;
  if (imgavatar == "null")
    aavatar = `https://avatar.oxro.io/avatar.svg?name=${data.name}&background=f39c12&length=2`;
  else aavatar = imgavatar;
  const changeAvatar = (e) => {
    enqueueSnackbar("Đang xử lý, xin vui lòng chờ", { variant: "info" });
    e.preventDefault();
    formData.append("image", e.target.files[0]);
    fetch("https://image.banlait.com:3000/uploadImage", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        closeSnackbar();
        enqueueSnackbar("Thành công!", { variant: "success" });
        localStorage.setItem(
          "avatar",
          `https://image.banlait.com:3000/${result.path}`
        );
        data.avatar = `https://image.banlait.com:3000/${result.path}`;
        avatar(`https://image.banlait.com:3000/${result.path}`);
      })
      .catch(() => {
        console.log(e);
      });
  };
  const [submit, setSubmit] = useState(false);
  const TTND = async (e) => {
    setSubmit(true);
    enqueueSnackbar("Đang cập nhật, vui lòng chờ", { variant: "info" });
    await callApi
      .updateUserNoPass(acToken, data.ID, e.name, e.Email, e.phoneNumber)
      .then((res) => {
        changeStorage(e.name, e.Email, e.phoneNumber);
        closeSnackbar();
        enqueueSnackbar(`${res.mess}`, { variant: "success" });
      })
      .catch((e) => {
        enqueueSnackbar("Có lỗi", { variant: "error" });
        console.log(e);
      });
    setSubmit(false);
  };
  const changePassword = async (e) => {
    setSubmit(true);
    let newPass = encrypt(e.password);
    await callApi
      .changePasswordUser(
        acToken,
        data.ID,
        encrypt(e.oldPassword),
        newPass,
        encrypt(e.reenterpassword)
      )
      .then((res) => {
        if (!res.error) enqueueSnackbar(`${res.mess}`, { variant: "success" });
        else enqueueSnackbar(`${res.error}`,{variant: 'error'})
      })
      .catch((e) => {
        enqueueSnackbar("Có lỗi đã xảy ra", { variant: "error" });
      });
    setSubmit(false);
  };
  return (
    <div>
      {submit && (
        <Dialog
          open={true}
          style={{ background: "none" }}
          className="DiaLog_Loading"
        >
          <DialogContent>
            <img
              src={require("../../../image/iconLoading.gif")}
              className="icon_loading"
            />
          </DialogContent>
        </Dialog>)}
      <Typography variant="h4" gutterBottom component="div">
        Trang cá nhân
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={4} className="g_trai">
          <Paper>
            <div className="div_avatar">
              <Avatar className="avatar_nd" src={aavatar}></Avatar>
            </div>
            <div className="div_text_trai">
              <Typography className="text_name" variant="h4">
                {data.name}
              </Typography>
              <Typography className="text_username" variant="subtitle1">
                {data.username}
              </Typography>
            </div>
            <div className="button_anhdaidien">
              <IconButton aria-label="upload picture" component="label">
                <CameraAltIcon />
                <input
                  hidden
                  accept="image/jpg, image/png"
                  type="file"
                  onChange={changeAvatar}
                />
              </IconButton>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={7} className="g_phai">
          <Paper>
            <Box sx={{ width: "100%", typography: "body1" }}>
              <TabContext value={Tabs}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <TabList onChange={handleChange}>
                    <Tab
                      label={
                        <div style={{ display: "flex" }}>
                          <AccountCircle />
                          <Typography
                            variant="caption"
                            style={{ marginLeft: 2 }}
                          >
                            THÔNG TIN CÁ NHÂN
                          </Typography>
                        </div>
                      }
                      value="1"
                    />
                    <Tab
                      label={
                        <div style={{ display: "flex" }}>
                          <Key />
                          <Typography
                            variant="caption"
                            style={{ marginLeft: 2 }}
                          >
                            Đổi mật khẩu
                          </Typography>
                        </div>
                      }
                      value="2"
                    />
                  </TabList>
                </Box>
                <form onSubmit={handleSubmit(TTND)}>
                  <TabPanel value="1">
                    <div className="div_info">
                      <TextField
                        fullWidth
                        label="Họ và tên"
                        name="name"
                        className="tf_name"
                        error={Boolean(errors.name)}
                        {...register("name")}
                        defaultValue={data.name}
                      ></TextField>
                      <Typography variant="caption" style={{ color: "red" }}>
                        {errors.name?.message}
                      </Typography>
                      <TextField
                        fullWidth
                        label="Email"
                        name="Email"
                        className="tf_email"
                        style={{ marginTop: 12 }}
                        error={Boolean(errors.Email)}
                        {...register("Email")}
                        defaultValue={data.email}
                      ></TextField>
                      <Typography variant="caption" style={{ color: "red" }}>
                        {errors.Email?.message}
                      </Typography>
                      <TextField
                        fullWidth
                        label="Số điện thoại"
                        name="phoneNumber"
                        className="tf_phoneNumber"
                        error={Boolean(errors.phoneNumber)}
                        style={{ marginTop: 12 }}
                        {...register("phoneNumber")}
                        defaultValue={data.phone}
                      ></TextField>
                      <Typography variant="caption" style={{ color: "red" }}>
                        {errors.phoneNumber?.message}
                      </Typography>
                    </div>
                    <div className="div_btn_update">
                      <Button
                        variant="contained"
                        type="submit"
                        style={{ marginTop: 15 }}
                      >
                        Cập nhật
                      </Button>
                    </div>
                  </TabPanel>
                </form>
                <TabPanel value="2">
                  <ChangePasswordProfile onSubmit={changePassword} />
                </TabPanel>
              </TabContext>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}

export default NhaSXProfile;
