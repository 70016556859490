import { VisibilityOff } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Grid, IconButton, InputAdornment } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { JSEncrypt } from "jsencrypt";
import { useSnackbar } from "notistack";
import { useState } from "react";
import Link from "@mui/material/Link";
import callApi from "../Api/userApi";
import UseStore from "../Zustand/zustand";
import "./style.scss";
import "./styles.css";
import { Navigate, useNavigate } from "react-router-dom";
import { ReactTitle } from "react-meta-tags";
const Joi = require("joi");

var encryptx = new JSEncrypt();

var publicKey = `
    -----BEGIN PUBLIC KEY-----
MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAuKLJc1in6qDU7VlYhCIc
exir2OkORN+/sEAgPWhopmoQt/k8sB5di3hQOeL/98F2DQHGPoSnT4DQMQXrseFL
npUFJnzPN2/0yxffgF2phTA9yKg5jGgIkEoWupuUbMEttqBITb9X23PpQQW7R5BJ
Kw6Ex2U4g825nQIEBEJxy76vBN3B4ZLrasIhRt1XR9tFVcqqckxzZ55g9REC//NU
HT2ofXL6oHuA2x2xCFh5Yljg9lAd+Zo0hQFW4BeChe/gU9ErD4dvvoryo4Xwkaq0
hYHWh7kN34vVJlfreuKaeiN/zRs2dySqtsRxHJNTExlWRcWfWpljYF//DDQiYP9w
9wIDAQAB
-----END PUBLIC KEY-----`;
encryptx.setPublicKey(publicKey);
const schema = Joi.object({
  username: Joi.string().alphanum().empty().messages({
    "string.alphanum": `Vui lòng kiểm tra lại Username có ký tự lạ`,
    "string.empty": `Không thể bỏ trống username`,
  }),
  password: Joi.string().empty().messages({
    "string.empty": `Không thể bỏ trống Password`,
  }),
});
function encrypt(text) {
  return encryptx.encrypt(text);
}
function LoginAdmin(props) {
  const navigate = useNavigate();
  const updateData = UseStore((state) => state.updateData);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [userOK, setuserOK] = useState();
  const [userPassOK, setuserPassOK] = useState();
  const [getType, setType] = useState(false);
  function showPass() {
    setType(!getType);
  }
  const handleSubmit = (event) => {
    event.preventDefault();

    const data = new FormData(event.currentTarget);
    const erroremail = schema.validate({
      username: data.get("username"),
    }).error;
    const errorpassword = schema.validate({
      password: data.get("password"),
    }).error;
    if (erroremail !== undefined) setuserOK(erroremail.toString());
    else setuserOK(undefined);
    if (errorpassword !== undefined) setuserPassOK(errorpassword.toString());
    else setuserPassOK(undefined);
    if (erroremail === undefined && errorpassword === undefined) {
      const res = callApi.login(
        data.get("username"),
        encrypt(data.get("password"))
      );
      res.then((resp) => {
        if (!resp.mess) {
          localStorage.setItem("accessToken", resp.accessToken);
          localStorage.setItem("ID", resp.ID);
          localStorage.setItem("phongban", resp.phongban);
          localStorage.setItem("name", resp.name);
          localStorage.setItem("username", data.get("username"));
          localStorage.setItem("email", resp.email);
          localStorage.setItem("avatar", resp.avatar);
          localStorage.setItem("phone", resp.phone);
          updateData(
            resp.accessToken,
            resp.ID,
            resp.phongban,
            resp.name,
            data.get("username"),
            resp.email,
            resp.avatar,
            resp.phone,

          );
          console.log(resp);

          if (resp.phongban == null || resp.vaitro == null)
            window.location.href = "/admin/accountNotAvalible";
        } else {
          setuserPassOK(resp.mess);
        }
        if (resp.vaitro == "Admin") {
          enqueueSnackbar(`Chào mừng bạn ${resp.name} đã quay trở lại`, {
            variant: "success",
          });
          return navigate("/admin/dashboard", { replace: true });
        }
        else if (resp.vaitro == 'NhaSX') {
          enqueueSnackbar(`Chào mừng bạn ${resp.name} đã quay trở lại`, {
            variant: "success",
          });
          return navigate("/nhasx/dashboard", { replace: true });
        }
        else if (resp.vaitro == 'CQKD' && resp.publickey == null) {
          enqueueSnackbar(`Bạn chưa có khoá, vui lòng tạo khoá`, {
            variant: "error",
          });
          enqueueSnackbar(`Chào mừng bạn ${resp.name} đã quay trở lại`, {
            variant: "success",
          });
          return navigate("/advkd/key", { replace: true });
        }
        else if (resp.vaitro == "ANhaSX") {
          enqueueSnackbar(`Chào mừng bạn ${resp.name} đã quay trở lại`, {
            variant: "success",
          });
          return navigate("/ANhaSX/dashboard", { replace: true });
        }
        else if (resp.vaitro == "NCQKD" && resp.publickey == null) {
          enqueueSnackbar(`Chào mừng bạn ${resp.name} đã quay trở lại`, {
            variant: "success",
          });
          enqueueSnackbar(`Bạn chưa có khoá, vui lòng tạo khoá`, {
            variant: "info",
          });
          return navigate("/ndvkd/key", { replace: true });
        }
        else if (resp.vaitro == "NCQKD" && resp.publickey != null) {
          enqueueSnackbar(`Chào mừng bạn ${resp.name} đã quay trở lại`, {
            variant: "success",
          });

          return navigate("/ndvkd/dashboard", { replace: true });
        }
        else if(resp.vaitro == "CQKD" && resp.publickey != null) {
          enqueueSnackbar(`Chào mừng bạn ${resp.name} đã quay trở lại`, {
            variant: "success",
          });
          return navigate("/advkd/dashboard", { replace: true });
        }
      });
      res.catch((e) => {
        enqueueSnackbar(
          "Server API đang tắt hoặc gặp lỗi. Vui lòng thử lại sau",
          { variant: "error" }
        );
      });
    }
  };
  return (
    <div className="divtong">
      <ReactTitle title="Đăng nhập - TRoot" />
      <div className="divlon">
        <div className="divanh">
          <img
            src={require("../image/userLogin1.png")}
            className="anhNen"
          ></img>
        </div>
        <div className="divLogin">
          <Container component="main">
            <CssBaseline />
            <Box
              className="boxlon"
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <img
                src={require("../image/logo.png")}
                className="loginImage"
              ></img>
              <Typography component="h1" variant="h6" className="text_troot">
                TRoot - Giải pháp phần mềm Blockchain truy xuất nguồn gốc
              </Typography>
              <Box
                component="form"
                onSubmit={handleSubmit}
                noValidate
                sx={{ mt: 1 }}
              >
                {userOK !== undefined ? (
                  <TextField
                    style={{ color: "red" }}
                    margin="normal"
                    required
                    error
                    fullWidth
                    id="username"
                    label="Tên đăng nhập"
                    name="username"
                    autoComplete="username"
                    autoFocus
                  />
                ) : (
                  <TextField
                    style={{ color: "red" }}
                    margin="normal"
                    required
                    fullWidth
                    id="username"
                    label="Tên đăng nhập"
                    name="username"
                    autoComplete="username"
                    autoFocus
                  />
                )}
                {userOK !== undefined && (
                  <Typography style={{ color: "red" }}>{userOK}</Typography>
                )}
                {userPassOK !== undefined ? (
                  <TextField
                    margin="normal"
                    required
                    error
                    fullWidth
                    name="password"
                    label="Mật khẩu"
                    type={getType === false ? "password" : "text"}
                    id="password"
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={showPass}>
                            {getType ? <VisibilityOff /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                ) : (
                  <TextField
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Mật khẩu"
                    type={getType === false ? "password" : "text"}
                    id="password"
                    autoComplete="current-password"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={showPass}>
                            {getType ? <VisibilityOff /> : <VisibilityIcon />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
                {userPassOK !== undefined && (
                  <Typography style={{ color: "red" }}>{userPassOK}</Typography>
                )}
                <Grid container>
                  <Grid item xs>
                    <Link
                      href="/admin/forgotPass"
                      variant="body2"
                      className="forgotPass"
                    >
                      Bạn đã quên mật khẩu ?
                    </Link>
                  </Grid>
                  <Link
                    href="/admin/phonelogin"
                    variant="body2"
                    className="sms_otp"
                    sx={{ marginTop: "2px" }}
                  >
                    Đăng nhập bằng SMS OTP
                  </Link>
                </Grid>
                <Button
                  type="submit"
                  fullWidth
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}
                >
                  Đăng nhập
                </Button>
              </Box>

              <p>Copyright © 2023 T Services</p>
            </Box>
          </Container>
        </div>
      </div>
    </div>
  );
}

export default LoginAdmin;
