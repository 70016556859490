import { id } from "date-fns/locale";
import axiosClient from "./axiosClient";
const callApi = {
  async login(username, password) {
    const url = "/login";
    const post = {
      username: username,
      password: password,
    };
    return await axiosClient.post(url, post);
  },
  async sendMail(username) {
    const url = "/getCode/" + username;
    return await axiosClient.get(url);
  },
  async checkCode(id, code) {
    const res = {
      id: id,
      code: code,
    };
    const url = "/checkCode";
    return await axiosClient.post(url, res);
  },
  async resetPass(id, code, password) {
    const res = {
      id: id,
      password: password,
      code: code,
    };
    const url = "/getChangePass";
    return await axiosClient.post(url, res);
  },
  async sendCode(phoneNumber, token) {
    const res = {
      phoneNumber: phoneNumber,
      recapchaToken: token,
    };
    const url = "/sendSMS";
    return await axiosClient.post(url, res);
  },
  async verify(phoneNumber, code) {
    const res = {
      phoneNumber: phoneNumber,
      code: code,
    };
    const url = "/confimCode";
    return await axiosClient.post(url, res);
  },
  async getListUser(token, pageGet, timkiem) {
    const res = {
      ...pageGet,
      timkiem: timkiem,
    };
    const url = "/userPage";
    return await axiosClient.post(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async getUserToChuc(token, id) {
    const res = {
      idcq: id
    };
    const url = "/getUserPageCQKD";
    return await axiosClient.post(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async updateAvatar(ID, Link, token) {
    const res = {
      id: ID,
      avatar: Link,
    };
    const url = "/updateAvatar";
    return await axiosClient.put(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async totalSize(token) {
    const url = "/totalSizeNguoiDung";
    return await axiosClient.get(url, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async removeUser(token, id) {
    const url = "/removeUser";
    const res = { id: id };
    return await axiosClient.delete(url, {
      headers: {
        Authorization: `${token}`,
      },
      data: res,
    });
  },
  async changePassForgot(token, id) {
    const url = "/adminChange";
    const res = { ID: id };
    return await axiosClient.post(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async getListToChuc(token, res) {
    const url = "/toChucPage";
    return await axiosClient.post(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async totalSizeToChuc(token) {
    const url = "/totalSizeToChuc";
    return await axiosClient.get(url, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async getAllToChuc(token) {
    const url = "/getAllCQ";
    return await axiosClient.get(url, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async updateUser(token, value) {
    const url = "/updateUser/";
    return await axiosClient.put(url, value, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async addUser(value) {
    const url = "/addUser/";
    return await axiosClient.post(url, value);
  },
  async vohieuhoatc(token, id) {
    const res = {
      id: id,
    };
    const url = "/disableToChuc/";
    return await axiosClient.post(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async updateLogoTC(token, id, logo) {
    const ress = {
      id: id,
      logo: logo,
    };
    const url = "/updateLogoToChuc";
    return await axiosClient.post(url, ress, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async suaToChuc(token, id, ten, diachi, sdt, loai) {
    if (loai == null) loai = "CoQuanSX";
    const ress = {
      id: id,
      ten: ten,
      diachi: diachi,
      sdt: sdt,
      loai: loai,
    };
    const url = "/editCoQuan";
    return await axiosClient.put(url, ress, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async ThemToChuc(token, ten, diachi, sdt, loai, tieuchuan) {
    if (tieuchuan == null) tieuchuan = "null";
    const ress = {
      tencq: ten,
      diachi: diachi,
      sdt: sdt,
      loai: loai,
      tieuchuan: tieuchuan,
    };
    const url = "/addCoQuan";
    return await axiosClient.post(url, ress, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async updateUserNoPass(token, id, name, email, sdt) {
    const res = {
      id: id,
      email: email,
      name: name,
      phone: sdt,
    };
    const url = "/updateUser";
    return await axiosClient.put(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async changePasswordUser(token, id, ownpass, pass, confimPass) {
    const res = {
      ID: id,
      password: pass,
      passcu: ownpass,
      confimPass: confimPass,
    };
    const url = "/changePass";
    return await axiosClient.put(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
};
export default callApi;
