import StarIcon from "@mui/icons-material/Star";
import { Button, Grid, Typography } from '@mui/material';
import { lime } from "@mui/material/colors";
import { styled } from "@mui/material/styles";
import { Box } from '@mui/system';
import * as React from 'react';
import './HomePage.css';
import { ReactTitle } from 'react-meta-tags'
HomePageNSX.propTypes = {

};

function HomePageNSX(props) {

  function redirectLink() {
    window.location.href = "https://truyxuatnguongoc.nckh.sytuan.net"
  }
  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText(lime[500]),
    backgroundColor: lime[500],
    "&:hover": {
      backgroundColor: lime[700],
    },
  }));
  return (
    <div>
      <ReactTitle title='Trang chủ Admin - TRoot' />
      <Box>
        <Grid container>
          <Grid item className="grid_trai">
            <div className="div_3">
              <div className="ribbon">
                <img
                  src={require("../image/ribbon_trai.png")}
                  className="ribbonTrai"
                ></img>
                <img
                  src={require("../image/ribonPhai.png")}
                  className="ribbonPhai"
                ></img>
              </div>
              <div className="star">
                <div className="div2_star">
                  <StarIcon
                    className="star_icon"
                    style={{
                      width: "75%",
                      height: "100%",
                      justifyContent: "center",
                    }}
                  />
                </div>
              </div>
              <div>
                <div className="divChu">
                  <Typography
                    style={{
                      color: "white",
                      fontWeight: "bold",
                      mb: 0,
                      fontSize: 23,
                    }}
                  >
                    XIN CHÀO
                  </Typography>
                  <Typography
                    style={{
                      color: "white",
                      mb: 0,
                      lineHeight: 2,
                      fontSize: 20,
                    }}
                  >
                    Chào mừng bạn đến với TRoot!
                  </Typography>
                </div>
              </div>
            </div>
          </Grid>
          <Grid className="grid_phai">
            <div className="div_grid_phai">
              <Typography variant="h6" component="h3" className="text_gtgtsp">
                Bạn muốn gia tăng giá trị sản phẩm?
              </Typography>
              <Typography variant="subtitle1" className="text_spgt">
                Gia tăng giá trị và bảo vệ thương hiệu sản phẩm bằng giải pháp
                Truy xuất nguồn gốc Blockchain của
                <span className="spanTroot" onClick={redirectLink}>
                  {" "}
                  TRoot
                </span>
                . Người tiêu dùng sẽ hiểu và an tâm về nguồn gốc, chất lượng
                sản phẩm của bạn.
              </Typography>
              <Button
                className="btn_bt"
                variant="contained"
                style={{
                  borderRadius: 35,
                  backgroundColor: "white",
                  color: '#679847',
                  marginTop: '4vh'
                }}
                onClick={redirectLink}
              >
                Xem chi tiết
              </Button>
            </div>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}

export default HomePageNSX;