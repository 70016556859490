import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { VisibilityOff } from "@mui/icons-material";
import VisibilityIcon from "@mui/icons-material/Visibility";
ChangePasswordProfile.propTypes = {
    onSubmit: PropTypes.func,
};

function ChangePasswordProfile({ onSubmit }) {
  const validationSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(6, "Password cần phải có đủ tối thiểu 6 ký tự")
      .required("Bạn không thể bỏ trống Password"),
    password: Yup.string()
      .min(6, "Password cần phải đủ tối thiểu 6 ký tự")
      .required("Password không thể bỏ trống"),
    reenterpassword: Yup.string()
      .oneOf([Yup.ref("password"), null], "Nhập lại mật khẩu không chính xác")
      .required("Không thể bỏ trống nhập lại mật khẩu"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    handleSubmit,
    formState,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm(formOptions);
  const [showPass,setShowPass] = useState(false);
  const [showEnterPass,setshowEnterPass] = useState(false);
  const [showReenterPass,setshowReenterPass] = useState(false);
  const sshowPass = () => {
    setShowPass(!showPass);
  }
  const sshowEnterPass = () => {
    setshowEnterPass(!showEnterPass);
  }
  const sshowReenterPass = () => {
    setshowReenterPass(!showReenterPass)
  }
  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextField
          label="Mật khẩu cũ"
          fullWidth
          type={Boolean(showPass) ? "text" : "password"}
          name="oldPassword"
          error={Boolean(errors.oldPassword)}
          {...register("oldPassword")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={sshowPass}>
                  {showPass ? <VisibilityOff /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        ></TextField>
        <Typography variant="caption" style={{ color: "red" }}>
          {errors.oldPassword?.message}
        </Typography>
        <TextField
          label="Mật khẩu mới"
          fullWidth
          type={Boolean(showEnterPass) ? "text" : "password"}
          name="newPassword"
          error={Boolean(errors.password)}
          style={{ marginTop: 12 }}
          {...register("password")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton onClick={sshowEnterPass}>
                  {showEnterPass ? <VisibilityOff /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="caption" style={{ color: "red" }}>
          {errors.password?.message}
        </Typography>
        <TextField
          label="Nhập lại mật khẩu mới"
          fullWidth
          type={Boolean(showReenterPass) ? "text" : "password"}
          name="retypePassword"
          style={{ marginTop: 12 }}
          error={Boolean(errors.reenterpassword)}
          {...register("reenterpassword")}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" onClick={sshowReenterPass}>
                <IconButton>
                  {showReenterPass ? <VisibilityOff /> : <VisibilityIcon />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Typography variant="caption" style={{ color: "red" }}>
          {errors.reenterpassword?.message}
        </Typography>
        <div className="changePass_div">
          <Button variant="contained" type="submit" style={{ marginTop: 15 }}>
            Cập nhật
          </Button>
        </div>
      </form>
    </div>
  );
}

export default ChangePasswordProfile;
