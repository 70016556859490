import { faPersonWalkingDashedLineArrowRight } from "@fortawesome/free-solid-svg-icons";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import PropTypes from "prop-types";
import { default as React, useState } from "react";
import { Link, Navigate, Route, useParams } from "react-router-dom";
import editSanPham from "../EditSanPham";
import ShowQR from "./ShowQRCodeSP";
import XoaSanPham from "./XoaSanPham";
ShowTableOneUser.propTypes = {
  data: PropTypes.object,
  size: PropTypes.number,
};
function ShowTableOneUser({ data, size }) {
  const params = useParams();

  var stt = 0;
  const [sp, setSP] = useState();
  const [qr, setQR] = useState();

  const editSP = (data) => {
    console.log(data);
    return <Route path="/home"></Route>
  };
  const ngungSXSP = (data) => {
    setSP(data);
  };
  const dialogClose = () => {
    setSP(null);
    setQR(null);
  };
  const showQR = (e) => {
    setQR(e);
  }
  const IDUser = localStorage.getItem('ID');
  return (
    <>
      {data.results.map((res) => {
        stt++;
        if (res.HinhAnhSP.hinh1 == 'null') res.HinhAnhSP.hinh1 = require('../../image/notFound.png')
        return (
          <>
            <TableRow hover role="checkbox">
              <TableCell>{stt}</TableCell>
              <TableCell style={{ display: 'flex', alignItems: 'center' }}>
                <img
                  src={res.HinhAnhSP.hinh1}
                  alt="Sản phẩm không có ảnh"
                  style={{ width: "37px", height: "37px" }}
                />
                {res.TenSP}
              </TableCell>
              <TableCell>
                {res.NgaySX}
              </TableCell>
              <TableCell>{res.ThoiHanSD}</TableCell>
              <TableCell>
                {res.IDUser == IDUser &&
                  <PopupState variant="popover" popupId="showMore">
                    {(popupState) => (
                      <React.Fragment>
                        <IconButton {...bindTrigger(popupState)}>
                          <MoreVertIcon />
                        </IconButton>
                        <Menu {...bindMenu(popupState)}>
                          <MenuItem>
                            <Link to={`/nhasx/suasanpham/${res.ID}`} style={{ textDecoration: 'none', color: 'black' }} params={res.ID}>Sửa thông tin sản phẩm</Link>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              ngungSXSP(res);
                            }}
                          >
                            <Typography onClick={popupState.close}>
                              {res.trangthai == true ? (
                                <span>Ngưng sản xuất</span>
                              ) : (
                                <span>Sản xuất trở lại</span>
                              )}
                            </Typography>
                          </MenuItem>
                          <MenuItem
                            onClick={() => {
                              showQR(res);
                            }}
                          >
                            <Typography onClick={popupState.close}>
                              <span>Xem mã QR của sản phẩm</span>
                            </Typography>
                          </MenuItem>
                        </Menu>
                      </React.Fragment>
                    )}
                  </PopupState>}
              </TableCell>
            </TableRow>
            {sp == res && <XoaSanPham data={sp} onClose={dialogClose} />}
            {qr == res && <ShowQR data={qr} onClose={dialogClose} />}
          </>
        );
      })}
    </>
  );
}

export default ShowTableOneUser;
