import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography
} from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useState } from "react";
import UseStore from "../../Zustand/zustand";
import KichHoatToChuc from "./KichHoatToChuc";
import SuaToChuc from "./SuaToChuc";
function BangInfoToChuc({ data, size }) {
  const IDHT = UseStore((state) => state.ID);
  const [dataDialog, setDataDialog] = useState(false);
  const [suatochuc,setSuaToChuc] = useState(0);
  const [infoUserDel, setInfoUserDel] = useState(0);
  const handleCloseDL = () => {
    setSuaToChuc(0);
  };
  const [khltc, kichhoattc] = useState(0);
  const closeDialogMH = () => {
    kichhoattc(0);
  };

  var dem = size;
  return (
    <>
      {data.results.map((res) => {
        var vt;
        if (res.vaitro == "Admin") vt = "Người quản trị";
        else if (res.vaitro == "NhaSX") vt = "Nhà sản xuất";
        else vt = res.vaitro;
        dem++;
        var loai;
        if(res.loai == 'CoQuanSX') loai = 'Đơn vị sản xuất';
        else if(res.loai == 'CoQuanKD') loai = 'Cơ quan kiểm định'
        else loai = "Cơ quan phần mềm"
        var tt;
        if(res.trangthai == 'true') tt = "Đang hoạt động";
        else tt = 'Đang bị vô hiệu hoá'
        return (
          <TableRow hover role="checkbox" key={res.ID}>
            <TableCell>{dem - 1}</TableCell>
            <TableCell>{res.TenCQ}</TableCell>
            <TableCell>{res.DiaChi}</TableCell>
            <TableCell>{res.SDT}</TableCell>
            <TableCell>{loai}</TableCell>
            <TableCell>{tt}</TableCell>
            <TableCell>
              <PopupState variant="popover" popupId="showMore">
                {(popupState) => (
                  <React.Fragment>
                    <IconButton {...bindTrigger(popupState)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          setSuaToChuc(res);
                        }}
                      >
                        <Typography onClick={popupState.close}>
                          Sửa tổ chức
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          kichhoattc(res);
                        }}
                      >
                        <Typography onClick={popupState.close}>
                          {res.trangthai == "true"
                            ? "Vô hiệu hoá tổ chức"
                            : "Kích hoạt lại tổ chức"}
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
              {khltc == res && (
                <KichHoatToChuc data={res} onClose={closeDialogMH} />
              )}
              {suatochuc == res && (
                <SuaToChuc data={suatochuc} onClose={handleCloseDL} />
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

export default BangInfoToChuc;
