import axiosClient from "./axiosClient";
const SanPhamAPI = {
  async sanpham(data) {
    const url = "/addProductAndQuyTrinh";
    return axiosClient.post(url, data);
  },
  async updateSP(data) {
    const url = "/updateProductandQT";
    return axiosClient.post(url, data);
  },
  async pagination(ID, Size, bookmark, token) {
    const url = "/SanPhamPage";

    const res = {
      ID: ID,
      size: Size,
      bookmark: bookmark,
    };
    console.log(res);
    return await axiosClient.post(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async totalSizeID(ID, token) {
    const url = "/totalSizeSanPham";
    const res = {
      ID: ID,
    };
    return await axiosClient.post(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async removeUser(token, id) {
    const url = "/removeSP";
    const res = { id: id };
    return await axiosClient.delete(url, {
      headers: {
        Authorization: `${token}`,
      },
      data: res,
    });
  },
  async getInfoProduct(id) {
    const url = "/getSanPham/" + id;
    return await axiosClient.get(url);
  },
  async getQuyTrinh(id) {
    const url = "/getQuyTrinh/" + id;
    return await axiosClient.get(url);
  },
  async getNVQT(id, token) {
    console.log(id);
    const url = "/getByDVKD";
    const res = {
      user: id,
    };
    return await axiosClient.post(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async pccq(userID, token, idsp) {
    const url = "/getByDVKDNV";
    const res = {
      idsp: idsp,
      user: userID,
    };
    console.log(res);
    return await axiosClient.post(url, res, {
      headers: {
        Authorization: `${token}`,
      },
    });
  },
  async getStatusKey(id) {
    const url = "/getPKKey/" + id;
    return await axiosClient.get(url);
  },
  async createPrivateKey(id) {
    const url = "/caplaiPK";
    const data = { id: id };
    return axiosClient.post(url, data);
  },
  async getSPQuyTrinh(id) {
    const url = "/getSPToChuc";
    const data = { idtc: id };
    return axiosClient.post(url, data);
  },
  async SubMItGiaoViec(datas) {
    const url = "/updateFullQT";
    const data = { datas: datas };
    return axiosClient.post(url, data);
  },
  async getQuyTrinh(id) {
    const url = "getQuyTrinh/" + id;
    return axiosClient.get(url);
  },
  async getQTByID(id) {
    const url = "getOneQT/" + id;
    return axiosClient.get(url);
  },
  async khongkyqt(id, idnguoiky) {
    const url = "/khongky";
    const data = { id: id, idnguoiky: idnguoiky };
    return axiosClient.post(url, data);
  },
  async ky(id, idsp, key) {
    const url = "/kyXT";
    const data = { idsp: idsp, id: id, key: key };
    return axiosClient.post(url, data);
  },
  async truyvanqt(idsp) {
    const url = "/getSPNoSig";
    const data = { idsp: idsp };
    return axiosClient.post(url, data);
  },
  async taomoiqt(idsp, iduser, hinhqd) {
    const url = "/addQuyTrinh";
    const data = {
      idsp: idsp,
      iduser: iduser,
      hinhqd: hinhqd,
    };
    return axiosClient.post(url, data);
  },
};
export default SanPhamAPI;
