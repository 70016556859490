import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  FormControl, Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography
} from "@mui/material";
import { convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";
import { ReactTitle } from "react-meta-tags";
import * as Yup from "yup";
import callApi from "../../Api/userApi";
import SanPhamStore from "../../Zustand/SanPham";
import "./ProductStep1.css";
ProductStep1.propTypes = {
  infoUser: PropTypes.object,
  next: PropTypes.func,
  setStep: PropTypes.func,
};

function ProductStep1({ infoUser, next, setStep }) {
  const validationSchema = Yup.object().shape({
    tenqtLon: Yup.string().required("Tên quy trình không thể bỏ trống")
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const [quytrinhRender, setquyTrinhRender] = useState([]);
  const quyTrinhChange = (e) => {
    if (e.target.value <= 30) {
      let obj = [];
      for (let i = 0; i < e.target.value; i++) obj[i] = { key: i + 1 };
      setquyTrinhRender(obj);
    }
  };
  const {
    handleSubmit,
    formState,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm(formOptions);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const setMoTaQT = SanPhamStore((state) => state.setMoTaQT);
  const setEditor = (edtState) => {
    setEditorState(edtState);
    setMoTaQT(convertToHTML(editorState.getCurrentContent()));
  };
  const setLoai = SanPhamStore((state) => state.setLoai);
  setLoai("TatCa");
  const setTenQT = SanPhamStore((state) => state.setTenQT);
  const Submit = (e) => {
    let res = [];
    setTenQT(e.tenqtLon);
    for (let i = 0; i < quytrinhRender.length; i++) {
      res[i] = {
        tenqt: e.tenqt[i + 1],
        mota: e.mota[i + 1],
        donvikd: e.donvikd[i + 1],
      };
    }

    setStep(res);
    next();
  };
  const [donvikiemdinh, setDonViKiemDinh] = useState(null);
  useEffect(() => {
    callApi.getAllToChuc(infoUser.accessToken).then((res) => {
      setDonViKiemDinh(res);
    });
  }, []);
  return (
    <div>
      <ReactTitle title="Bước 2 - Thêm sản phẩm - TRoot"></ReactTitle>
      <form onSubmit={handleSubmit(Submit)} className="root">
        <div style={{ marginTop: 3 }}>
          <Typography
            variant="h6"
            sx={{ fontWeight: "bold", color: "#3b3e66" }}
          >
            Thêm các bước trong quy trình sản xuất cần ký vào hệ thống
          </Typography>
          <Typography variant="caption1">
            Bạn có thể thêm các bước bất cứ lúc nào sau khi tạo xong sản phẩm!
          </Typography>
          <br></br>
          <Typography variant="caption1">
            Lưu ý: Mỗi sản phẩm có tối đa 30 bước
          </Typography>
        </div>
        <div className="div_slqt">
          <Typography variant="caption1">
            Nhập thông tin về quy trình của bạn:
          </Typography>
        </div>
        <div className="div_tenqt">
          <Typography variant="caption1">
            Tên quy trình <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            {...register("tenqtLon")}
            error={Boolean(errors.tenqtLon)}
            style={{ marginTop: 5 }}
            placeholder='Ví dụ: Quy trình VietGaps'
          ></TextField>
          <Typography variant="caption1" style={{ color: 'red' }}>{errors.tenqtLon?.message}</Typography>
        </div>
        <div className="div_mota">
          <Typography variant="caption1">Mô tả về quy trình</Typography>
          <div className="editor">
            <Editor
              editorState={editorState}
              fullWidth
              toolbarClassName="toolbarClassName"
              wrapperClassName="wrapperClassName"
              editorClassName="editorClassName"
              onEditorStateChange={setEditor}
            />
          </div>
        </div>
        <div className="div_slqt">
          <Typography variant="caption1">
            Nhập số lượng các bước trong quy trình:
          </Typography>
          <Input
            type="number"
            onChange={quyTrinhChange}
            inputProps={{ min: 0, max: 30 }}
          ></Input>
        </div>

        <div>
          {quytrinhRender.map((res) => {
            return (
              <div
                style={{ display: "flex", flexDirection: "column" }}
                key={res.key}
              >
                <Typography variant="caption1">
                  Quy trình thứ {res.key}:
                </Typography>
                <TextField
                  label="Tên bước"
                  style={{ marginTop: 6 }}
                  required
                  {...register(`tenqt[${res.key}]`)}
                ></TextField>
                <TextField
                  label="Mô tả về bước"
                  style={{ marginTop: 6 }}
                  required
                  {...register(`mota[${res.key}]`)}
                ></TextField>
                <FormControl fullWidth style={{ marginTop: 6 }}>
                  <InputLabel>Lựa chọn đơn vị kiểm định</InputLabel>
                  <Select
                    label="Lựa chọn đơn vị kiểm định"
                    required
                    {...register(`donvikd[${res.key}]`)}
                  >
                    {donvikiemdinh != undefined &&
                      donvikiemdinh.map((res) => {
                        if (res.record.loai == "CoQuanKD" ) {
                          return (
                            <MenuItem value={res.record.ID}>
                              {res.record.TenCQ}
                            </MenuItem>
                          );
                        }
                      })}
                  </Select>
                </FormControl>
              </div>
            );
          })}
        </div>
        <div className="div_addSP">
          <Button type="submit">Thêm sản phẩm</Button>
        </div>
      </form>
    </div>
  );
}

export default ProductStep1;
