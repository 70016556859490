import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import './ProductStep2.css'
import { CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
ProductStep2.propTypes = {
  infoUser: PropTypes.object,
  submit: PropTypes.func,
};

function ProductStep2({ infoUser, submit }) {
  const navigate = useNavigate();
  submit();

  return (
    <div>
      <div className="div_step2">
        <CircularProgress />
      </div>
      <Typography>
        Hệ thống đang xử lý dữ liệu, vui lòng chờ đợi trong giây lát
      </Typography>
    </div>
  );
}

export default ProductStep2;