import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Dialog,
    DialogContent,
    Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import ShowTableOneUser from "./ShowTableOneUser";
import UseStore from "../../Zustand/zustand";
import SanPhamAPI from "../../Api/SanPhamAPI";

TableAndSkeletion.propTypes = {};
function TableAndSkeletion(props) {
  const obj = [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 },{id: 5},{id: 6}];
    const [page, setPage] = React.useState(0);
    const [dialog, setDialog] = React.useState(false);
    const openDialog = () => {
      setDialog(true);
    };
    const closeDialog = () => {
      setDialog(false);
    };
    const hotReload = UseStore((state) => state.hotReload);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [rowPageGet, setRowPageGet] = React.useState({
      size: rowsPerPage,
      bookmark:
        "",
    });
    const [count, setCound] = React.useState();
    const handleChangeRowsPerPage = (event) => {
      setRowPageGet(event.target.value);
      setPage(0);
      setRowsPerPage(event.target.value);
      setRowPageGet({
        size: event.target.value,
        bookmark:
          "",
      });
    };
    const bookmarkHistory = [
      "",
    ];
    const [data, setData] = React.useState(null);
    const handleChangePage = (event, newPage) => {
      bookmarkHistory.push(data.bookmark);
      if (newPage < page)
        data.bookmark = bookmarkHistory[bookmarkHistory.length - 2];
      setPage(newPage);
      setRowPageGet({
        ...rowPageGet,
        bookmark: data.bookmark,
      });
    };
    const setHotReload = UseStore((state) => state.setHotReload);
    const accessToken = UseStore((state) => state.accessToken);
    const ID = UseStore((state) => state.phongban)

    React.useEffect(() => {
      SanPhamAPI.totalSizeID(ID,accessToken).then((res) => setCound(res));
      SanPhamAPI.pagination(ID, rowPageGet.size, "", accessToken).then((res) =>
        setData(res)
      );
    }, [rowPageGet, hotReload]);
    const animation = UseStore((state) => state.loading);
  return (
    <>
      <TableContainer>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              <TableCell>STT</TableCell>
              <TableCell>Tên sản phẩm</TableCell>
              <TableCell>Ngày sản xuất</TableCell>
              <TableCell>Thời hạn sử dụng</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data == null ? (
              obj.map((res) => {
                return (
                  <TableRow key={res.id}>
                    {obj.map((res) => {
                      return (
                        <TableCell key={res.id}>
                          <Skeleton />
                        </TableCell>
                      );
                    })}
                  </TableRow>
                );
              })
            ) : (
              <ShowTableOneUser data={data} size={page * rowsPerPage + 1} />
            )}
          </TableBody>
        </Table>
        {count != undefined && (
          <TablePagination
            rowsPerPageOptions={[10, 25, 50]}
            component="div"
            count={count.total - 1}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        )}
      </TableContainer>
      {animation == true && (
        <Dialog
          open={true}
          style={{ background: "none" }}
          className="DiaLog_Loading"
        >
          <DialogContent>
            <img
              src={require("../../image/iconLoading.gif")}
              className="icon_loading"
            />
          </DialogContent>
        </Dialog>
      )}
    </>
  );
}

export default TableAndSkeletion;
