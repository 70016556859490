import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import {
  Button,
  IconButton,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { convertToHTML } from "draft-convert";
import { EditorState } from "draft-js";
import moment from "moment";
import { useSnackbar } from "notistack";
import PropTypes from "prop-types";
import { React, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";
import { ReactTitle } from "react-meta-tags";
import * as Yup from "yup";
import SanPhamStore from "../../Zustand/SanPham";
import "./ProductStep0.css";
ProductStep0.propTypes = {
  infoUser: PropTypes.object,
  next: PropTypes.func,
  setStep: PropTypes.func,
};

function ProductStep0({ infoUser, next, setStep }) {

  const [currency, setCurrency] = useState(0);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const setMoTaHtml = SanPhamStore((state) => state.setMoTa);
  const setEditor = (edtState) => {
    setEditorState(edtState);
    setMoTaHtml(convertToHTML(editorState.getCurrentContent()));
  };
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const validationSchema = Yup.object().shape({
    tenSP: Yup.string().required("Không thể bỏ trống Tên sản phẩm"),
    HanSD: Yup.string().required("Sản phẩm phải có hạn sử dụng"),
    lohang: Yup.string().required("Không thể bỏ trống lô hàng"),
    NgaySX: Yup.string().required("Sản phẩm phải có ngày sản xuất"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const {
    handleSubmit,
    formState,
    register,
    reset,
    formState: { isSubmitting, errors },
  } = useForm(formOptions);
  const mota = SanPhamStore((state) => state.mota);
  let formData = new FormData();
  const [targetFile1, setTargetFile1] = useState(null);
  const [targetFile2, setTargetFile2] = useState(null);
  const [targetFile3, setTargetFile3] = useState(null);
  const [ttloaisp, setLoaiSP] = useState("Trái cây");
  const [btloading, setBtLoading] = useState(false);
  const submitForm = async (e) => {
    setBtLoading(true);
    if (targetFile1 != null)
      formData.append("images", targetFile1);
    if (targetFile2 != null)
      formData.append("images", targetFile2);
    if (targetFile3 != null)
      formData.append("images", targetFile3);
    let img = null;
    await fetch("https://image.banlait.com:3000/uploadBulkImage", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((result) => {
        img = result;
      })
    var IDSPP = localStorage.getItem('phongban')
    const product = {
      tensp: e.tenSP,
      loaisp: ttloaisp,
      lo: e.lohang,
      ngaysx: e.NgaySX,
      HanSD: e.HanSD,
      iduser: infoUser.ID,
      IDDN: IDSPP,
      mota: mota,
      hinh1: !img[0] ? "null" : `https://image.banlait.com:3000/${img[0].path}`,
      hinh2: !img[1] ? "null" : `https://image.banlait.com:3000/${img[1].path}`,
      hinh3: !img[2] ? "null" : `https://image.banlait.com:3000/${img[2].path}`,
    };
    setStep(product);
    next();
  };
  const [image1, setImage1] = useState(require("../../image/imageIcon.png"));
  const [image2, setImage2] = useState(require("../../image/imageIcon.png"));
  const [image3, setImage3] = useState(require("../../image/imageIcon.png"));
  const [otherOpen, setotherOpen] = useState(false);
  const setDataChange = (e) => {
    setLoaiSP(e.target.value);
    if (e.target.value === "Khác") setotherOpen(true);
  };
  const img1 = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      setImage1(reader.result); //base64encoded string
    };
    setTargetFile1(e.target.files[0]);
  };
  const img2 = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);

    reader.onload = () => {
      setImage2(reader.result); //base64encoded string
    };
    setTargetFile2(e.target.files[0]);
  };
  const img3 = (e) => {
    var reader = new FileReader();
    reader.readAsDataURL(e.target.files[0]);
    reader.onload = () => {
      setImage3(reader.result); //base64encoded string
    };
    setTargetFile3(e.target.files[0]);
  };
  var value = new Date();
  value = moment(value).format("YYYY-MM-DD");
  return (
    <div>
      <ReactTitle title="Bước 1 - Thêm sản phẩm - TRoot" />
      <form onSubmit={handleSubmit(submitForm)} id="formName">
        <div className="div_content">
          <div>
            <Typography
              variant="h5"
              sx={{ fontWeight: "bold", color: "#3b3e66" }}
            >
              Mô tả sản phẩm
            </Typography>
            <Typography variant="caption2">
              Thông tin cơ bản giới thiệu sản phẩm
            </Typography>
          </div>
          <div className="div_tren1">
            <div
              className="div_tensp"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography variant="caption1">
                Tên sản phẩm <span style={{ color: "red" }}>(*)</span>
              </Typography>
              <TextField
                fullWidth
                style={{ marginTop: 5 }}
                name="tenSP"
                error={Boolean(errors.tenSP)}
                {...register("tenSP")}
              ></TextField>
              <Typography variant="caption1" style={{ color: "red" }}>
                {errors.tenSP?.message}
              </Typography>
            </div>
            <div
              className="div_loaisp"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <Typography variant="caption1">
                Loại sản phẩm <span style={{ color: "red" }}>(*)</span>
              </Typography>
              {otherOpen == false ?
                <Select
                  className="option_loaisp"
                  style={{ marginTop: 5 }}
                  defaultValue={"Trái Cây"}
                  name="loaisp"
                  error={Boolean(errors.loaisp)}
                  onChange={setDataChange}>
                  <MenuItem value={"Trái Cây"}>Trái Cây</MenuItem>
                  <MenuItem value={"Thịt, cá, trứng"}>Thịt, cá, trứng</MenuItem>
                  <MenuItem value={"Rau củ"}>Rau củ</MenuItem>
                  <MenuItem value={"Đông lạnh"}>Đông lạnh</MenuItem>
                  <MenuItem value={"Gia vị"}>Gia vị</MenuItem>
                  <MenuItem value={"Thực phẩm khô"}>Thực phẩm khô</MenuItem>
                  <MenuItem value={"Bia, nước ngọt"}>Bia, nước ngọt</MenuItem>
                  <MenuItem value={"Sữa"}>Sữa</MenuItem>
                  <MenuItem value={"Khác"}>Khác</MenuItem>
                </Select>
                : <TextField fullWidth
                  style={{ marginTop: 5 }}
                  name="loaisp"
                  error={Boolean(errors.tenSP)}
                  onChange={setDataChange}
                  required
                />}
            </div>
          </div>
          <div className="div_tren3">
            <div className="div_thsd">
              <Typography variant="caption1">
                Ngày sản xuất <span style={{ color: "red" }}>(*)</span>
              </Typography>
              <TextField
                id="date"
                type="date"
                error={Boolean(errors.NgaySX)}
                {...register("NgaySX")}
                InputProps={{
                  inputProps: { max: value },
                }}
                required
              />
              <Typography style={{ color: "red" }} variant="caption1">
                {errors.NgaySX?.message}
              </Typography>
            </div>
            <div className="div_lo">
              <Typography variant="caption1">
                Hạn sủ dụng <span style={{ color: "red" }}>(*)</span>
              </Typography>
              <TextField
                id="date"
                type="date"
                error={Boolean(errors.HanSD)}
                {...register("HanSD")}
                InputProps={{
                  inputProps: { min: value },
                }}
              />
              <Typography style={{ color: "red" }} variant="caption1">
                {errors.HanSD?.message}
              </Typography>
            </div>
          </div>
          <div className="div_tren2">
            <div className="div_mota">
              <Typography variant="caption1">
                Lô hàng <span style={{ color: "red" }}>(*)</span>
              </Typography>
              <TextField
                type="text"
                {...register("lohang")}
                error={Boolean(errors.lohang)}
              ></TextField>
              <Typography style={{ color: "red" }} variant="caption1">
                {errors.lohang?.message}
              </Typography>
            </div>
          </div>
          <div className="div_mota">
            <Typography variant="caption1">Mô tả về sản phẩm</Typography>
            <div className="editor">
              <Editor
                editorState={editorState}
                fullWidth
                toolbarClassName="toolbarClassName"
                wrapperClassName="wrapperClassName"
                editorClassName="editorClassName"
                onEditorStateChange={setEditor}
              />
            </div>
          </div>
          <div className="div_hinhanhsp">
            <Typography variant="caption1">Hình ảnh sản phẩm:</Typography>
            <div>
              <IconButton aria-label="upload picture" component="label">
                <img src={image1} className="img_1" />
                <input
                  hidden
                  accept="image/jpg, image/jpeg"
                  type="file"
                  {...register("img1")}
                  onChange={img1}
                ></input>
              </IconButton>
              <IconButton aria-label="upload picture" component="label">
                <img src={image2} className="img_1" />
                <input
                  hidden
                  accept="image/jpg, image/jpeg"
                  type="file"
                  {...register("img2")}
                  onChange={img2}
                ></input>
              </IconButton>
              <IconButton aria-label="upload picture" component="label">
                <img src={image3} className="img_1" />
                <input
                  hidden
                  accept="image/jpg, image/jpeg"
                  type="file"
                  {...register("img3")}
                  onChange={img3}
                ></input>
              </IconButton>
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "right" }}>
            <LoadingButton
              loading={Boolean(btloading)}
              type="submit"
              form="formName"
              variant="contained"
            >
              Bước kế tiếp
            </LoadingButton>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ProductStep0;
