import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  IconButton,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import PopupState, { bindMenu, bindTrigger } from "material-ui-popup-state";
import React, { useState } from "react";
import UseStore from "../Zustand/zustand";
import CapLaiMK from "./NguoiDung/CapLaiMK";
import SuaNguoiDung from "./NguoiDung/SuaNguoiDung";
import XoaNguoiDung from "./NguoiDung/XoaNguoiDung";
function RowTable({ data, size }) {
  const IDHT = UseStore((state) => state.ID);
  const [dataDialog, setDataDialog] = useState(false);
  const handleCloseND = () => {
    setDataDialog(false);
  };
  const [infoUserDel, setInfoUserDel] = useState(0);
  const handleCloseDL = () => {
    setInfoUserDel(false);
  };
  const [infoUserChangePass, setChangePass] = useState(0);
  const closeDialogMH = () => {
    setChangePass(0);
  }

  var dem = size;
  return (
    <>
      {data.results.map((res) => {
        var vt;
        if (res.Value.vaitro == "Admin") vt = "Người quản trị";
        else if (res.Value.vaitro == "NhaSX") vt = "Nhà sản xuất";
        else if (res.Value.vaitro == "AdminTC") vt = 'Người quản trị tổ chức';
        else if (res.Value.vaitro == "ANhaSX") vt = "Admin Nhà SX";
        else vt = "Cơ quan kiểm định";
        dem++;
        return (
          <TableRow hover role="checkbox" key={res.Value.ID}>
            <TableCell>{dem - 1}</TableCell>
            <TableCell>{res.Value.username}</TableCell>
            <TableCell>{res.Value.name}</TableCell>
            <TableCell>{res.Value.email}</TableCell>
            <TableCell>{res.Value.phone}</TableCell>
            <TableCell>{res.Value.coquan}</TableCell>
            <TableCell>{vt}</TableCell>
            <TableCell>
              <PopupState variant="popover" popupId="showMore">
                {(popupState) => (
                  <React.Fragment>
                    <IconButton {...bindTrigger(popupState)}>
                      <MoreVertIcon />
                    </IconButton>
                    <Menu {...bindMenu(popupState)}>
                      <MenuItem
                        onClick={() => {
                          setDataDialog(res);
                        }}
                      >
                        <Typography onClick={popupState.close}>
                          Sửa người dùng
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setChangePass(res);
                        }}
                      >
                        <Typography onClick={popupState.close}>
                          Cấp lại mật khẩu
                        </Typography>
                      </MenuItem>
                      <MenuItem
                        onClick={() => {
                          setInfoUserDel(res);
                        }}
                      >
                        <Typography onClick={popupState.close}>
                          Xoá người dùng
                        </Typography>
                      </MenuItem>
                    </Menu>
                  </React.Fragment>
                )}
              </PopupState>
              {dataDialog == res && (
                <SuaNguoiDung
                  info={dataDialog}
                  openSua={Boolean(dataDialog)}
                  handleClose={handleCloseND}
                />
              )}
              {infoUserDel == res && (
                <XoaNguoiDung
                  data={infoUserDel}
                  handleClose={handleCloseDL}
                  open={Boolean(infoUserDel)}
                />
              )}
              {infoUserChangePass == res && (
                <CapLaiMK
                  data={infoUserChangePass}
                  open={Boolean(infoUserChangePass)}
                  handleClose={closeDialogMH}
                />
              )}
            </TableCell>
          </TableRow>
        );
      })}
    </>
  );
}

export default RowTable;
