import { SnackbarProvider } from 'notistack';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import RouteDir from './Route';

function App() {
  return (
    <SnackbarProvider anchorOrigin={{ horizontal: 'right', vertical: 'top' }}>
      <BrowserRouter>



        <RouteDir />

      </BrowserRouter>
    </SnackbarProvider>
  );
}

export default App;
