// component
import Iconify from "./Iconify";

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: "Trang chủ",
    path: "/ndvkd/dashboard",
    icon: getIcon("eva:pie-chart-2-fill"),
  },
  {
    title: "Xác nhận quy trình",
    path: "/ndvkd/quytrinh",
    icon: getIcon("fluent-mdl2:processing"),
  },
  {
    title: "Quản lý khoá",
    path: "/ndvkd/key",
    icon: getIcon("material-symbols:key"),
  },
  {
    title: "Trang cá nhân",
    path: "/ndvkd/profile",
    icon: getIcon("icon-park-twotone:permissions"),
  },
];

export default navConfig;
