import firebase from "firebase";

const firebaseConfig = {
    apiKey: "AIzaSyBS-6NrgPdhQHsmVhW0SgB2JrSueNkSmbA",
    authDomain: "nodal-linker-356207.firebaseapp.com",
    projectId: "nodal-linker-356207",
    storageBucket: "nodal-linker-356207.appspot.com",
    messagingSenderId: "281465105281",
    appId: "1:281465105281:web:cb7489a4edd96e28529680"
};

firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
export { auth, firebase };