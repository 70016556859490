import React from 'react';
import PropTypes from 'prop-types';
import './style.scss';
import { width } from '@mui/system';
import { Typography,Button } from '@mui/material';
import { ReactTitle } from 'react-meta-tags';
UserNotPermission.propTypes = {
    
};
function goToHome() {
    window.location.href = "/";
}
function UserNotPermission(props) {
    localStorage.clear('accessToken');
    localStorage.clear('ID');
    return (
      <div className="imageLon">
        <ReactTitle title="404" />
        <img
          src={require("../image/userChuaPhanQuyen.png")}
          className="imageTrong"
        />
        <Typography component="h1" variant="h4" style={{ textAlign: "center" }}>
          Tài khoản của bạn chưa được phân quyền
        </Typography>
        <Typography component="h1" variant="h4" style={{ textAlign: "center" }}>
          Vui lòng liên hệ với Admin để được hỗ trợ
        </Typography>
        <Button
          size="outlined"
          style={{
            marginLeft: "auto",
            marginRight: "auto",
            display: "block",
            marginTop: 20,
          }}
          onClick={goToHome}
        >
          Về trang chủ
        </Button>
      </div>
    );
}

export default UserNotPermission;