import { yupResolver } from "@hookform/resolvers/yup";
import {
    Button,
    FormControl, Input,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography
} from "@mui/material";
import { convertToHTML } from "draft-convert";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useForm } from "react-hook-form";
import { ReactTitle } from "react-meta-tags";
import * as Yup from "yup";
import callApi from "../../Api/userApi";
import SanPhamStore from "../../Zustand/SanPham";
import "./ProductStep1.css";
VietGapsStep.propTypes = {
    infoUser: PropTypes.object,
    next: PropTypes.func,
    setStep: PropTypes.func,
};

function VietGapsStep({ infoUser, next, setStep, dataQT }) {
    var dem = 0
    const {
        handleSubmit,
        formState,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm();
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const setMoTaQT = SanPhamStore((state) => state.setMoTaQT);
    const setLoai = SanPhamStore((state) => state.setLoai);
    setLoai("VietGaps");
    const setEditor = (edtState) => {
        setEditorState(edtState);
        setMoTaQT(convertToHTML(editorState.getCurrentContent()));
    };

    const setTenQT = SanPhamStore((state) => state.setTenQT);
    const Submit = (e) => {
        let res = [];
        setTenQT(e.tenqtLon);

        for (let i = 0; i < dataQT.thongtin.length; i++) {
            res[i] = {
                tenqt: e.tenqt[i + 1],
                mota: e.mota[i + 1],
                donvikd: e.donvikd[i + 1],
            };
        }


        setStep(res);
        next();
    };
    const [donvikiemdinh, setDonViKiemDinh] = useState(null);
    useEffect(() => {
        callApi.getAllToChuc(infoUser.accessToken).then((res) => {
            setDonViKiemDinh(res);
        });
    }, []);
    return (
        <div>
            <ReactTitle title="Bước 2 - Thêm sản phẩm - TRoot"></ReactTitle>
            <form onSubmit={handleSubmit(Submit)} className="root">
                <div className="div_tenqt">
                    <Typography variant="caption1">
                        Tên quy trình <span style={{ color: "red" }}>*</span>
                    </Typography>
                    <TextField
                        {...register("tenqtLon")}
                        error={Boolean(errors.tenqtLon)}
                        style={{ marginTop: 5 }}
                        defaultValue={`Quy trình sản xuất sản phẩm ${dataQT.label} đạt chuẩn VietGaps`}
                    ></TextField>
                    <Typography variant="caption1" style={{ color: 'red' }}>{errors.tenqtLon?.message}</Typography>
                </div>
                <div className="div_mota">
                    <Typography variant="caption1">Mô tả về quy trình</Typography>
                    <div className="editor">
                        <Editor
                            editorState={editorState}
                            fullWidth
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={setEditor}
                        />
                    </div>
                </div>

                <div>
                    {dataQT.thongtin.map((res) => {
                        dem++;
                        return (
                            <div
                                style={{ display: "flex", flexDirection: "column" }}
                                key={res.dieukien}
                            >
                                <Typography variant="caption1">
                                    Quy trình thứ {dem}:
                                </Typography>
                                <TextField
                                    label="Tên bước"
                                    style={{ marginTop: 6 }}
                                    required
                                    value={res.dieukien}
                                    {...register(`tenqt[${dem}]`)}
                                ></TextField>
                                <TextField
                                    label="Mô tả về bước"
                                    style={{ marginTop: 6 }}
                                    required
                                    {...register(`mota[${dem}]`)}
                                ></TextField>
                                <FormControl fullWidth style={{ marginTop: 6 }}>
                                    <InputLabel>Lựa chọn đơn vị kiểm định</InputLabel>
                                    <Select
                                        label="Lựa chọn đơn vị kiểm định"
                                        required
                                        {...register(`donvikd[${dem}]`)}
                                    >
                                        {donvikiemdinh != undefined &&
                                            donvikiemdinh.map((res) => {
                                                if (res.record.loai == "CoQuanKD" && res.record.tieuchuan == "VietGaps") {
                                                    return (
                                                        <MenuItem value={res.record.ID}>
                                                            {res.record.TenCQ}
                                                        </MenuItem>
                                                    );
                                                }
                                            })}
                                    </Select>
                                </FormControl>
                            </div>
                        );
                    })}
                </div>
                <div className="div_addSP">
                    <Button type="submit">Thêm sản phẩm</Button>
                </div>
            </form >
        </div >
    );
}

export default VietGapsStep;
