import create from 'zustand';
const SanPhamStore = create((set) => ({
    mota: "<p>Chưa có mô tả</p>",
    motaQT: "<p>Chưa có mô tả quy trình</p>",
    tenqt: "Chưa có mô tả",
    loai: "All",
    setMoTa: (mota) => set(() => ({ mota: mota })),
    setMoTaQT: (mota) => set(() => ({ motaQT: mota })),
    setTenQT: (tenqt) => set(() => ({tenqt: tenqt})),
    setLoai: (loai) => set(() => ({loai: loai}))
}))
export default SanPhamStore;