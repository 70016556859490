import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/system';
import { Accordion, AccordionSummary, Button, Typography } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AccordionDetails from '@mui/material/AccordionDetails';
import { WrapText } from '@mui/icons-material';
TaoKhoaXong.propTypes = {

};

function TaoKhoaXong({ infoUser }) {
    const [key, setKey] = useState(null);
    useEffect(() => {
        var key = localStorage.getItem("PK");
        setKey(key)
    }, []);
    const exportData = () => {
        const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
            JSON.stringify(JSON.parse(key))
        )}`;
        const link = document.createElement("a");
        link.href = jsonString;
        link.download = "Key.txt";
        link.click();
    };
    return (
        <div>
            <h1>THÔNG TIN VỀ KHOÁ CỦA BẠN</h1>
            <p>Vui lòng lưu khoá của bạn, thông tin về khoá chỉ xuất hiện 1 lần duy nhất</p>
            <Accordion>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>XEM KHOÁ CỦA BẠN</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ wordWrap: "break-word" }} variant='caption1'>
                        {key}
                    </Typography>
                </AccordionDetails>
            </Accordion>
            <div className='cantrai' style={{ display: 'flex', justifyContent: 'right' }}><Button variant="contained" onClick={exportData}>Tải về</Button></div>
            

        </div>
    );
}

export default TaoKhoaXong;