import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import { useSnackbar } from "notistack";
import React, { useState } from "react";
import callApi from "../../Api/userApi";
import UseStore from "../../Zustand/zustand";

KichHoatToChuc.propTypes = {};

function KichHoatToChuc({ data, onClose }) {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const token = UseStore((state) => state.accessToken);
  const [submit, setLoading] = useState(false);
  const reload = UseStore((state) => state.setHotReload);
  const Submit = async () => {
    setLoading(true);
    enqueueSnackbar("Vui lòng chờ", { variant: "info" });
    await callApi
      .vohieuhoatc(token, data.ID)
      .then((res) => {
        closeSnackbar();
        enqueueSnackbar(res.mess, { variant: "success" });
        reload(data);
        onClose();
      })
      .catch((e) => {
        enqueueSnackbar(e.title, { variant: "error" });
        onClose();
      });
    setLoading(false);
  };
  return (
    <div>
      <Dialog
        open={Boolean(data)}
        onClose={onClose}
        aria-describedby="alert-dialog-slide-description"
        keepMounted
      >
        {data.trangthai == "true" ? (
          <div>
            {submit ? (
              <Dialog
                open={true}
                style={{ background: "none" }}
                className="DiaLog_Loading"
              >
                <DialogContent>
                  <img
                    src={require("../../image/iconLoading.gif")}
                    className="icon_loading"
                  />
                </DialogContent>
              </Dialog>
            ) : (
              <div>
                <DialogTitle>
                  Bạn muốn vô hiệu hoá tổ chức {data.TenCQ} chứ
                </DialogTitle>
                <DialogContent>
                  Bạn muốn vô hiệu hoá tổ chức trên chứ, sau khi vô hiệu hoá thì
                  những người thuộc tổ chức trên sẽ không được truy cập vào hệ
                  thống?
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={onClose}>
                    Không
                  </Button>
                  <Button onClick={Submit}>Có</Button>
                </DialogActions>
              </div>
            )}
          </div>
        ) : (
          <div>
            {submit ? (
              <Dialog
                open={true}
                style={{ background: "none" }}
                className="DiaLog_Loading"
              >
                <DialogContent>
                  <img
                    src={require("../../image/iconLoading.gif")}
                    className="icon_loading"
                  />
                </DialogContent>
              </Dialog>
            ) : (
              <div>
                <DialogTitle>
                  Bạn muốn kích hoạt lại tổ chức {data.TenCQ} chứ
                </DialogTitle>
                <DialogContent>
                  Bạn muốn kích hoạt lại tổ chức trên chứ, sau khi kích hoạt lại
                  thì những người thuộc tổ chức trên có thể đăng nhập hệ thống
                  một cách bình thường
                </DialogContent>
                <DialogActions>
                  <Button autoFocus onClick={onClose}>
                    Không
                  </Button>
                  <Button onClick={Submit}>Có</Button>
                </DialogActions>
              </div>
            )}
          </div>
        )}
      </Dialog>
    </div>
  );
}

export default KichHoatToChuc;
