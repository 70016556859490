import { Button, Dialog, DialogContent, IconButton, InputAdornment, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import SanPhamAPI from '../../Api/SanPhamAPI';
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { VisibilityOff } from '@mui/icons-material';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { red } from '@mui/material/colors';
import { useNavigate } from 'react-router-dom';
import KhoaOK from './KhoaOK';
QlKey.propTypes = {

};

function QlKey({ infoUser }) {
    const validationSchema = Yup.object().shape({
        pkKhoa: Yup.string()
            .min(6, "Password cần phải đủ tối thiểu 6 ký tự")
            .required("Password không thể bỏ trống")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const {
        handleSubmit,
        formState,
        register,
        reset,
        formState: { isSubmitting, errors },
    } = useForm(formOptions);
    const [status, setStatusPK] = useState(false);
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [infoPK, setInfoPK] = useState(null);
    const submitPK = async () => {
        setLoading(true);
        await SanPhamAPI.createPrivateKey(infoUser.ID).then((res) => {
            setInfoPK(res);
            localStorage.setItem("PK", JSON.stringify(res));
        })
        navigate('/nhasx/createPK')
        setLoading(false);
    }
    useEffect(() => {
        SanPhamAPI.getStatusKey(infoUser.ID).then((res) => {
            if (res === "") setStatusPK(false);
            else setStatusPK(true);
        })

    }, []);
    const [showPass, setShowPass] = useState(false);
    const sshowPass = () => {
        setShowPass(!showPass)

    }
    return (
        loading ? (<Dialog
            open={true}
            style={{ background: "none" }
            }
            className="DiaLog_Loading"
        >
            <DialogContent>
                <img
                    src={require("../../image/iconLoading.gif")}
                    className="icon_loading"
                />
            </DialogContent>
        </Dialog >
        ) : (
            <div>

                <Typography variant='h6' style={{ color: 'red' }}>Quản lý khoá của bạn</Typography>
                <Typography variant='h6'>Tại đây, bạn có thể kiểm tra tình trạng khoá của mình</Typography>
                {status === true ? (<Typography variant='caption1'>Bạn đã tạo khoá trước đó</Typography>) : (
                    <div>
                        <Typography variant='caption1'>Bạn chưa tạo khoá bí mật!</Typography>
                        <br></br>
                        <Typography variant='caption1'>Bạn hãy thực hiện các bước dưới đây để tạo khoá bí mật cho mình nhé!</Typography>
                        <br />
                        <Typography variant='caption1'>Khoá bí mật phải giữ bí mật tuyệt đối</Typography>
                        <br></br>
                        <Typography variant='caption1'>Làm theo các bước dưới đây - TRoot sẽ hỗ trợ bạn tạo khoá bí mật!</Typography>
                        <br></br>
                        <Typography variant='caption1'>Tên đăng nhập của bạn là {infoUser.username}</Typography>
                        <br></br>
                        <Typography variant='subtitle2' fontSize={17} style={{ color: 'red' }}>Để cấp khoá cho bạn, hãy nhập một mật khẩu bất kỳ!</Typography>
                        <form onSubmit={handleSubmit(submitPK)}>
                            <TextField
                                id="outlined-password-input"
                                label="Nhập mật khẩu của bạn"
                                type={Boolean(showPass) ? "text" : "password"}
                                fullWidth
                                {...register('pkKhoa')}
                                error={Boolean(errors.pkKhoa)}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={sshowPass}>
                                                {showPass ? <VisibilityOff /> : <VisibilityIcon />}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                            <Typography variant="caption" style={{ color: "red" }} fontSize={17}>
                                {errors.pkKhoa?.message}
                            </Typography>
                            <div className="changePass_div">
                                <Button variant="contained" type="submit" style={{ marginTop: 15 }}>
                                    Tạo khoá
                                </Button>
                            </div>
                        </form>

                    </div>
                )}
            </div>)
    );
}

export default QlKey;